import React, {FunctionComponent, useCallback, useEffect, useRef} from "react";
import {navigate, RouteComponentProps, Router} from "@reach/router";
import {FileManagerContext, FileManagerProvider, useFileManagerContext} from "./provider";
import {Box} from "@material-ui/core";
import {Header} from "./header";
import {Footer} from "./footer";
import {CreateFolder} from "./createFolder";
import {DeleteNodes} from "./deleteNodes";
import {FileList} from './fileList';
import {isFile, Node} from "../api/node";
import {UploadWidget} from "./uploadWidget";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {UploadTaskState} from "./state";
import {Sidebar} from "./sidebar";
import {MasterDetail} from "../ui/masterDetail";
import {RenameNode} from "./renameNode";
import {MoveNode} from "./moveNode";
import {ManagePermissionsDialog} from "../permissions/managePermissionsDialog";
import {isAxiosError} from "../api/client";
import {BottomDrawer} from "./bottomDrawer";
import {useBreakpointContext} from "../ui/breakpointProvider";
import {NodeActions} from "./nodeActions";
import {FileInput} from "./fileInput";

const MAX_FILE_SIZE = 500 * 1024 * 1024; // 500M

export interface FileManagerPageProps {
	portalContainer: HTMLDivElement | null | undefined;
	folderId?: number;
}

const useStyles = makeStyles({
	router: {
		height: '100%'
	},
	wrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	listWrapper: {
		flex: '1 1 100%',
		display: 'flex',
		overflow: 'hidden',
	}
});

type RoutedFileManagerProps = RouteComponentProps<FileManagerPageProps>;

export const FileManagerPage: FunctionComponent<RoutedFileManagerProps> = ({folderId, portalContainer}) => {
	const {api} = useFileManagerContext();
	const styles = useStyles();

	useEffect(() => {
		(async () => {
			try {
				await api.loadFolder(folderId);
			} catch (e) {
				if (isAxiosError(e) && (e.response?.status === 403 || e.response?.status === 404)) {
					await navigate('/');
				}
			}
		})();
	}, [api, folderId]);

	const onSelectFolder = (node?: Node) => {
		navigate(node ? `/folder/${node.id}` : '/');
	};

	const handleDownloadFile = useCallback((node: Node) => {
		if (!isFile(node)) {
			return;
		}

		window.open(`${window.location.origin}/download/${node.id}`, '_blank');
	}, []);

	return <FileManagerContext.Consumer>
		{({state, api}) => (
			<Box className={styles.wrapper}>
				<Header
					selectionCount={state.selectionCount}
					breadcrumb={state.breadcrumb}
					folder={state.folder}
					sort={state.sort}
					sortDirection={state.sortDirection}
					sidebarOpen={state.sidebarOpen}
					api={api}/>

				<Box className={styles.listWrapper}>
					<MasterDetail open={state.sidebarOpen} detail={
						<Sidebar folder={state.folder}
										 folderContents={state.nodes}
										 selectionCount={state.selectionCount}
										 api={api}/>
					}>
						<FileList files={state.nodes}
											api={api}
											onNavigateToFolder={onSelectFolder}
											onDownloadFile={handleDownloadFile}
											loading={state.loading}/>

					</MasterDetail>
				</Box>

				<Footer
					folder={state.folder}
					nodes={state.nodes}
					loading={state.loading}
					selectionCount={state.selectionCount}
					sidebarOpen={state.sidebarOpen}
					bottomDrawerOpen={state.bottomDrawerOpen}
					uploadWidgetOpen={state.showUploadWidget}
					api={api}/>

				<CreateFolder
					open={state.createFolderOpen}
					parentId={state.folder ? state.folder.id : undefined}
					api={api}/>

				<DeleteNodes
					nodes={api.getSelection()}
					selectionCount={state.selectionCount}
					open={state.deleteNodesOpen}
					api={api}/>

				<BottomDrawer
					open={state.bottomDrawerOpen}
					selectionCount={state.selectionCount}
					api={api}/>

				<NodeActions
					open={state.nodeActionsOpen}
					api={api}
					selectionCount={state.selectionCount}/>

				{api.getFirstSelected() !== undefined && <>
					<RenameNode
						node={api.getFirstSelected()!}
						open={state.renameNodeOpen}
						api={api}/>

					<MoveNode
						node={api.getFirstSelected()!}
						open={state.moveNodeOpen}
						api={api}/>

					<ManagePermissionsDialog
						node={api.getFirstSelected()!}
						open={state.managePermissionsOpen}
						api={api}/>
				</>}

				<UploadWidget
					portalContainer={portalContainer}
					uploadTasks={state.uploadTasks}
					expanded={state.expandUploadWidget}
					show={state.showUploadWidget}
					onExpand={api.expandUploadWidget}
					onClose={() => {
						const tasks = api.getUploadTasksForStates(UploadTaskState.Uploading, UploadTaskState.Pending);
						if (tasks.length === 0) {
							api.closeUploadWidget();
						} else {
							api.expandUploadWidget(false);
						}
					}}
					onExited={() => {
						const tasks = api.getUploadTasksForStates(UploadTaskState.Uploading, UploadTaskState.Pending);
						if (tasks.length === 0) {
							api.clearCompletedUploadTasks();
						}
					}}/>

				<FileInput
					maxFileSize={MAX_FILE_SIZE}
					open={state.fileInputOpen}
					onSelectFiles={(files) => api.uploadFiles(files, folderId)}
					onClose={() => api.toggleFileInput(false)}/>
			</Box>
		)}
	</FileManagerContext.Consumer>
};

export const FileManagerModule: FunctionComponent<RouteComponentProps> = () => {
	const styles = useStyles();
	const portalContainer = useRef<HTMLDivElement & Router>(null);
	const {isMobile} = useBreakpointContext();

	return (
		<FileManagerProvider isMobile={isMobile}>
			<Router className={styles.router}>
				<FileManagerPage path="/"
												 portalContainer={portalContainer.current}/>

				<FileManagerPage path="folder/:folderId"
												 portalContainer={portalContainer.current}/>
			</Router>
			<div ref={portalContainer}/>
		</FileManagerProvider>
	);
};
