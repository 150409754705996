import React, {FunctionComponent, useCallback, useState} from "react";
import {deleteGroup, Group} from "../api/groups";
import {ConfirmRemoteOperationDialog} from "../ui/confirmRemoteOperationDialog";
import {GroupSummary} from "../api/groupSummary";

export interface UseDeleteGroupDialogHookProps {
	onGroupDeleted: (group: GroupSummary) => void;
}

export const useDeleteGroupDialog = (props: UseDeleteGroupDialogHookProps) => {
	const {onGroupDeleted} = props;
	const [open, setOpen] = useState<boolean>(false);
	const [group, setGroup] = useState<GroupSummary>();

	const onDeleteGroup = useCallback((group: GroupSummary) => {
		setGroup(group);
		setOpen(true);
	}, []);

	const onDeleteGroupSubmit = useCallback((group: GroupSummary) => {
		setOpen(false);
		onGroupDeleted(group);
	}, [onGroupDeleted]);

	const onDeleteGroupExited = useCallback(() => {
		setGroup(undefined);
	}, []);

	const onDeleteGroupCancel = useCallback(() => {
		setOpen(false);
	}, []);

	return {
		onDeleteGroupDialogGroup: group,
		onDeleteGroup,
		onDeleteGroupDialogOpen: open,
		onDeleteGroupSubmit,
		onDeleteGroupExited,
		onDeleteGroupCancel,
	};
};

export interface DeleteGroupDialogProps {
	group?: GroupSummary;
	open: boolean;
	onCancel: () => void;
	onSubmit: (group: Group) => void;
	onExited: (isDeleted: boolean) => void;
}

export const DeleteGroupDialog: FunctionComponent<DeleteGroupDialogProps> = (props) => {
	const {
		group,
		onCancel,
		onSubmit,
		onExited,
		open,
	} = props;

	const handleSubmit = useCallback(async () => {
		if (group) {
			return await deleteGroup(group?.groupId);
		}
	}, [group]);

	return <ConfirmRemoteOperationDialog
		open={open}
		onExited={onExited}
		onSubmit={handleSubmit}
		onAfterSubmit={onSubmit}
		onCancel={onCancel}
		initialText={<>
			Deseja apagar mesmo apagar o grupo #{group?.groupId}?
			<br/>
			Esta ação não pode ser revertida.
		</>}
		loadingText={<>
			A apagar o grupo...
		</>}
		submitErrorText={<>
			Ocorreu um erro ao apagar o grupo.
			<br/>
			Por favor tente novamente.
		</>}
		submitButtonText={"Apagar grupo"}
		successText={"Grupo apagado com sucesso!"}
		submittedText={<>
			Grupo apagado com sucesso!
		</>}/>
}
