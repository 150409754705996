import {Node, NodeType} from "./node";
import {authenticatedApiClient} from "./client";
import axios, {AxiosRequestConfig} from "axios";

export type DOMFile = globalThis.File;

export interface File extends Node {
  type: NodeType.File;
  uploaded: boolean;
  mimeType: string;
  size: number;
  downloadStats: FileDownloadStat[] | undefined;
}

export interface FileForm {
  name: string;
  parentId: number | undefined;
}

export interface UploadProgress {
  total: number;
  loaded: number;
  progress: number;
}

export interface FileDownloadStat {
	userId: number;
	userName: string;
	downloadCount: number;
	lastDownloadAt: string;
}

export interface UploadFileHandler {
  request(): Promise<File>;

  cancel(): void;
}

export interface ProgressCallback {
  (progress: UploadProgress): void;
}

export async function createFile({name, parentId}: FileForm): Promise<File> {
  const {data} = await authenticatedApiClient.post('files', {
    name,
    parent: parentId
  });

  return data;
}

export function uploadFile(file: Node, domFile: DOMFile, progressCallback: ProgressCallback): UploadFileHandler {
  const cancelToken = axios.CancelToken.source();

  const config: AxiosRequestConfig = {
    cancelToken: cancelToken.token,
    onUploadProgress: ({loaded, total}) => {
      const progress = Math.round((loaded * 100) / total);
      if (progressCallback) {
        progressCallback({
          loaded,
          progress,
          total
        });
      }
    }
  };

  const postData = new FormData();
  postData.append('file', domFile);

  const request = authenticatedApiClient.post(`files/${file.id}/upload`, postData, config);

  return {
    request(): Promise<File> {
      return request.then(({data}) => data);
    },
    cancel() {
      cancelToken.cancel();
    }
  };
}

export async function generateDownloadUrl(fileId: number): Promise<string> {
	const {data} = await authenticatedApiClient.get<{ url: string }>(`files/${fileId}/generateDownloadUrl`);
	return data.url;
}
