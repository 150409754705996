import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Link, RouteComponentProps} from "@reach/router";
import {
	Button,
	IconButton,
	Link as MaterialLink,
	Paper,
	Table, TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography
} from "@material-ui/core";
import {ScrollContainer} from "../ui/common";
import {ErrorPanel} from "../ui/errorPanel";
import {LoadingPanel} from "../ui/loadingPanel";
import {makeStyles} from "@material-ui/styles";
import {Delete as DeleteIcon} from '@material-ui/icons';
import theme from "../theme";
import {UserSummary} from "../api/userSummary";
import {getGroup, Group} from "../api/groups";
import {DeleteGroupDialog} from "./deleteGroupDialog";
import {DeleteGroupUserDialog} from "./deleteGroupUserDialog";
import {AddGroupUserDialog} from "./addGroupUserDialog";
import {Navbar, NavbarActions, NavbarBreadcrumbs} from "../ui/navbar";

export type ShowPageProps = RouteComponentProps<{ groupId: number }>;

const useStyles = makeStyles({
	container: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
	},
	gridContainer: {
		display: 'inline-grid',
		gridTemplateColumns: 'max-content 1fr',
		gridColumnGap: '20px',
		alignItems: 'center',
		gridRowGap: '5px',
		marginBottom: theme.spacing(2),
	},
	header: {
		marginBottom: theme.spacing(2),
	},
	bottomActions: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	groupActions: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	}
});

const useShowPage = (props: ShowPageProps) => {
	const {navigate} = props;
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);
	const [group, setGroup] = useState<Group>();
	const [isDeleteGroupOpen, setIsDeleteGroupOpen] = useState(false);
	const [isAddGroupUserOpen, setAddGroupUserOpen] = useState(false);
	const [deleteGroupUser, setDeleteGroupUser] = useState<UserSummary>();

	const onReload = useCallback(async () => {
		setLoading(true);
		try {
			setGroup(await getGroup(props.groupId!));
			setLoading(false);
			setHasLoadingError(false);
		} catch (e) {
			setLoading(false);
			setHasLoadingError(true);
		}
	}, [props.groupId]);

	const onOpenDeleteGroup = useCallback(() => {
		setIsDeleteGroupOpen(true);
	}, [])

	const onSubmitDeleteGroup = useCallback(async () => {
		setIsDeleteGroupOpen(false);
	}, []);

	const onExitDeleteGroup = useCallback(async (isDeleted: boolean) => {
		if (isDeleted && navigate) {
			await navigate('..');
		}
	}, [navigate]);

	const onCancelDeleteGroup = useCallback(async () => {
		setIsDeleteGroupOpen(false);
	}, []);

	const onOpenAddGroupUserDialog = useCallback(() => {
		setAddGroupUserOpen(true);
	}, []);

	const onAddGroupUserSubmit = useCallback((group: Group) => {
		setAddGroupUserOpen(false);
		setGroup(group);
	}, []);

	const onAddGroupUserCancel = useCallback(() => {
		setAddGroupUserOpen(false);
	}, []);

	const onDeleteGroupUserSubmit = useCallback((group: Group) => {
		setDeleteGroupUser(undefined);
		setGroup(group);
	}, []);

	const onDeleteGroupUserCancel = useCallback(() => {
		setDeleteGroupUser(undefined);
	}, []);

	const onDeleteGroupUser = useCallback((user: UserSummary) => {
		setDeleteGroupUser(user);
	}, []);

	useEffect(() => {
		// noinspection JSIgnoredPromiseFromCall
		onReload();
	}, [onReload]);

	return {
		isLoading,
		hasLoadingError,
		group,
		onReload,
		onOpenDeleteGroup,
		onSubmitDeleteGroup,
		onCancelDeleteGroup,
		isDeleteGroupOpen,
		onAddGroupUserCancel,
		onAddGroupUserSubmit,
		isAddGroupUserOpen,
		onOpenAddGroupUserDialog,
		onDeleteGroupUserCancel,
		onDeleteGroupUserSubmit,
		onDeleteGroupUser,
		deleteGroupUser,
		onExitDeleteGroup,
	};
};

export const ShowPage: FunctionComponent<ShowPageProps> = (props) => {
	const {
		groupId
	} = props;

	const {
		hasLoadingError,
		onReload,
		group,
		isLoading,
		isDeleteGroupOpen,
		onCancelDeleteGroup,
		onSubmitDeleteGroup,
		onOpenDeleteGroup,
		isAddGroupUserOpen,
		onAddGroupUserSubmit,
		onAddGroupUserCancel,
		onOpenAddGroupUserDialog,
		onDeleteGroupUserCancel,
		onDeleteGroupUserSubmit,
		onDeleteGroupUser,
		deleteGroupUser,
		onExitDeleteGroup,
	} = useShowPage(props);

	const styles = useStyles();

	const Header: FunctionComponent = ({children}) => <Typography component="h6" variant="h6" className={styles.header}>
		{children}
	</Typography>;

	const Label: FunctionComponent = ({children}) => <Typography color="textSecondary">
		{children}
	</Typography>;

	const Value: FunctionComponent = ({children}) => <Typography>
		{children}
	</Typography>;

	const UsersTable: FunctionComponent = () => {
		if (!group?.users.length) {
			return <TableContainer>
				<Table>
					<TableRow>
						<TableCell>
							<Typography color="textSecondary">
								O grupo não contém nenhum utilizador
							</Typography>
						</TableCell>
					</TableRow>
				</Table>
			</TableContainer>;
		}

		return <TableContainer>
			<Table>
				<TableBody>
					{group?.users.map(user => (
						<TableRow>
							<TableCell>{user.name}</TableCell>
							<TableCell>
								<Tooltip title="Remover">
									<IconButton color="secondary" onClick={() => onDeleteGroupUser(user)}>
										<DeleteIcon/>
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>;
	};

	return <>
		<Navbar>
			<NavbarBreadcrumbs>
				<MaterialLink component={Link} to="../" color="inherit">
					Grupos
				</MaterialLink>
				<Typography color="textPrimary">
					Grupo #{groupId}
				</Typography>
			</NavbarBreadcrumbs>

			<NavbarActions>
				<Button color="primary" variant="contained" component={Link} to="edit">
					Editar Grupo
				</Button>
			</NavbarActions>
		</Navbar>

		{!isLoading && !hasLoadingError && <>
			<ScrollContainer>
				<Paper className={styles.container}>
					<Header>
						Informações Básicas
					</Header>

					<div className={styles.gridContainer}>
						<Label>Nome</Label>
						<Value>{group?.name}</Value>
					</div>

					<Header>Utilizadores</Header>

					<UsersTable/>

					<div className={styles.groupActions}>
						<Button color="primary" onClick={onOpenAddGroupUserDialog}>
							Adicionar utilizador ao grupo
						</Button>
					</div>
				</Paper>

				<div className={styles.bottomActions}>
					<Button variant="contained" onClick={onOpenDeleteGroup} color="secondary">
						Apagar Grupo
					</Button>
				</div>
			</ScrollContainer>
		</>}

		{!isLoading && hasLoadingError && <>
			<ErrorPanel onRetry={onReload}>
				Ocorreu um erro ao carregar o grupo.
				<br/>
				Por favor tente novamente.
			</ErrorPanel>
		</>}

		{isLoading && <>
			<LoadingPanel>
				A carregar grupo...
			</LoadingPanel>
		</>}

		{group && <>
			<DeleteGroupDialog
				open={isDeleteGroupOpen}
				group={group}
				onSubmit={onSubmitDeleteGroup}
				onCancel={onCancelDeleteGroup}
				onExited={onExitDeleteGroup}/>

			<AddGroupUserDialog
				group={group}
				open={isAddGroupUserOpen}
				onSubmit={onAddGroupUserSubmit}
				onCancel={onAddGroupUserCancel}/>
		</>}

		{group && <>
			<DeleteGroupUserDialog
				group={group}
				user={deleteGroupUser}
				onSubmit={onDeleteGroupUserSubmit}
				onCancel={onDeleteGroupUserCancel}/>
		</>}
	</>
}
