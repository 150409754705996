import {AccessToken} from "../api/auth";

const STORAGE_KEY = 'session';

export const storeAccessToken = (accessToken: AccessToken | undefined) => {
	if (accessToken) {
		localStorage.setItem(STORAGE_KEY, JSON.stringify(accessToken));
	} else {
		localStorage.removeItem(STORAGE_KEY);
	}
};

export const loadAccessToken = (): AccessToken | undefined => {
	const rawAccessToken = localStorage.getItem(STORAGE_KEY);
	return rawAccessToken ? JSON.parse(rawAccessToken) : undefined;
};
