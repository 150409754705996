import React, {FunctionComponent, ReactNode} from "react";
import {useProfileState} from "../profile/provider";

export enum GenericPermission {
	ManageUsers,
	ManageGroup
}

export enum FolderPermission {
	ManageNode,
	UploadFile,
}

export interface IsGrantedProps {
	permission: GenericPermission;
	children: (props: { isGranted: boolean }) => ReactNode;
}

export interface WhenGrantedProps {
	permission: GenericPermission;
}

export const IsGranted: FunctionComponent<IsGrantedProps> = (props) => {
	const {isAdmin} = useProfileState();
	const {children} = props;

	return <>{children({isGranted: isAdmin})}</>;
};

export const WhenGranted: FunctionComponent<WhenGrantedProps> = (props) => {
	return <IsGranted permission={props.permission}>
		{({isGranted}) => isGranted && props.children}
	</IsGranted>
}
