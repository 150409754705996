import {ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {HumanByteSize} from "../ui/humanByteSize";

export interface FileInputProps {
	open: boolean;
	onSelectFiles: (files: File[]) => void;
	onClose: () => void;
	maxFileSize: number
}

export const FileInput: FunctionComponent<FileInputProps> = (props) => {
	const {open, onSelectFiles, maxFileSize, onClose} = props;
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
	const closeHandler = useRef(onClose);

	useEffect(() => {
		closeHandler.current = onClose;
	}, [onClose]);

	useEffect(() => {
		const handleClose = () => closeHandler.current();

		if (open) {
			if (fileInputRef.current !== null) {
				fileInputRef.current.click();
				window.addEventListener('focus', handleClose);
			}
		}

		return () => window.removeEventListener('focus', handleClose);
	}, [open, closeHandler]);

	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files === null) {
			return;
		}

		const files = Array.from(e.target.files);
		e.target.value = '';

		if (files.some(f => f.size > maxFileSize)) {
			setErrorDialogOpen(true);
			onClose();

			return;
		}

		onSelectFiles(files);
	}, [onSelectFiles, maxFileSize, onClose]);

	return <>
		<input
			type="file"
			style={{display: 'none'}}
			multiple={true}
			ref={fileInputRef}
			onChange={handleChange}/>

		<Dialog open={errorDialogOpen}>
			<DialogTitle>
				Criar ficheiro
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Os ficheiros não podem exceder o tamanho máximo de <HumanByteSize bytes={maxFileSize}/>.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					onClick={() => setErrorDialogOpen(false)}>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	</>;
}
