import {authenticatedApiClient} from "./client";
import {UserSummary} from "./userSummary";
import {GroupSummary} from "./groupSummary";
import {PaginatedResource} from "./paginatedResource";

export interface SearchCriteria {
	q?: string;
	page?: number;
	excludeAdmins?: boolean;
}

export enum PermissionEntityType {
	User = 0,
	Group
}

export interface PermissionEntity {
	type: PermissionEntityType;
	permissionEntityId: number;
}

export interface PermissionEntityUser extends PermissionEntity {
	type: PermissionEntityType.User;
	user: UserSummary;
}

export interface PermissionEntityGroup extends PermissionEntity {
	type: PermissionEntityType.Group;
	group: GroupSummary;
}

export function isPermissionEntityUser(permissionEntity: PermissionEntity): permissionEntity is PermissionEntityUser {
	return permissionEntity.type === PermissionEntityType.User;
}

export function isPermissionEntityGroup(permissionEntity: PermissionEntity): permissionEntity is PermissionEntityGroup {
	return permissionEntity.type === PermissionEntityType.Group;
}

export async function getPermissionEntities(params?: SearchCriteria): Promise<PaginatedResource<PermissionEntity>> {
	const {data} = await authenticatedApiClient.get('permission-entities', {params});
	return data;
}
