import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {ConfirmRemoteOperationDialog} from "../ui/confirmRemoteOperationDialog";
import {Group, updateUsers} from "../api/groups";
import {UserSummary} from "../api/userSummary";

export interface DeleteGroupUserDialogProps {
	group: Group;
	user?: UserSummary;
	onSubmit: (group: Group) => void;
	onCancel: () => void;
}

const useDeleteGroupUserDialog = (props: DeleteGroupUserDialogProps) => {
	const {
		group,
		user,
		onCancel: parentOnCancel,
		onSubmit: parentOnSubmit,
	} = props;

	const [open, setOpen] = useState<boolean>(false);
	const [updatedGroup, setUpdatedGroup] = useState<Group>();

	const handleExited = useCallback(() => {
		if (updatedGroup) {
			parentOnSubmit(updatedGroup);
			setUpdatedGroup(undefined);
		}
	}, [parentOnSubmit, updatedGroup]);

	const handleCancel = useCallback(() => {
		setUpdatedGroup(undefined);
		parentOnCancel();
	}, [parentOnCancel]);

	const handleSubmit = useCallback(async () => {
		if (!user) {
			return;
		}

		const userIds = group.users
			.map(user => user.userId)
			.filter(userId => userId !== user!.userId);

		const updatedGroup = await updateUsers(group.groupId, userIds);
		setUpdatedGroup(updatedGroup);

		return updatedGroup;
	}, [user, group]);

	const handleAfterSubmit = useCallback(() => {
		setOpen(false);
	}, []);

	useEffect(() => {
		setOpen(!!user);
	}, [user]);

	return {
		user,
		open,
		handleExited,
		handleCancel,
		handleSubmit,
		handleAfterSubmit,
	};
}

export const DeleteGroupUserDialog: FunctionComponent<DeleteGroupUserDialogProps> = (props) => {
	const {
		open,
		user,
		handleAfterSubmit,
		handleSubmit,
		handleCancel,
		handleExited,
	} = useDeleteGroupUserDialog(props);

	return <ConfirmRemoteOperationDialog
		open={open}
		initialText={<>
			Deseja remover o utilizador {user?.name} deste grupo?
		</>}
		loadingText={<>
			A remover utilizador do grupo...
		</>}
		successText="Utilizador removido com sucesso!"
		submitErrorText={<>
			Ocorreu um erro ao remover o utilizador do grupo.
			<br/>
			Por favor tente novamente.
		</>}
		submittedText={<>
			Utilizador removido com sucesso!
		</>}
		submitButtonText="Remover"
		cancelButtonText="Cancelar"
		onCancel={handleCancel}
		onExited={handleExited}
		onSubmit={handleSubmit}
		onAfterSubmit={handleAfterSubmit}/>
}
