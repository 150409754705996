import {AxiosError} from "axios";

export type FormErrors<T> = {
	[K in keyof T]?: string[] | FormErrors<T[K]>;
}

export function parseFormErrors<T>({response}: AxiosError): FormErrors<T> | undefined {
	if (response?.status === 400) {
		return response.data;
	} else {
		return undefined;
	}
}
