import React, {FunctionComponent} from "react";
import clsx from "clsx";
import {Breadcrumbs, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useBreakpointContext} from "./breakpointProvider";

const useStyles = makeStyles((theme: Theme) => ({
	navbarContainer: {
		margin: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
	},
	navbarContainerMobile: {
		flexDirection: 'column',
		alignItems: 'flex-start'
	},
	navbarTitle: {
		flex: '1',
	},
	navbarActions: {
		flex: '0 0 auto',
	},
	navbarActionsMobile: {
		marginTop: theme.spacing(2)
	}
}));

export const Navbar: FunctionComponent = props => {
	const {children} = props;
	const styles = useStyles();
	const {isMobile} = useBreakpointContext();

	return <div className={clsx(styles.navbarContainer, isMobile && styles.navbarContainerMobile)}>
		{children}
	</div>
}

export const NavbarBreadcrumbs: FunctionComponent = props => {
	const {children} = props;
	const styles = useStyles();

	return <Breadcrumbs className={styles.navbarTitle}>
		{children}
	</Breadcrumbs>;
}

export const NavbarActions: FunctionComponent = props => {
	const {children} = props;
	const styles = useStyles();
	const {isMobile} = useBreakpointContext();

	return <div className={clsx(styles.navbarActions, isMobile && styles.navbarActionsMobile)}>
		{children}
	</div>
}
