import React, {createContext, useContext} from "react";
import {createFileManagerApi, FileManagerApi} from "./api";
import {Store} from "../core/store";
import {FileManagerState, getInitialState} from "./state";

export const FileManagerContext = createContext<{ state: FileManagerState, api: FileManagerApi }>({
  state: {} as FileManagerState,
  api: {} as FileManagerApi
});

export const useFileManagerContext = () => useContext(FileManagerContext);

export interface FileManagerProviderProps {
  isMobile: boolean;
}

export class FileManagerProvider extends React.Component<FileManagerProviderProps, FileManagerState> {
  private readonly api: FileManagerApi;

  constructor(props: FileManagerProviderProps) {
    super(props);

    const store = new Store<FileManagerState>({
      getState: () => this.state,
      setState: (state, callback) => this.setState(state, callback)
    });

    const {isMobile} = props;

    this.api = createFileManagerApi(store);
    this.state = getInitialState({isMobile});
  }

  render() {
    return <FileManagerContext.Provider
      value={{state: this.state, api: this.api}}
      children={this.props.children}/>;
  }
}
