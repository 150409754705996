import {FunctionComponent} from "react";
import {ShowPage} from "./showPage";
import {FullPageRouter} from '../ui/router';
import {RouteComponentProps} from "@reach/router";
import {EditPage} from "./editPage";

export const ProfileModule: FunctionComponent<RouteComponentProps> = (props) => {
	return <FullPageRouter>
		<ShowPage path="/"/>
		<EditPage path="/edit"/>
	</FullPageRouter>;
}
