import {createContext, FunctionComponent, useContext} from "react";
import {Theme, useMediaQuery, useTheme} from "@material-ui/core";

export interface BreakpointContextValue {
	isMobile: boolean;
	isDesktop: boolean;
	isTablet: boolean;
}

const BreakpointContext = createContext<BreakpointContextValue>({
	isMobile: false,
	isDesktop: true,
	isTablet: false,
});

export const BreakpointProvider: FunctionComponent = (props) => {
	const {children} = props;
	const theme = useTheme<Theme>();


	const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
		noSsr: true
	});

	const isTablet = useMediaQuery(theme.breakpoints.down('sm'), {
		noSsr: true
	});

	const value = {
		isMobile,
		isTablet: !isMobile && isTablet,
		isDesktop: !isMobile
	};

	return <BreakpointContext.Provider value={value}>
		{children}
	</BreakpointContext.Provider>;
}

export const useBreakpointContext = () => {
	return useContext(BreakpointContext);
};
