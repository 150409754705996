import {SyntheticEvent, useCallback, useEffect, useState} from "react";
import {SnackbarCloseReason, SnackbarOrigin} from "@material-ui/core";

enum LoadingViewState {
	Initial,
	Loading,
	SubmitError,
	Submitted,
}

export interface UseLoadingViewStateProps<R> {
	onSubmit: () => R | Promise<R | undefined> | undefined;
	onAfterSubmit?: (r: R) => void;
}

export interface UseLoadingViewStateOutput<R> {
	onSubmit: () => Promise<R | undefined>;
	isInitial: boolean;
	isLoading: boolean;
	isSubmitError: boolean;
	isSubmitted: boolean;
	resetState: () => void;
}

export const useLoadingViewState = <R,>(props: UseLoadingViewStateProps<R>): UseLoadingViewStateOutput<R> => {
	const {onSubmit: onParentSubmit, onAfterSubmit: onParentAfterSubmit} = props;
	const [viewState, setViewState] = useState<LoadingViewState>(LoadingViewState.Initial);
	const [submitResult, setSubmitResult] = useState<R>();

	const onSubmit = useCallback(async () => {
		setViewState(LoadingViewState.Loading);
		try {
			const ret = await onParentSubmit();
			if (ret === undefined) {

				setViewState(LoadingViewState.Initial);
				return;
			}

			setSubmitResult(ret);
			setViewState(LoadingViewState.Submitted);
			return ret;
		} catch (e) {
			setSubmitResult(undefined);
			setViewState(LoadingViewState.SubmitError);
		}
	}, [onParentSubmit]);

	const resetState = useCallback(() => {
		setViewState(LoadingViewState.Initial);
	}, []);

	useEffect(() => {
		if (viewState === LoadingViewState.Submitted && submitResult !== undefined) {
			onParentAfterSubmit && onParentAfterSubmit(submitResult);
			setSubmitResult(undefined);
		}
	}, [viewState, submitResult, onParentAfterSubmit]);

	return {
		onSubmit,
		isInitial: viewState === LoadingViewState.Initial,
		isLoading: viewState === LoadingViewState.Loading,
		isSubmitError: viewState === LoadingViewState.SubmitError,
		isSubmitted: viewState === LoadingViewState.Submitted,
		resetState,
	}
};

export interface UseLoadingViewSnackbarOutput {
	snackbarOpen: boolean;
	snackbarTimer: number;
	snackbarHandleClose: (event: SyntheticEvent, reason: SnackbarCloseReason) => void;
	snackbarSetOpen: (open: boolean) => void;
	snackbarAnchorOrigin: SnackbarOrigin;
}

export const useLoadingViewSnackbar = (): UseLoadingViewSnackbarOutput => {
	const [open, setOpen] = useState<boolean>(false);

	const handleClose = useCallback((event: SyntheticEvent, reason: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	}, []);

	return {
		snackbarOpen: open,
		snackbarTimer: 3000,
		snackbarHandleClose: handleClose,
		snackbarSetOpen: setOpen,
		snackbarAnchorOrigin: {vertical: 'bottom', horizontal: 'right'}
	}
}

export type UseLoadingViewStateWithSnackbarOutput<R> =
	UseLoadingViewStateOutput<R> &
	Omit<UseLoadingViewSnackbarOutput, 'snackbarSetOpen'>;

export const useLoadingViewStateWithSnackbar = <R,>(props: UseLoadingViewStateProps<R>)
	: UseLoadingViewStateWithSnackbarOutput<R> => {
	const {
		onSubmit: onParentSubmit
	} = props;

	const {
		snackbarSetOpen,
		...snackbarProps
	} = useLoadingViewSnackbar();

	const handleSubmit = useCallback(async () => {
		const ret = await onParentSubmit();
		if (ret !== undefined) {
			snackbarSetOpen(true);
			return ret;
		}

		return ret;
	}, [onParentSubmit, snackbarSetOpen]);

	const viewStateProps = useLoadingViewState({
		...props,
		onSubmit: handleSubmit
	});

	return {
		...viewStateProps,
		...snackbarProps,
	}
}

