import {FunctionComponent} from "react";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import clsx from "clsx";
import {useBreakpointContext} from "./breakpointProvider";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'inline-grid',
		justifyContent: 'end',
		gridAutoColumns: '1fr 1fr',
		gridAutoFlow: 'column',
		gridColumnGap: theme.spacing(2),
		alignSelf: 'flex-end',
	},
	mobile: {
		alignItems: 'stretch',
		gridAutoFlow: 'row',
		gridRowGap: theme.spacing(2),
		alignSelf: 'stretch',
	}
}))

export const FormActions: FunctionComponent = props => {
	const {children} = props;
	const {isMobile} = useBreakpointContext();
	const styles = useStyles();

	return <div className={clsx(styles.root, isMobile && styles.mobile)}>
		{children}
	</div>
}
