import {loadAccessToken} from "./localStorage";
import {AccessToken} from "../api/auth";

export interface AuthState {
	requestInterceptorId: number | undefined;
	responseInterceptorId: number | undefined;
	isLoggedIn: boolean;
	accessToken: AccessToken | undefined;
}

export const getInitialState = (): AuthState => {
	const accessToken = loadAccessToken();
	const isLoggedIn = !!accessToken;

	const initialState: AuthState = {
		isLoggedIn,
		accessToken,
		responseInterceptorId: undefined,
		requestInterceptorId: undefined,
	};

	return {
		...initialState
	};
}
