import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Link, RouteComponentProps} from "@reach/router";
import {getUser, User} from "../api/users";
import {Button, Link as MaterialLink, Paper, Typography} from "@material-ui/core";
import {ScrollContainer} from "../ui/common";
import {ErrorPanel} from "../ui/errorPanel";
import {LoadingPanel} from "../ui/loadingPanel";
import {makeStyles} from "@material-ui/styles";
import theme from "../theme";
import {UserRoleName} from "./userRoleName";
import {DeleteUserDialog} from "./deleteUserDialog";
import {AddUserGroupDialog} from "./addUserGroupDialog";
import {DeleteUserGroupDialog} from "./deleteUserGroupDialog";
import {GroupSummary} from "../api/groupSummary";
import {GroupsTable} from "./groupsTable";
import {Navbar, NavbarActions, NavbarBreadcrumbs} from "../ui/navbar";

export type ShowPageProps = RouteComponentProps<{ userId: number }>;

const useStyles = makeStyles({
	container: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
	},
	gridContainer: {
		display: 'inline-grid',
		gridTemplateColumns: 'max-content 1fr',
		gridColumnGap: '20px',
		alignItems: 'center',
		gridRowGap: '5px',
		marginBottom: theme.spacing(2),
	},
	header: {
		marginBottom: theme.spacing(2),
	},
	bottomActions: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	groupActions: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	}
});

const useShowPage = (props: ShowPageProps) => {
	const {navigate} = props;
	const [isLoading, setLoading] = useState<boolean>(false);
	const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);
	const [user, setUser] = useState<User>();
	const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
	const [isAddUserGroupOpen, setAddUserGroupOpen] = useState(false);
	const [isDeleteUserGroupOpen, setDeleteUserGroupOpen] = useState(false);
	const [deleteUserGroup, setDeleteUserGroup] = useState<GroupSummary>();

	const onReload = useCallback(async () => {
		setLoading(true);
		try {
			setUser(await getUser(props.userId!));
			setLoading(false);
			setHasLoadingError(false);
		} catch (e) {
			setLoading(false);
			setHasLoadingError(true);
		}
	}, [props.userId]);

	const onOpenDeleteUser = useCallback(() => {
		setIsDeleteUserOpen(true);
	}, [])

	const onSubmitDeleteUser = useCallback(async () => {
		setIsDeleteUserOpen(false);
		if (navigate) {
			await navigate('..');
		}
	}, [navigate]);

	const onCancelDeleteUser = useCallback(async () => {
		setIsDeleteUserOpen(false);
	}, []);

	const onOpenAddUserGroupDialog = useCallback(() => {
		setAddUserGroupOpen(true);
	}, []);

	const onAddUserGroupSubmit = useCallback((user: User) => {
		setAddUserGroupOpen(false);
		setUser(user);
	}, []);

	const onAddUserGroupCancel = useCallback(() => {
		setAddUserGroupOpen(false);
	}, []);


	const onOpenDeleteUserGroupDialog = useCallback(() => {
		setDeleteUserGroupOpen(true);
	}, []);

	const onDeleteUserGroupSubmit = useCallback((user: User) => {
		setDeleteUserGroupOpen(false);
		setUser(user);
	}, []);

	const onDeleteUserGroupCancel = useCallback(() => {
		setDeleteUserGroupOpen(false);
	}, []);

	const onDeleteUserGroupExited = useCallback(() => {
		setDeleteUserGroup(undefined);
	}, []);

	const onDeleteUserGroup = useCallback((group: GroupSummary) => {
		setDeleteUserGroupOpen(true);
		setDeleteUserGroup(group);
	}, []);

	useEffect(() => {
		// noinspection JSIgnoredPromiseFromCall
		onReload();
	}, [onReload]);

	return {
		isLoading,
		hasLoadingError,
		user,
		onReload,
		onOpenDeleteUser,
		onSubmitDeleteUser,
		onCancelDeleteUser,
		isDeleteUserOpen,
		onAddUserGroupCancel,
		onAddUserGroupSubmit,
		isAddUserGroupOpen,
		onOpenAddUserGroupDialog,
		isDeleteUserGroupOpen,
		onDeleteUserGroupCancel,
		onDeleteUserGroupSubmit,
		onOpenDeleteUserGroupDialog,
		onDeleteUserGroup,
		deleteUserGroup,
		onDeleteUserGroupExited,
		showGroupsPanel: user?.role !== 'ROLE_ADMIN',
	};
};

export const ShowPage: FunctionComponent<ShowPageProps> = (props) => {
	const {
		userId
	} = props;

	const {
		hasLoadingError,
		onReload,
		user,
		isLoading,
		isDeleteUserOpen,
		onCancelDeleteUser,
		onSubmitDeleteUser,
		onOpenDeleteUser,
		isAddUserGroupOpen,
		onAddUserGroupSubmit,
		onAddUserGroupCancel,
		onOpenAddUserGroupDialog,
		onDeleteUserGroupCancel,
		isDeleteUserGroupOpen,
		onDeleteUserGroupSubmit,
		onDeleteUserGroup,
		deleteUserGroup,
		onDeleteUserGroupExited,
		showGroupsPanel,
	} = useShowPage(props);

	const notificationSettings = user?.notificationSettings;

	const styles = useStyles();

	const Header: FunctionComponent = ({children}) => <Typography component="h6" variant="h6" className={styles.header}>
		{children}
	</Typography>;

	const Label: FunctionComponent = ({children}) => <Typography color="textSecondary">
		{children}
	</Typography>;

	const Value: FunctionComponent = ({children}) => <Typography>
		{children}
	</Typography>;

	const BoolValue: FunctionComponent<{ value?: boolean }> = ({value}) =>
		value ? <Value>Sim</Value> : <Value>Não</Value>;

	return <>
		<Navbar>
			<NavbarBreadcrumbs>
				<MaterialLink component={Link} to="../" color="inherit">
					Utilizadores
				</MaterialLink>
				<Typography color="textPrimary">
					Utilizador #{userId}
				</Typography>
			</NavbarBreadcrumbs>

			<NavbarActions>
				<Button color="primary" variant="contained" component={Link} to="edit">
					Editar Utilizador
				</Button>
			</NavbarActions>
		</Navbar>

		{!isLoading && !hasLoadingError && <>
			<ScrollContainer>
				<Paper className={styles.container}>
					<Header>
						Informações Básicas
					</Header>

					<div className={styles.gridContainer}>
						<Label>Nome</Label>
						<Value>{user?.name}</Value>

						<Label>Email</Label>
						<Value>{user?.email}</Value>

						<Label>Tipo de Utilizador</Label>
						<Value><UserRoleName role={user?.role}/></Value>
					</div>

					<Header>
						Notificações
					</Header>

					<div className={styles.gridContainer}>
						<Label>Criação de pastas</Label>
						<BoolValue value={notificationSettings?.newFolderNotificationEnabled}/>

						<Label>Criação de ficheiros</Label>
						<BoolValue value={notificationSettings?.newFileNotificationEnabled}/>

						<Label>Alteração de pastas</Label>
						<BoolValue value={notificationSettings?.updatedFolderNotificationEnabled}/>

						<Label>Alteração de ficheiros</Label>
						<BoolValue value={notificationSettings?.updatedFileNotificationEnabled}/>
					</div>

					{user?.role === 'ROLE_ADMIN' && <>
						<Header>
							Avançadas
						</Header>

						<div className={styles.gridContainer}>
							<Label>Esconder da lista de permissões</Label>
							<BoolValue value={user?.invisible}/>
						</div>
					</>}

					{showGroupsPanel && <>
						<Header>Grupos</Header>

						<GroupsTable
							enableRemove={true}
							groups={user?.groups || []}
							onRemove={onDeleteUserGroup}/>

						<div className={styles.groupActions}>
							<Button color="primary" onClick={onOpenAddUserGroupDialog}>
								Adicionar utilizador a grupo
							</Button>
						</div>
					</>}
				</Paper>

				<div className={styles.bottomActions}>
					<Button variant="contained" onClick={onOpenDeleteUser} color="secondary">
						Apagar Utilizador
					</Button>
				</div>
			</ScrollContainer>
		</>}

		{!isLoading && hasLoadingError && <>
			<ErrorPanel onRetry={onReload}>
				Ocorreu um erro ao carregar o utilizador.
				<br/>
				Por favor tente novamente.
			</ErrorPanel>
		</>}

		{isLoading && <>
			<LoadingPanel>
				A carregar utilizador...
			</LoadingPanel>
		</>}

		{user && <>
			<DeleteUserDialog
				open={isDeleteUserOpen}
				userId={user.userId}
				onSubmit={onSubmitDeleteUser}
				onCancel={onCancelDeleteUser}/>
		</>}

		{user && showGroupsPanel && <>
			<AddUserGroupDialog
				user={user}
				open={isAddUserGroupOpen}
				onSubmit={onAddUserGroupSubmit}
				onCancel={onAddUserGroupCancel}/>

			<DeleteUserGroupDialog
				open={isDeleteUserGroupOpen}
				user={user}
				group={deleteUserGroup}
				onSubmit={onDeleteUserGroupSubmit}
				onCancel={onDeleteUserGroupCancel}
				onExited={onDeleteUserGroupExited}/>
		</>}
	</>
}
