import {Store} from "../core/store";
import {authenticatedApiClient} from "../api/client";
import {AxiosError, AxiosRequestConfig} from "axios";
import {AccessToken, refreshToken} from "../api/auth";
import {storeAccessToken} from "./localStorage";
import {AuthState} from "./state";

export interface InterceptorsState {
	requestInterceptorId: number | undefined
	responseInterceptorId: number | undefined
}

const addAuthHeader = (config: AxiosRequestConfig, accessToken: AccessToken | undefined): AxiosRequestConfig => {
	if (!accessToken) {
		return config;
	}

	config.headers['Authorization'] = `Bearer ${accessToken.accessToken}`;
	return config;
}

export const setupInterceptors = (store: Store<AuthState>): InterceptorsState => {
	const retries: Map<AxiosRequestConfig, void> = new Map<AxiosRequestConfig, void>();
	const {interceptors: {request, response}} = authenticatedApiClient;

	let refreshRequest: Promise<AccessToken> | undefined;
	let {requestInterceptorId, responseInterceptorId} = store.getState();

	if (requestInterceptorId === undefined) {
		requestInterceptorId = request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
			if (refreshRequest) {
				await refreshRequest;
			}

			const {accessToken} = store.getState();
			return addAuthHeader(config, accessToken);
		}, undefined);
	}

	if (responseInterceptorId === undefined) {
		responseInterceptorId = response.use(undefined, async (error: AxiosError): Promise<any> => {
			const {accessToken} = store.getState();

			if (retries.has(error.config) || !accessToken || !error.response) {
				return Promise.reject(error);
			}

			const {status} = error.response;
			if (status !== 401) {
				return Promise.reject(error);
			}

			if (!refreshRequest) {
				refreshRequest = refreshToken(accessToken.refreshToken).then(newAccessToken => {
					const isLoggedIn = !!newAccessToken;
					const newState = {accessToken: newAccessToken, isLoggedIn: isLoggedIn};

					storeAccessToken(newAccessToken);

					return store.updateStateAsync(newState).then(() => {
						refreshRequest = undefined;
						return accessToken;
					});
				}, error => {
					storeAccessToken(undefined);

					return store.updateStateAsync({accessToken: undefined, isLoggedIn: false}).then(() => {
						refreshRequest = undefined;
						return Promise.reject(error);
					});
				});
			}

			try {
				await refreshRequest;
				retries.set(error.config);
				const response = await authenticatedApiClient.request(error.config);
				retries.delete(error.config);

				return response;
			} catch {
				retries.delete(error.config);
				return Promise.reject(error);
			}
		});
	}

	return {requestInterceptorId, responseInterceptorId};
};

export const clearInterceptors = (store: Store<AuthState>): InterceptorsState => {
	const {requestInterceptorId, responseInterceptorId} = store.getState();
	const {interceptors: {request, response}} = authenticatedApiClient;

	if (requestInterceptorId !== undefined) {
		request.eject(requestInterceptorId);
	}

	if (responseInterceptorId !== undefined) {
		response.eject(responseInterceptorId);
	}

	return {requestInterceptorId: undefined, responseInterceptorId: undefined};
}
