import React, {FunctionComponent, useCallback} from "react";
import {updateGroups, User} from "../api/users";
import {ConfirmRemoteOperationDialog} from "../ui/confirmRemoteOperationDialog";
import {GroupSummary} from "../api/groupSummary";

export interface DeleteUserGroupDialogProps {
	open: boolean;
	user: User;
	group?: GroupSummary;
	onSubmit: (user: User) => void;
	onCancel: () => void;
	onExited: () => void;
}

export const DeleteUserGroupDialog: FunctionComponent<DeleteUserGroupDialogProps> = (props) => {
	const {
		open,
		user,
		group,
		onSubmit: onAfterSubmit,
		onCancel,
		onExited
	} = props;

	const onSubmit = useCallback(async () => {
		if (!group) {
			return;
		}

		const groups = user.groups
			.filter(userGroup => userGroup.groupId !== group.groupId)
			.map(userGroup => userGroup.groupId);

		return await updateGroups(user.userId, groups);
	}, [user.groups, user.userId, group]);

	return <>
		<ConfirmRemoteOperationDialog
			open={open}
			onExited={onExited}
			onSubmit={onSubmit}
			onAfterSubmit={onAfterSubmit}
			onCancel={onCancel}
			submitButtonText={"Apagar grupo"}
			loadingText={<>
				A apagar grupo...
			</>}
			submitErrorText={<>
				Ocorreu um erro ao apagar o grupo. Por favor tente novamente.
				<br/>
			</>}
			initialText={<>
				Deseja apagar mesmo remover o grupo {group?.name} do utilizador {user.name}?</>
			}/>
	</>;
}
