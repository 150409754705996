import React, {FunctionComponent, SyntheticEvent, useCallback, useRef, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {createUser, CreateUserRequest, updateUser, User} from "../api/users";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormGroup,
	FormLabel,
	MenuItem,
	Snackbar,
	SnackbarCloseReason,
	TextField
} from "@material-ui/core";
import {CheckboxWithLabel} from "formik-material-ui";
import {makeStyles} from "@material-ui/styles";
import * as yup from 'yup';
import {AnyObjectSchema} from 'yup';
import {parseFormErrors} from "../api/formErrors";
import {UserRoleName} from "./userRoleName";
import {UserRole} from "../api/userSummary";
import {CustomDialogActions} from "../ui/customDialogActions";
import clsx from "clsx";
import {FormActions} from "../ui/formActions";
import {useBreakpointContext} from "../ui/breakpointProvider";

interface FormType extends Partial<CreateUserRequest> {
	role: UserRole;
	passwordConfirmation: string | undefined;
}

export interface UserFormProps {
	user?: User;
	onReset?: () => void;
	onAfterSubmit?: (user: User) => void;
}

const useStyles = makeStyles({
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	fieldset: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		marginBottom: '40px',
	},
	legend: {
		marginBottom: '10px',
		fontSize: '1.2em',
		lineHeight: '1.2em',
		fontWeight: 'bold'
	},
	notificationSettingsFormGroup: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
	},
	notificationSettingsFormGroupMobile: {
		gridTemplateColumns: '1fr',
	}
});

const useUserForm = (props: UserFormProps) => {
	const {user, onAfterSubmit, onReset: onAfterReset} = props;
	const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>();
	const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
	const {current: isNewUser} = useRef<boolean>(!user?.userId);

	const {current: initialValues} = useRef<FormType>({
		name: user?.name,
		email: user?.email,
		password: undefined,
		passwordConfirmation: undefined,
		role: user?.role || 'ROLE_USER',
		invisible: user?.invisible,
		notificationSettings: {
			newFolderNotificationEnabled: true,
			updatedFolderNotificationEnabled: true,
			newFileNotificationEnabled: true,
			updatedFileNotificationEnabled: true,
		}
	});

	const {current: validationSchema} = useRef<AnyObjectSchema>(yup.object().shape({
		name: yup.string()
			.required('é obrigatório'),
		email: yup.string()
			.required('é obrigatório')
			.email('não é um e-mail válido'),
		password: (isNewUser ? yup.string()
			.required('é obrigatória') : yup.string())
			.min(6, 'tem de ter mais que 6 caracteres'),
		passwordConfirmation: yup.string()
			.oneOf([yup.ref('password'), null], 'tem que ser igual à password'),
		role: yup.string().required().oneOf(['ROLE_USER', 'ROLE_ADMIN']),
		notificationSettings: yup.object().shape({
			newFolderNotificationEnabled: yup.boolean(),
			updatedFolderNotificationEnabled: yup.boolean(),
			newFileNotificationEnabled: yup.boolean(),
			updatedFileNotificationEnabled: yup.boolean(),
		})
	}));

	const doSubmit = useCallback((form: CreateUserRequest) => {
		if (!isNewUser) {
			return updateUser(user!.userId, form);
		} else {
			return createUser(form);
		}
	}, [isNewUser, user]);

	const onSubmit = useCallback(async (values: FormType, helpers: FormikHelpers<FormType>) => {
		const {
			setSubmitting,
			setFieldError,
			resetForm
		} = helpers;

		setSubmitting(true);

		const {notificationSettings} = values;

		try {
			const user = await doSubmit({
				name: values.name!,
				email: values.email!,
				password: values.password!,
				role: values.role!,
				invisible: values.role! === 'ROLE_ADMIN' ? values.invisible! : false,
				notificationSettings: {
					newFileNotificationEnabled: notificationSettings!.newFileNotificationEnabled,
					newFolderNotificationEnabled: notificationSettings!.newFolderNotificationEnabled,
					updatedFileNotificationEnabled: notificationSettings!.updatedFileNotificationEnabled,
					updatedFolderNotificationEnabled: notificationSettings!.updatedFolderNotificationEnabled
				}
			});

			setSubmitting(false);
			isNewUser && resetForm({values: initialValues});
			onAfterSubmit && onAfterSubmit(user);
			setSuccessSnackbarOpen(true);

		} catch (e) {
			setSubmitting(false);

			const errors = parseFormErrors<CreateUserRequest>(e);
			if (errors) {
				if (errors.email) {
					setFieldError('email', 'já está a ser usado');
					return;
				}
			}

			setHasSubmitError(true);
		}
	}, [doSubmit, initialValues, onAfterSubmit, isNewUser]);

	const onReset = useCallback(() => {
		onAfterReset && onAfterReset();
	}, [onAfterReset]);

	const handleSnackbarClose = useCallback((event: SyntheticEvent, reason: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSuccessSnackbarOpen(false);
	}, []);

	const handleSubmitErrorDialogClose = useCallback(() => {
		setHasSubmitError(false);
	}, []);

	return {
		user,
		onSubmit,
		onReset,
		initialValues,
		validationSchema,
		successSnackbarOpen,
		handleSnackbarClose,
		isNewUser,
		hasSubmitError,
		handleSubmitErrorDialogClose,
	}
}

export const UserForm: FunctionComponent<UserFormProps> = (props) => {
	const styles = useStyles();
	const {
		onSubmit,
		initialValues,
		validationSchema,
		onReset,
		successSnackbarOpen,
		handleSnackbarClose,
		isNewUser,
		hasSubmitError,
		handleSubmitErrorDialogClose
	} = useUserForm(props);

	const {isMobile} = useBreakpointContext();

	return <>
		<Formik
			initialValues={initialValues}
			validateOnMount={false}
			validationSchema={validationSchema}
			onReset={onReset}
			onSubmit={onSubmit}>
			{({isSubmitting, errors, touched, values}) => (
				<Form className={styles.form}>
					<FormControl component="fieldset" className={styles.fieldset}>
						<FormLabel component="legend" className={styles.legend}>Definições Básicas</FormLabel>

						<Field id="name"
									 name="name"
									 label="Nome"
									 as={TextField}
									 margin="normal"
									 error={touched.name && !!errors.name}
									 helperText={touched.name && errors.name}
									 disabled={isSubmitting}/>

						<Field id="email"
									 name="email"
									 label="Email"
									 as={TextField}
									 margin="normal"
									 type="email"
									 error={touched.email && !!errors.email}
									 helperText={touched.email && errors.email}
									 disabled={isSubmitting}/>

						<Field id="role"
									 as={TextField}
									 type="text"
									 name="role"
									 label="Tipo de Utilizador"
									 select
									 margin="normal"
									 error={touched.role && !!errors.role}
									 helperText={touched.role && errors.role}
									 disabled={isSubmitting}>
							<MenuItem value={'ROLE_USER'}>
								<UserRoleName role={'ROLE_USER'}/>
							</MenuItem>
							<MenuItem value={'ROLE_ADMIN'}>
								<UserRoleName role={'ROLE_ADMIN'}/>
							</MenuItem>
						</Field>

					</FormControl>

					<FormControl component="fieldset" className={styles.fieldset}>
						<FormLabel component="legend" className={styles.legend}>Password</FormLabel>

						<FormGroup>
							<Field id="password"
										 name="password"
										 label="Password"
										 as={TextField}
										 margin="normal"
										 type="password"
										 error={touched.password && !!errors.password}
										 helperText={
											 (touched.password && errors.password) ||
											 (!isNewUser && 'Deixe vazio para manter a password atual')
										 }
										 disabled={isSubmitting}/>

							<Field id="passwordConfirmation"
										 name="passwordConfirmation"
										 label="Confirmação da Password"
										 as={TextField}
										 margin="normal"
										 type="password"
										 error={touched.passwordConfirmation && !!errors.passwordConfirmation}
										 helperText={touched.passwordConfirmation && errors.passwordConfirmation}
										 disabled={isSubmitting}/>
						</FormGroup>
					</FormControl>

					<FormControl component="fieldset" className={styles.fieldset}>
						<FormLabel component="legend" className={styles.legend}>Notificações</FormLabel>
						<FormGroup
							className={clsx(
								styles.notificationSettingsFormGroup,
								isMobile && styles.notificationSettingsFormGroupMobile
							)}>
							<Field name="notificationSettings.newFolderNotificationEnabled"
										 Label={{label: "Criação de pastas"}}
										 type="checkbox"
										 component={CheckboxWithLabel}
										 disabled={isSubmitting}/>

							<Field name="notificationSettings.newFileNotificationEnabled"
										 Label={{label: "Criação de ficheiros"}}
										 type="checkbox"
										 component={CheckboxWithLabel}
										 disabled={isSubmitting}/>

							<Field name="notificationSettings.updatedFolderNotificationEnabled"
										 Label={{label: "Alteração de pastas"}}
										 type="checkbox"
										 component={CheckboxWithLabel}
										 disabled={isSubmitting}/>

							<Field name="notificationSettings.updatedFileNotificationEnabled"
										 Label={{label: "Alteração de ficheiros"}}
										 type="checkbox"
										 component={CheckboxWithLabel}
										 disabled={isSubmitting}/>
						</FormGroup>
					</FormControl>

					<FormControl component="fieldset" className={styles.fieldset}>
						<FormLabel component="legend" className={styles.legend}>Avançadas</FormLabel>

						<FormGroup>
							<Field
								name="invisible"
								Label={{label: "Esconder utilizador na lista de permissões"}}
								type="checkbox"
								component={CheckboxWithLabel}
								disabled={isSubmitting || values.role !== 'ROLE_ADMIN'}/>
						</FormGroup>
					</FormControl>

					<FormActions>
						<Button variant="contained"
										disabled={isSubmitting}
										type="reset">
							Cancelar
						</Button>

						<Button variant="contained"
										color="primary"
										disabled={isSubmitting}
										type="submit">
							{isNewUser
								? <>Criar Utilizador</>
								: <>Atualizar Utilizador</>}
						</Button>
					</FormActions>
				</Form>
			)}
		</Formik>

		<Snackbar
			anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
			open={successSnackbarOpen}
			message={isNewUser
				? 'Utilizador criado com sucesso'
				: 'Utilizador atualizado com sucesso'
			}
			autoHideDuration={1000}
			onClose={handleSnackbarClose}/>

		<Dialog
			open={hasSubmitError}
			onClose={handleSubmitErrorDialogClose}>
			<DialogTitle>Erro</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{isNewUser
						? 'Ocorreu um erro ao criar o utilizador. Por favor tente novamente.'
						: 'Ocorreu um erro ao atualizar o utilizador. Por favor tente novamente.'}
				</DialogContentText>
			</DialogContent>
			<CustomDialogActions>
				<Button onClick={handleSubmitErrorDialogClose} color="primary" autoFocus>
					Ok
				</Button>
			</CustomDialogActions>
		</Dialog>
	</>
}
