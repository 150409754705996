import {ptPT} from "@material-ui/core/locale";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#0067dc'
		}
	}
}, ptPT);

export default theme;

