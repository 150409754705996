import React, {FunctionComponent} from "react";
import {FileManagerApi} from "./api";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import {ItemIcon} from "./itemIcon";
import {isFile, isFolder, Node} from '../api/node';
import {HumanByteSize} from "../ui/humanByteSize";
import {FormattedDate} from "../ui/formattedDate";
import {SidebarSharedList} from "./sidebarSharedList";
import {SidebarDownloadList} from "./sidebarDownloadList";
import {NodeInfo} from "./state";

export interface SidebarProps {
	folder: Node | undefined;
	api: FileManagerApi;
	folderContents: NodeInfo[] | undefined;
	selectionCount: number;
}

const useStyles = makeStyles<Theme>(theme => ({
	header: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(1, 1, 1, 2),
		alignItems: 'flex-start',
		borderBottom: `3px solid ${theme.palette.grey['200']}`,
	},
	headerTitle: {
		...theme.typography.h6,
		fontWeight: theme.typography.fontWeightRegular,
		marginTop: theme.spacing(1),
		marginBottom: 0,
		flex: '1',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 3,
		wordBreak: 'break-word',
	},
	headerIcon: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		height: '2rem',
		color: theme.palette.text.secondary,
	},
	headerClose: {
		flex: '0 0 auto'
	},
	content: {
		padding: theme.spacing(2),
	}
}));

const avUseStyles = makeStyles<Theme>(theme => ({
	root: {
		marginBottom: theme.spacing(2),
	},
	attribute: {
		flex: '0 0 100px',
		marginRight: theme.spacing(1),
		color: theme.palette.grey['600'],
	},
	value: {
		flex: '1',
		alignSelf: 'flex-end',
	}
}))

interface AttributeValueProps {
	name: string;
}

const AttributeValue: FunctionComponent<AttributeValueProps> = ({children, name}) => {
	const styles = avUseStyles();

	return <div className={styles.root}>
		<div className={styles.attribute}>{name}</div>
		<div className={styles.value}>{children}</div>
	</div>
};

const getFirstSelectedOrCurrentFolder = ({api, folder}: SidebarProps): Node | undefined => {
	const firstSelected = api.getFirstSelected();

	if (!firstSelected && folder) {
		return folder;
	}

	return firstSelected;
};

interface NodeStats {
	fileCount: number;
	folderCount: number;
}

const getFolderStats = (contents: NodeInfo[] | undefined): NodeStats => {
	const stats: NodeStats = {
		fileCount: 0,
		folderCount: 0,
	};

	(contents || []).forEach(({node}) => {
		if (isFolder(node)) {
			stats.folderCount++;
		} else if (isFile(node)) {
			stats.fileCount++;
		}
	});

	return stats;
};

export const Sidebar: FunctionComponent<SidebarProps> = (props) => {
	const {api} = props;
	const styles = useStyles();
	const node = getFirstSelectedOrCurrentFolder(props);

	return <div>
		<div className={styles.header}>
			<ItemIcon className={styles.headerIcon} node={node}/>

			<h3 className={styles.headerTitle} title={node ? node.name : undefined}>
				{node
					? `${node.name}`
					: 'Cloud'
				}
			</h3>
			<IconButton className={styles.headerClose} onClick={() => api.toggleSidebar(false)}>
				<Close/>
			</IconButton>
		</div>

		<SidebarDetails node={node} {...props}/>
	</div>;
};

const SidebarDetails: FunctionComponent<SidebarProps & { node: Node | undefined }> = (props) => {
	const {node, folderContents, folder} = props;
	const styles = useStyles();
	const {fileCount, folderCount} = getFolderStats(folderContents);

	if (!node) {
		return <div className={styles.content}>
			<AttributeValue name={"Pastas"}>
				{folderCount > 0 ? folderCount : <>Sem pastas</>}
			</AttributeValue>

			<AttributeValue name={"Ficheiros"}>
				{fileCount > 0 ? fileCount : <>Sem ficheiros</>}
			</AttributeValue>
		</div>;
	}


	return <div className={styles.content}>
		{node.id === folder?.id && <>
			<AttributeValue name={"Pastas"}>
				{folderCount > 0 ? folderCount : <>Sem pastas</>}
			</AttributeValue>

			<AttributeValue name={"Ficheiros"}>
				{fileCount > 0 ? fileCount : <>Sem ficheiros</>}
			</AttributeValue>
		</>}
		{isFile(node) && <>
			<AttributeValue name="Tamanho">
				<HumanByteSize bytes={node.size} showBytes={true}/>
			</AttributeValue>
		</>}

		<AttributeValue name="Partilhado com">
			<SidebarSharedList permissions={node.effectivePermissions}/>
		</AttributeValue>

		{node.createdBy && node.createdBy.name && <>
			<AttributeValue name="Criado por">{node.createdBy.name}</AttributeValue>
		</>}

		<AttributeValue name="Criado em">
			<FormattedDate date={node.createdAt} dateStyle={"medium"}/>
		</AttributeValue>

		<AttributeValue name="Última alteração em">
			<FormattedDate date={node.updatedAt} dateStyle={"medium"}/>
		</AttributeValue>

		{isFile(node) && node.downloadStats !== undefined && <>
			<AttributeValue name="Visualizado por">
				<SidebarDownloadList downloadStats={node.downloadStats}/>
			</AttributeValue>
		</>}
	</div>
};
