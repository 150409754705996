import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Link, RouteComponentProps} from "@reach/router";
import {Link as MaterialLink, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../theme";
import {UserForm} from "./userForm";
import {getUser, User} from "../api/users";
import {ScrollContainer} from "../ui/common";
import {LoadingPanel} from "../ui/loadingPanel";
import {ErrorPanel} from "../ui/errorPanel";
import {Navbar, NavbarBreadcrumbs} from "../ui/navbar";

export interface EditPageProps {
	userId?: number;
}

const editPageStyles = makeStyles({
	container: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
	}
});

const useEditPage = (props: EditPageProps & RouteComponentProps) => {
	const {userId, navigate} = props;
	const [isLoading, setLoading] = useState(false);
	const [user, setUser] = useState<User>();
	const [hasLoadingError, setHasLoadingError] = useState<boolean>();

	const onReset = useCallback(() => {
		if (navigate) {
			navigate('..');
		}
	}, [navigate]);

	const onLoadUser = useCallback(() => {
		if (userId !== null && userId !== undefined) {
			setLoading(true);
			getUser(userId).then(user => {
				setUser(user);
				setLoading(false);
				setHasLoadingError(false);
			}, err => {
				setUser(undefined);
				setLoading(false);
				setHasLoadingError(true);
			});
		} else {
			setUser(undefined);
			setLoading(false);
			setHasLoadingError(false);
		}
	}, [userId]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(onLoadUser, [userId]);

	return {
		isLoading,
		hasLoadingError,
		user,
		onReset,
		onLoadUser,
	}
}

export const EditPage: FunctionComponent<RouteComponentProps & EditPageProps> = (props) => {
	const {
		userId
	} = props;

	const {
		user,
		isLoading,
		hasLoadingError,
		onReset,
		onLoadUser
	} = useEditPage(props);

	const styles = editPageStyles();

	return <>
		<Navbar>
			<NavbarBreadcrumbs>
				<MaterialLink component={Link} to="../../" color="inherit">
					Utilizadores
				</MaterialLink>
				<Typography color="textPrimary">
					Editar Utilizador #{userId}
				</Typography>
			</NavbarBreadcrumbs>
		</Navbar>

		{!isLoading && !hasLoadingError && <>
			<ScrollContainer>
				<Paper className={styles.container}>
					{user && <UserForm user={user} onReset={onReset}/>}
				</Paper>
			</ScrollContainer>
		</>}

		{!isLoading && hasLoadingError && <>
			<ErrorPanel onRetry={onLoadUser}>
				Ocorreu um erro ao carregar o utilizador.
				<br/>
				Por favor tente novamente.
			</ErrorPanel>
		</>}

		{isLoading && <>
			<LoadingPanel>
				A carregar utilizador...
			</LoadingPanel>
		</>}
	</>;
}
