import {ChangeEvent, FunctionComponent, SyntheticEvent, useCallback, useEffect, useState} from "react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	LinearProgress,
	MenuItem,
	Select,
	Snackbar,
	Typography
} from "@material-ui/core";
import {FileManagerApi} from "../fileManager/api";
import {
	isPermissionEntityGroup,
	isPermissionEntityUser,
	PermissionEntity,
	PermissionEntityGroup,
	PermissionEntityUser
} from "../api/permissionEntity";
import {
	isNodePermissionGroup,
	isNodePermissionUser,
	Node,
	NodePermission,
	NodePermissionRequest,
	NodePermissionType
} from "../api/node";
import {Group as GroupIcon, Person as PersonIcon,} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import theme from "../theme";
import {useProfileState} from "../profile/provider";
import {isAdmin} from "../api/users";
import {SearchForm} from "./searchForm";
import clsx from "clsx";
import {CustomDialogActions} from "../ui/customDialogActions";
import {useBreakpointContext} from "../ui/breakpointProvider";


const useStyles = makeStyles({
	root: {
		minWidth: '800px',
		maxWidth: '80vw',
	},
	rootMobile: {
		minWidth: 'initial',
		maxWidth: 'initial',
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
	},
	listItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingBottom: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	listItemDetails: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		flex: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		'& *': {
			overflow: 'inherit',
			textOverflow: 'inherit',
			whiteSpace: 'inherit',
		}
	},
	listItemActions: {
		flex: '0 0 100px',
		textAlign: 'right',
	},
	listItemTitle: {
		margin: 0,
		marginBottom: theme.spacing(1),
	},
	addPermissionContainer: {
		display: 'flex',
		justifyContent: 'center',
	}
});

const usePermissionsFormStyles = makeStyles({
	formControl: {
		minWidth: '150px',
		maxWidth: '80vw',
	},
	divider: {
		width: '100%'
	},
});

export enum ViewState {
	Initial,
	Loading,
	LoadError,
}

export interface ManagePermissionsProps {
	open: boolean;
	api: FileManagerApi;
	node: Node;
}

function cloneNodePermissions(node: Node) {
	return node.effectivePermissions.map<NodePermission>(perm => ({...perm}));
}

function createPermissionUpdateRequest(permissions: NodePermission[]): NodePermissionRequest[] {
	return permissions.filter(({permissionEntity}) => {
		return isPermissionEntityGroup(permissionEntity) ||
			(isPermissionEntityUser(permissionEntity) && !isAdmin(permissionEntity.user));
	}).map(({permissionEntity, type}) => ({
		type,
		permissionEntity: permissionEntity.permissionEntityId
	}));
}

function deduplicatePermissionUpdateRequest(reqs: NodePermissionRequest[]): NodePermissionRequest[] {
	const newReqs = [];
	const newReqIdMap: { [key: number]: number } = {};

	for (const req of reqs) {
		if (newReqIdMap[req.permissionEntity] === undefined) {
			newReqs.push(req);
			newReqIdMap[req.permissionEntity] = newReqs.length - 1;
		} else {
			const existingReqIdx = newReqIdMap[req.permissionEntity];
			newReqs[existingReqIdx] = req;
		}
	}

	return newReqs;
}

const useManagePermissions = (props: ManagePermissionsProps) => {
	const {node, api} = props;
	const [permissions, setPermissions] = useState<NodePermission[]>([]);
	const [selectedEntities, setSelectedEntities] = useState<PermissionEntity[]>([]);
	const [selectedPermission, setSelectedPermission] = useState<NodePermissionType>(NodePermissionType.Read);
	const [viewState, setViewState] = useState<ViewState>(ViewState.Initial);
	const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>(false);

	const handleSelect = useCallback((entity: PermissionEntity) => {
		setSelectedEntities(entities => {
			if (entities.find(selectedEntity => selectedEntity.permissionEntityId === entity.permissionEntityId)) {
				return entities;
			}

			return entities.concat([entity]);
		})
	}, []);

	const handleExited = useCallback(() => {
		setSelectedEntities([]);
		setSelectedPermission(NodePermissionType.Read);
	}, []);

	const handleRemove = useCallback((entity: PermissionEntity) => {
		setSelectedEntities(entities => {
			const entityIdx = entities.findIndex(sEntity => sEntity.permissionEntityId === entity.permissionEntityId);
			if (entityIdx === -1) {
				return entities;
			}

			return [...entities.slice(0, entityIdx), ...entities.slice(entityIdx + 1)];
		});
	}, []);

	const handleCancelAdd = useCallback(() => {
		setSelectedEntities([]);
		setSelectedPermission(NodePermissionType.Read);
		setViewState(ViewState.Initial);
	}, []);

	const handleCancel = useCallback(() => {
		setSelectedEntities([]);
		setSelectedPermission(NodePermissionType.Read);
		setPermissions(cloneNodePermissions(node));
		setViewState(ViewState.Initial);
		api.toggleManagePermissions(false);
	}, [node, api]);

	const handleSelectedPermissionChange = useCallback((type: NodePermissionType) => {
		setSelectedPermission(type);
	}, []);

	const handleAddPermissions = useCallback(async () => {
		setViewState(ViewState.Loading);

		try {
			const permissions: NodePermissionRequest[] = [
				...createPermissionUpdateRequest(node.effectivePermissions),
				...selectedEntities.map(({permissionEntityId}) => ({
					type: selectedPermission,
					permissionEntity: permissionEntityId
				}))
			];

			const perms = deduplicatePermissionUpdateRequest(permissions);
			const newNode = await api.updateNodePermissions(node, perms);
			setViewState(ViewState.Initial);
			setSelectedEntities([]);
			setSelectedPermission(NodePermissionType.Read);
			setPermissions(cloneNodePermissions(newNode));
			setSuccessSnackbarOpen(true);
			api.toggleManagePermissions(false);
		} catch (e) {
			setViewState(ViewState.LoadError);
		}
	}, [selectedEntities, node, api, selectedPermission]);

	const handleUpdatePermissions = useCallback(async () => {
		setViewState(ViewState.Loading);

		try {
			const perms = deduplicatePermissionUpdateRequest(createPermissionUpdateRequest(permissions));
			const newNode = await api.updateNodePermissions(node, perms);
			setSelectedEntities([]);
			setSelectedPermission(NodePermissionType.Read);
			setPermissions(cloneNodePermissions(newNode));
			setSuccessSnackbarOpen(true);
			setViewState(ViewState.Initial);
			api.toggleManagePermissions(false);
		} catch (e) {
			setViewState(ViewState.LoadError);
		}
	}, [permissions, api, node]);

	const handleSuccessSnackbarClose = useCallback(() => {
		setSuccessSnackbarOpen(false);
	}, []);

	const handleChangePermission = useCallback((permission: NodePermission, type: NodePermissionType) => {
		setPermissions(permissions => {
			return permissions.map(perm => {
				if (perm.permissionEntity.permissionEntityId !== permission.permissionEntity.permissionEntityId) {
					return perm;
				}

				return {...perm, type};
			});
		});
	}, []);

	const handleRemovePermission = useCallback((permission: NodePermission) => {
		setPermissions(permissions => permissions.filter(perm =>
			perm.permissionEntity.permissionEntityId !== permission.permissionEntity.permissionEntityId))
	}, []);

	useEffect(() => {
		setSelectedEntities([]);
		setPermissions(cloneNodePermissions(node));
		setSelectedPermission(NodePermissionType.Read);
	}, [node]);

	return {
		handleSelect,
		handleExited,
		handleRemove,
		handleCancelAdd,
		handleAddPermissions,
		handleUpdatePermissions,
		handleSuccessSnackbarClose,
		handleSelectedPermissionChange,
		handleCancel,
		handleChangePermission,
		handleRemovePermission,
		isAdding: selectedEntities.length > 0,
		selectedEntities,
		selectedPermission,
		permissions,
		successSnackbarOpen,
		viewState,
		...props
	}
}

interface PermissionsFormProps {
	type: NodePermissionType;
	includeRemove: boolean;
	onChange: (type: NodePermissionType) => void;
	onRemove?: () => void;
}

const PermissionsForm: FunctionComponent<PermissionsFormProps> = (props) => {
	const {type, onChange, includeRemove, onRemove} = props;
	const styles = usePermissionsFormStyles();

	const handleChange = useCallback((event: ChangeEvent<{ value: unknown }>) => {
		event.stopPropagation();
		onChange(event.target.value as NodePermissionType);
	}, [onChange]);

	const handleRemove = useCallback((event: SyntheticEvent) => {
		event.stopPropagation();
		onRemove && onRemove();
	}, [onRemove]);

	return <FormControl className={styles.formControl}>
		<Select value={type} onChange={handleChange}>
			<MenuItem value={NodePermissionType.Read}>Leitura</MenuItem>
			<MenuItem value={NodePermissionType.Write}>Leitura e Escrita</MenuItem>

			{includeRemove && [
				<MenuItem
					disabled={true}
					disableGutters={true}
					key="divider">
					<Divider variant="fullWidth" className={styles.divider}/>
				</MenuItem>,
				<MenuItem
					key="remove"
					onClick={handleRemove}>
					Remover
				</MenuItem>
			]}
		</Select>
	</FormControl>;
};

export const ManagePermissionsDialog: FunctionComponent<ManagePermissionsProps> = (props) => {
	const {
		open,
		api,
		node,
		isAdding,
		selectedEntities,
		selectedPermission,
		handleSelect,
		handleExited,
		handleRemove,
		handleCancelAdd,
		handleSelectedPermissionChange,
		permissions,
		viewState,
		successSnackbarOpen,
		handleSuccessSnackbarClose,
		handleUpdatePermissions,
		handleAddPermissions,
		handleCancel,
		handleChangePermission,
		handleRemovePermission,
	} = useManagePermissions(props);

	const styles = useStyles();
	const {profile} = useProfileState();
	const {isMobile} = useBreakpointContext();

	const NodePermissionUser = ({nodePermission}: { nodePermission: NodePermission<PermissionEntityUser> }) => {
		const {permissionEntity: {user}} = nodePermission;

		return <div className={styles.listItem}>
			<div>
				<PersonIcon/>
			</div>
			<div className={styles.listItemDetails}>
				<h3 className={styles.listItemTitle}>
					{user.name}

					{profile?.userId === user.userId && <Typography color="textSecondary" component="span">
						&nbsp;(Você)
					</Typography>}
				</h3>
				<span>{user.email}</span>
			</div>
			<div className={styles.listItemActions}>
				{isAdmin(user)
					? <>
						<Typography color="textSecondary">
							<span>Admin</span>
						</Typography>
					</>
					: <>
						<PermissionsForm
							type={nodePermission.type}
							includeRemove={true}
							onChange={type => handleChangePermission(nodePermission, type)}
							onRemove={() => handleRemovePermission(nodePermission)}/>
					</>}
			</div>
		</div>;
	};

	const NodePermissionGroup = ({nodePermission}: { nodePermission: NodePermission<PermissionEntityGroup> }) => {
		const {permissionEntity: {group}} = nodePermission;

		return <div className={styles.listItem}>
			<div>
				<GroupIcon/>
			</div>
			<div className={styles.listItemDetails}>
				<h3 className={styles.listItemTitle}>
					{group.name}
				</h3>
				<span>Grupo</span>
			</div>
			<div className={styles.listItemActions}>
				<PermissionsForm
					type={nodePermission.type}
					includeRemove={true}
					onChange={type => handleChangePermission(nodePermission, type)}
					onRemove={() => handleRemovePermission(nodePermission)}/>
			</div>
		</div>;
	};

	const InitialDialog = () => (
		viewState === ViewState.Initial
			? <>
				<div className={styles.list}>
					{permissions.map(nodePermission =>
						isNodePermissionUser(nodePermission)
							? <NodePermissionUser
								nodePermission={nodePermission}
								key={nodePermission.permissionEntity.permissionEntityId}/>
							: isNodePermissionGroup(nodePermission)
								? <NodePermissionGroup
									nodePermission={nodePermission}
									key={nodePermission.permissionEntity.permissionEntityId}/>
								: <></>
					)}
				</div>
			</>
			: viewState === ViewState.Loading
				? <>
					<DialogContentText>
						A atualizar permissões...
					</DialogContentText>
					<LinearProgress/>
				</>
				: viewState === ViewState.LoadError
					? <>
						<DialogContentText>
							Ocorreu um erro ao atualizar as permissões.
							<br/>
							Por favor tente novamente.
						</DialogContentText>
					</>
					: <></>
	);

	const AddingDialog = () => (
		viewState === ViewState.Initial
			? <>
				<DialogContentText>
					Selecione que permissão deseja dar aos utilizadores acima em <strong>{node.name}</strong>
				</DialogContentText>

				<div className={styles.addPermissionContainer}>
					<PermissionsForm
						type={selectedPermission}
						includeRemove={false}
						onChange={handleSelectedPermissionChange}/>
				</div>
			</>
			: viewState === ViewState.Loading
				? <>
					<DialogContentText>
						A adicionar permissões...
					</DialogContentText>
					<LinearProgress/>
				</>
				: viewState === ViewState.LoadError
					? <>
						<DialogContentText>
							Ocorreu um erro ao adicionar as permissões.
							<br/>
							Por favor tente novamente.
						</DialogContentText>
					</>
					: <></>
	);

	const AddingActions = () => (
		viewState !== ViewState.LoadError ? <>
			<Button
				onClick={handleCancelAdd}
				disabled={viewState === ViewState.Loading}>
				Cancelar
			</Button>
			<Button
				variant="contained"
				color="primary"
				onClick={handleAddPermissions}
				disabled={viewState === ViewState.Loading}>
				Adicionar
			</Button>
		</> : <>
			<Button onClick={handleCancelAdd}>
				Cancelar
			</Button>
			<Button
				variant="contained"
				color="primary"
				onClick={handleAddPermissions}>
				Tentar novamente
			</Button>
		</>
	);

	const InitialActions = () => (
		viewState !== ViewState.LoadError ? <>
			<Button
				onClick={handleCancel}
				disabled={viewState === ViewState.Loading}>
				Cancelar
			</Button>
			<Button
				variant="contained"
				color="primary"
				onClick={handleUpdatePermissions}
				disabled={viewState === ViewState.Loading}>
				Atualizar
			</Button>
		</> : <>
			<Button onClick={handleCancel}>
				Cancelar
			</Button>
			<Button
				variant="contained"
				color="primary"
				onClick={handleUpdatePermissions}>
				Tentar novamente
			</Button>
		</>
	);

	return <>
		<Dialog
			open={open}
			classes={{paper: clsx(styles.root, isMobile ? styles.rootMobile : undefined)}}
			onClose={() => api.toggleManagePermissions(false)}
			onExited={handleExited}>

			<DialogTitle>Permissões</DialogTitle>
			<DialogContent>
				<SearchForm
					onSelect={handleSelect}
					selectedEntities={selectedEntities}
					onRemove={handleRemove}/>

				{isAdding
					? <AddingDialog/>
					: <InitialDialog/>}
			</DialogContent>
			<CustomDialogActions>
				{isAdding
					? <AddingActions/>
					: <InitialActions/>}
			</CustomDialogActions>
		</Dialog>

		<Snackbar
			open={successSnackbarOpen}
			autoHideDuration={3000}
			onClose={handleSuccessSnackbarClose}
			message="Permissões atualizadas com sucesso!"/>
	</>;
}
