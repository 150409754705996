import React, {FunctionComponent} from "react";
import {useAuthState} from "./provider";
import {Redirect, RouteComponentProps} from "@reach/router";
import {useProfileState} from "../profile/provider";
import {GenericPermission} from "../permissions/isGranted";
import {Profile} from "../api/profile";
import {isAdmin} from "../api/users";

export interface ProtectedRouteProps extends RouteComponentProps {
  as: FunctionComponent;
  permission?: GenericPermission;
}

function hasPermission(profile: Profile, permission: GenericPermission) {
	switch (permission) {
		case GenericPermission.ManageUsers:
		case GenericPermission.ManageGroup:
			return isAdmin(profile);
		default:
			return false;
	}
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = (props) => {
	const {as: Component, permission, ...propsRest} = props;
  const {isLoggedIn} = useAuthState();
  const {profile} = useProfileState();

  if (!isLoggedIn) {
    return <Redirect to="/login" noThrow/>;
  } else if (!profile) {
  	return <></>;
  } else if (permission !== undefined && !hasPermission(profile, permission)) {
  	return <Redirect to="/" noThrow/>
	} else {
		return <Component {...propsRest}/>;
	}
};
