import React, {FunctionComponent} from "react";
import {Node, NodeType} from '../api/node';
import {Cloud, Description, Folder} from "@material-ui/icons";
import {SvgIconProps} from "@material-ui/core";

export interface ItemIconProps extends SvgIconProps {
	node?: Node;
}

export const ItemIcon: FunctionComponent<ItemIconProps> = (props) => {
	const {node, ...rest} = props;
	const {type} = node ? node : {type: undefined};

	switch (type) {
		case NodeType.Folder:
			return <Folder {...rest}/>;
		case NodeType.File:
			return <Description {...rest}/>;
		default:
			return <Cloud {...rest}/>;
	}
};
