import {authenticatedApiClient} from "./client";
import {PaginatedResource} from "./paginatedResource";
import {GroupSummary} from "./groupSummary";
import {UserSummary} from "./userSummary";

export type UserRole = 'ROLE_USER' | 'ROLE_ADMIN';

export interface Group {
	groupId: number;
	name: string;
	permissionEntityId: number;
	users: UserSummary[];
}

export interface SearchCriteria {
	page?: number;
	withoutUserIdMembership?: number;
}

export interface CreateGroupRequest {
	name: string;
}

export async function getGroups(params: SearchCriteria = {}): Promise<PaginatedResource<GroupSummary>> {
	const {data} = await authenticatedApiClient.get('/groups', {params});
	return data;
}

export async function getGroup(groupId: number): Promise<Group> {
	const {data} = await authenticatedApiClient.get(`/groups/${groupId}`);
	return data;
}

export async function deleteGroup(groupId: number): Promise<Group> {
	const {data} = await authenticatedApiClient.delete(`/groups/${groupId}`);
	return data;
}

export async function createGroup(req: CreateGroupRequest): Promise<Group> {
	const {data} = await authenticatedApiClient.post(`/groups`, req);
	return data;
}

export async function updateGroup(groupId: number, req: CreateGroupRequest): Promise<Group> {
	const {data} = await authenticatedApiClient.put(`/groups/${groupId}`, req);
	return data;
}

export async function updateUsers(groupId: number, userIds: number[]): Promise<Group> {
	const {data} = await authenticatedApiClient.put(`/groups/${groupId}/users`, userIds);
	return data;
}
