import {useAuthState} from "../auth/provider";
import React, {createContext, FunctionComponent, useCallback, useContext, useEffect, useState} from "react";
import {getProfile, Profile} from "../api/profile";

export interface ProfileContextProps {
	state: ProfileState;
	api: ProfileApi;
}

export interface ProfileState {
	profile: Profile | undefined;
	isAdmin: boolean;
}

export interface ProfileApi {
	updateProfile(profile: Profile): void;
}

const ProfileContext = createContext<ProfileContextProps>({
	state: {
		profile: undefined,
		isAdmin: false,
	},
	api: {} as ProfileApi,
});

const useProfileProviderState = () => {
	const {isLoggedIn} = useAuthState();
	const [profile, setProfile] = useState<Profile | undefined>(undefined);

	const updateProfile = useCallback((profile: Profile) => {
		setProfile(profile);
	}, []);

	useEffect(() => {
		(async () => {
			if (!isLoggedIn) {
				setProfile(undefined);
			} else {
				try {
					setProfile(await getProfile());
				} catch (e) {
					setProfile(undefined);
				}
			}
		})();
	}, [isLoggedIn]);

	return {
		profile,
		isAdmin: profile?.role === 'ROLE_ADMIN',
		updateProfile,
	};
}

export const useProfileState = (): ProfileState => {
	const {state} = useContext(ProfileContext);
	return state;
}

export const useProfileApi = (): ProfileApi => {
	const {api} = useContext(ProfileContext);
	return api;
}

export const ProfileProvider: FunctionComponent = (props) => {
	const {children} = props;
	const {updateProfile, ...state} = useProfileProviderState();
	const api = {updateProfile};

	return <ProfileContext.Provider value={{state, api}}>
		{children}
	</ProfileContext.Provider>
}

export const WhenProfile: FunctionComponent = (props) => {
	const {children} = props;
	const {profile} = useProfileState();
	if (!profile) {
		return <></>;
	}

	return <>{children}</>;
}
