import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Link, RouteComponentProps, WindowLocation} from "@reach/router";
import {
	Button,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Theme,
	Tooltip,
	Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {getUsers} from "../api/users";
import {LoadingOverlay} from "../ui/loadingOverlay";
import {Delete as DeleteIcon, Edit as EditIcon, Visibility as VisibilityIcon} from "@material-ui/icons";
import {ScrollContainer} from "../ui/common";
import {LoadingPanel} from "../ui/loadingPanel";
import {ErrorPanel} from "../ui/errorPanel";
import {DeleteUserDialog} from "./deleteUserDialog";
import * as yup from 'yup';
import {parseQueryParams} from "../util/parseQueryParams";
import {UserSummary} from "../api/userSummary";
import {UserRoleName} from "./userRoleName";
import clsx from "clsx";
import {Navbar, NavbarActions, NavbarBreadcrumbs} from "../ui/navbar";
import {useBreakpointContext} from "../ui/breakpointProvider";

const indexPageStyles = makeStyles((theme: Theme) => ({
	tableContainer: {
		margin: theme.spacing(2)
	},
	mobileTableRow: {
		display: 'flex',
		flexDirection: 'column',
		'& td:not(:last-child)': {
			borderBottomColor: 'transparent'
		}
	},
	actionsCell: {
		textAlign: 'right'
	}
}));

const parseParams = (location: WindowLocation | undefined): Params => {
	return parseQueryParams(location, yup.object().shape({
		page: yup.number().default(1)
	}));
}

interface Params {
	page?: number;
}

const useIndexParams = (location: WindowLocation | undefined): Params => {
	const [params, setParams] = useState(parseParams(location));

	useEffect(() => {
		setParams(parseParams(location));
	}, [location]);

	return params;
};

const useUsers = (params: Params) => {
	const [initialLoading, setInitialLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState<UserSummary[]>([]);
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(params.page ? params.page - 1 : 0);
	const [perPage, setPerPage] = useState(0);
	const [hasLoadingError, setHasLoadingError] = useState(false);
	const [deleteUserId, setDeleteUserId] = useState<number>();

	const onReload = useCallback(async () => {
		setLoading(true);

		try {
			const paginatedUsers = await getUsers({
				page: params.page,
			});

			setUsers(paginatedUsers.items);
			setCount(paginatedUsers.count);
			setPage(paginatedUsers.page - 1);
			setPerPage(paginatedUsers.perPage);
			setLoading(false);
			setInitialLoading(false);
		} catch (e) {
			setLoading(false);
			setHasLoadingError(true);
			setInitialLoading(true);
		}
	}, [params.page]);

	const onOpenDeleteUser = useCallback((userId: number) => {
		setDeleteUserId(userId);
	}, []);

	const onSubmitDeleteUser = useCallback(async () => {
		setDeleteUserId(undefined);
		return await onReload();
	}, [onReload])

	const onCancelDeleteUser = useCallback(() => {
		setDeleteUserId(undefined);
	}, []);

	useEffect(() => {
		// noinspection JSIgnoredPromiseFromCall
		onReload();
	}, [onReload]);

	return {
		initialLoading,
		loading,
		users,
		count,
		page,
		perPage,
		hasLoadingError,
		onReload,
		onOpenDeleteUser,
		onSubmitDeleteUser,
		onCancelDeleteUser,
		isDeleteUserOpen: !!deleteUserId,
		deleteUserId
	};
}

export const IndexPage: FunctionComponent<RouteComponentProps> = ({location, navigate}) => {
	const styles = indexPageStyles();
	const params = useIndexParams(location);
	const {
		initialLoading,
		loading,
		users,
		page,
		count,
		perPage,
		hasLoadingError,
		onReload,
		deleteUserId,
		onCancelDeleteUser,
		onSubmitDeleteUser,
		onOpenDeleteUser,
		isDeleteUserOpen,
	} = useUsers(params);

	const onChangePage = useCallback((_, page) => {
		const newParams = {
			...params,
			page: page + 1
		};

		const url = new URLSearchParams(newParams);
		if (navigate) {
			navigate(`.?${url.toString()}`);
		}
	}, [params, navigate]);

	const {isMobile} = useBreakpointContext();

	return (
		<>
			<Navbar>
				<NavbarBreadcrumbs>
					<Typography color="textPrimary">Utilizadores</Typography>
				</NavbarBreadcrumbs>

				<NavbarActions>
					<Button color="primary" variant="contained" component={Link} to="new">
						Novo Utilizador
					</Button>
				</NavbarActions>
			</Navbar>

			{!initialLoading && !hasLoadingError && <>
				<ScrollContainer>
					<Paper className={styles.tableContainer}>
						<LoadingOverlay loading={loading}>
							<TableContainer>
								<Table>
									{!isMobile && <>
										<TableHead>
											<TableRow>
												<TableCell>#</TableCell>
												<TableCell>Nome</TableCell>
												<TableCell>Email</TableCell>
												<TableCell>Tipo</TableCell>
												<TableCell/>
											</TableRow>
										</TableHead>
									</>}

									<TableBody>
										{users.map(user => (
											<TableRow key={user.userId} className={clsx(isMobile && styles.mobileTableRow)}>
												{!isMobile && <>
													<TableCell>{user.userId}</TableCell>
												</>}
												<TableCell>{user.name}</TableCell>
												<TableCell>{user.email}</TableCell>
												<TableCell><UserRoleName role={user.role}/></TableCell>
												<TableCell className={styles.actionsCell}>
													<Tooltip title="Ver">
														<IconButton component={Link} to={`${user.userId}`}>
															<VisibilityIcon/>
														</IconButton>
													</Tooltip>

													<Tooltip title="Editar">
														<IconButton component={Link} to={`${user.userId}/edit`}>
															<EditIcon/>
														</IconButton>
													</Tooltip>

													<Tooltip title="Apagar">
														<IconButton onClick={() => onOpenDeleteUser(user.userId)} color="secondary">
															<DeleteIcon/>
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										))}
									</TableBody>

									<TableFooter>
										<TableRow>
											<TablePagination count={count}
																			 onChangePage={onChangePage}
																			 page={page}
																			 rowsPerPageOptions={[]}
																			 rowsPerPage={perPage}>
											</TablePagination>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</LoadingOverlay>
					</Paper>
				</ScrollContainer>
			</>}

			{initialLoading && loading && !hasLoadingError && <>
				<LoadingPanel>
					A carregar utilizadores...
				</LoadingPanel>
			</>}

			{hasLoadingError && <>
				<ErrorPanel showRetryButton={true} onRetry={onReload}>
					Ocorreu um erro a carregar os utilizadores.
					<br/>
					Por favor tente novamente.
				</ErrorPanel>
			</>}

			{deleteUserId && <>
				<DeleteUserDialog
					open={isDeleteUserOpen}
					userId={deleteUserId}
					onCancel={onCancelDeleteUser}
					onSubmit={onSubmitDeleteUser}/>
			</>}
		</>
	);
}
