import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Link, RouteComponentProps} from "@reach/router";
import {Link as MaterialLink, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../theme";
import {ScrollContainer} from "../ui/common";
import {LoadingPanel} from "../ui/loadingPanel";
import {ErrorPanel} from "../ui/errorPanel";
import {getGroup, Group} from "../api/groups";
import {GroupForm} from "./form";
import {Navbar, NavbarBreadcrumbs} from "../ui/navbar";

export interface EditPageProps {
	groupId?: number;
}

const editPageStyles = makeStyles({
	container: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
	}
});

const useEditPage = (props: EditPageProps & RouteComponentProps) => {
	const {groupId, navigate} = props;
	const [isLoading, setLoading] = useState(false);
	const [group, setGroup] = useState<Group>();
	const [hasLoadingError, setHasLoadingError] = useState<boolean>();

	const onReset = useCallback(() => {
		if (navigate) {
			navigate('..');
		}
	}, [navigate]);

	const onLoadGroup = useCallback(() => {
		if (groupId !== null && groupId !== undefined) {
			setLoading(true);
			getGroup(groupId).then(group => {
				setGroup(group);
				setLoading(false);
				setHasLoadingError(false);
			}, () => {
				setGroup(undefined);
				setLoading(false);
				setHasLoadingError(true);
			});
		} else {
			setGroup(undefined);
			setLoading(false);
			setHasLoadingError(false);
		}
	}, [groupId]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(onLoadGroup, [groupId]);

	return {
		isLoading,
		hasLoadingError,
		group,
		onReset,
		onLoadGroup,
	}
}

export const EditPage: FunctionComponent<RouteComponentProps & EditPageProps> = (props) => {
	const {
		groupId
	} = props;

	const {
		group,
		isLoading,
		hasLoadingError,
		onReset,
		onLoadGroup
	} = useEditPage(props);

	const styles = editPageStyles();

	return <>
		<Navbar>
			<NavbarBreadcrumbs>
				<MaterialLink component={Link} to="../../" color="inherit">
					Grupos
				</MaterialLink>
				<Typography color="textPrimary">
					Editar Grupo #{groupId}
				</Typography>
			</NavbarBreadcrumbs>
		</Navbar>

		{!isLoading && !hasLoadingError && <>
			<ScrollContainer>
				<Paper className={styles.container}>
					{group && <GroupForm group={group} onReset={onReset}/>}
				</Paper>
			</ScrollContainer>
		</>}

		{!isLoading && hasLoadingError && <>
			<ErrorPanel onRetry={onLoadGroup}>
				Ocorreu um erro ao carregar o grupo.
				<br/>
				Por favor tente novamente.
			</ErrorPanel>
		</>}

		{isLoading && <>
			<LoadingPanel>
				A carregar grupo...
			</LoadingPanel>
		</>}
	</>;
}
