import {
	ButtonProps,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Theme,
	Toolbar,
	Typography
} from "@material-ui/core";
import MuiAppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import {
	AccountCircle as AccountCircleIcon,
	ExitToApp as ExitToAppIcon,
	Group as GroupIcon,
	Home as HomeIcon,
	Menu as MenuIcon,
	Person as PersonIcon,
	Refresh as RefreshIcon
} from '@material-ui/icons';
import {makeStyles} from "@material-ui/styles";
import {Link} from "@reach/router";
import React, {FunctionComponent, MouseEventHandler, useCallback, useState} from "react";
import {useAuthApi} from "../auth/provider";
import {GenericPermission, WhenGranted} from "../permissions/isGranted";
import {useProfileState} from "../profile/provider";
import {useBreakpointContext} from "./breakpointProvider";
import {FlexSpacer} from "./common";

const useStyles = makeStyles((theme: Theme) => ({
	drawer: {
		maxWidth: '65vw',
		width: '250px',
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		overflow: 'auto',
	},
	header: {
		padding: theme.spacing(2),
	},
	profileName: {
		fontWeight: 'bold',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		margin: 0,
		padding: 0,
	},
	listSpacer: {
		flex: '1'
	},
	logoutLink: {
		color: theme.palette.error.main
	}
}));

export const AppBar: FunctionComponent = () => {
	const {isMobile} = useBreakpointContext();
	const styles = useStyles();
	const {logout} = useAuthApi();
	const {profile} = useProfileState();
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const handleReload = useCallback(() => window.location.reload(), []);

	return <MuiAppBar position="static">
		<Toolbar>
			{isMobile && <>
				<IconButton onClick={() => setDrawerOpen(true)} color="inherit">
					<MenuIcon/>
				</IconButton>
				<Drawer
					anchor="left"
					open={drawerOpen}
					className={styles.drawer}
					onClose={() => setDrawerOpen(false)}>
					<div className={styles.drawer}>

						<div className={styles.header}>
							<Typography variant="h6">
								<span>Olá,</span>
								<br/>
								<p className={styles.profileName} title={profile?.name}>
									{profile?.name}
								</p>
							</Typography>
						</div>

						<Divider/>

						<List>
							<ListItem button component={Link} to={"/"} onClick={() => setDrawerOpen(false)}>
								<ListItemIcon>
									<HomeIcon/>
								</ListItemIcon>
								<ListItemText>
									Início
								</ListItemText>
							</ListItem>

							<WhenGranted permission={GenericPermission.ManageUsers}>
								<ListItem button component={Link} to={"/users"} onClick={() => setDrawerOpen(false)}>
									<ListItemIcon>
										<PersonIcon/>
									</ListItemIcon>
									<ListItemText>
										Utilizadores
									</ListItemText>
								</ListItem>
							</WhenGranted>

							<WhenGranted permission={GenericPermission.ManageGroup}>
								<ListItem button component={Link} to="/groups" onClick={() => setDrawerOpen(false)}>
									<ListItemIcon>
										<GroupIcon/>
									</ListItemIcon>
									<ListItemText>
										Grupos
									</ListItemText>
								</ListItem>
							</WhenGranted>
						</List>

						<div className={styles.listSpacer}/>

						<Divider/>

						<List>
							<ListItem button component={Link} to={"/profile"} onClick={() => setDrawerOpen(false)}>
								<ListItemIcon>
									<AccountCircleIcon/>
								</ListItemIcon>
								<ListItemText>
									Perfil
								</ListItemText>
							</ListItem>

							<ListItem
								button
								className={styles.logoutLink}
								onClick={async () => {
									setDrawerOpen(false);
									await logout();
								}}>
								<ListItemIcon>
									<ExitToAppIcon/>
								</ListItemIcon>
								<ListItemText>
									Terminar Sessão
								</ListItemText>
							</ListItem>
						</List>
					</div>


				</Drawer>
			</>}

			<Button color="inherit" component={Link} to="/">
				<Typography variant="h6">
					AM Marco Cloud
				</Typography>
			</Button>

			<FlexSpacer/>

			{!isMobile && <>
				<WhenGranted permission={GenericPermission.ManageUsers}>
					<Button color="inherit" component={Link} to="/users">
						Utilizadores
					</Button>
				</WhenGranted>

				<WhenGranted permission={GenericPermission.ManageGroup}>
					<Button color="inherit" component={Link} to="/groups">
						Grupos
					</Button>
				</WhenGranted>

				<ProfileMenu color="inherit"/>
			</>}

			{isMobile && <>
				<IconButton color="inherit" onClick={handleReload}>
					<RefreshIcon/>
				</IconButton>
			</>}
		</Toolbar>
	</MuiAppBar>;
};

const useProfileMenuStyles = makeStyles((theme: Theme) => ({
	logoutLink: {
		color: theme.palette.error.main
	}
}));

const ProfileMenu: FunctionComponent<Exclude<ButtonProps, 'onClick'>> = (props) => {
	const {logout} = useAuthApi();
	const {profile} = useProfileState();
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const styles = useProfileMenuStyles();

	if (!profile) {
		return <></>;
	}

	const handleClick: MouseEventHandler = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose: MouseEventHandler = () => {
		setAnchorEl(null);
	};

	const handleLogout: MouseEventHandler = () => {
		logout();
		setAnchorEl(null);
	};

	return <>
		<Button onClick={handleClick} {...props}>
			{profile.name}
		</Button>

		<Menu open={Boolean(anchorEl)}
					keepMounted
					anchorEl={anchorEl}
					getContentAnchorEl={null}
					onClose={handleClose}
					anchorOrigin={{
						horizontal: 'center',
						vertical: 'bottom'
					}}>
			<MenuItem component={Link} to={"/profile"} onClick={handleClose}>
				Perfil
			</MenuItem>

			<MenuItem onClick={handleLogout} className={styles.logoutLink}>
				Terminar Sessão
			</MenuItem>
		</Menu>
	</>;
}
