import {ObjectSchema} from "yup";

type ParsedObj = { [key: string]: string | ParsedObj };

export function parseQueryParams<T>(location: Location | undefined, schema: ObjectSchema<any>): T {
	if (!location) {
		return schema.getDefaultFromShape() as T;
	}

	const url = new URLSearchParams(location.search);
	const obj: ParsedObj = {};

	url.forEach((value, key) => {
		const parts = key.split('.');

		let current: ParsedObj = obj;
		let nestedKey;
		while ((nestedKey = parts.shift())) {
			if (parts.length === 0) {
				current[nestedKey] = value;

			} else {
				const newObj: ParsedObj = {};

				if (typeof current[nestedKey] !== "object") {
					current[nestedKey] = newObj;
				}

				current = newObj;
			}
		}
		obj[key] = value;
	});

	if (!schema.isValid(obj)) {
		return schema.getDefaultFromShape() as T;
	}

	return schema.cast(obj, {
		stripUnknown: true,
	}) as T;
}

export function defaultQueryParams<T>(schema: ObjectSchema<any>): T {
	return schema.getDefaultFromShape() as T;
}
