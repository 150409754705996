import {authenticatedApiClient} from "./client";
import {UserSummary} from "./userSummary";
import {
	isPermissionEntityGroup,
	isPermissionEntityUser,
	PermissionEntity,
	PermissionEntityGroup,
	PermissionEntityUser
} from "./permissionEntity";
import {File} from "./file";

export enum NodeType {
	Folder = 0,
	File = 1,
}

export interface Node {
	id: number;
	type: NodeType;
	name: string;
	parent?: { id: number };
	createdAt: string;
	updatedAt: string;
	createdBy?: UserSummary;
	effectivePermissions: NodePermission[];
	effectiveUserPermissions: NodePermission | undefined;
}

export enum NodePermissionType {
	Revoke = -1,
	Read,
	Write,
}

export interface NodePermission<T extends PermissionEntity = PermissionEntity> {
	type: NodePermissionType;
	nodeId: number;
	permissionEntity: T;
}

export interface PatchNodeForm {
	parent?: number | null;
	name?: string | null;
}

export interface NodePermissionRequest {
	type: NodePermissionType;
	permissionEntity: number;
}

export function isFile(node: Node): node is File {
	return node.type === NodeType.File;
}

export function isFolder(node: Node): boolean {
	return node.type === NodeType.Folder;
}

export function isNodePermissionUser(np: NodePermission): np is NodePermission<PermissionEntityUser> {
	return isPermissionEntityUser(np.permissionEntity);
}

export function isNodePermissionGroup(np: NodePermission): np is NodePermission<PermissionEntityGroup> {
	return isPermissionEntityGroup(np.permissionEntity);
}

export async function getNodes(parentId?: number): Promise<Node[]> {
	const {data} = await authenticatedApiClient.get<Node[]>('nodes', {
		params: {parentId}
	});

	return data;
}

export async function getNodePathFromRoot(folderId: number): Promise<Node[]> {
	const {data} = await authenticatedApiClient.get<Node[]>(`nodes/${folderId}/path-from-root`);
	return data;
}

export async function deleteNode(nodeId: number): Promise<void> {
	await authenticatedApiClient.delete<Node[]>(`nodes/${nodeId}`);
}

export async function patchNode(nodeId: number, form: PatchNodeForm): Promise<Node> {
	const {data} = await authenticatedApiClient.patch(`nodes/${nodeId}`, form);
	return data;
}

export function renameNode(nodeId: number, name: string): Promise<Node> {
	return patchNode(nodeId, {name});
}

export function moveNode(nodeId: number, parent: number | null): Promise<Node> {
	return patchNode(nodeId, {parent});
}

export async function updateNodePermissions(nodeId: number, req: NodePermissionRequest[]): Promise<NodePermission[]> {
	const {data} = await authenticatedApiClient.post(`nodes/${nodeId}/permissions`, req);
	return data;
}
