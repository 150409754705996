import React, {FunctionComponent} from "react";
import {UserRole} from "../api/userSummary";

export interface UserRoleProps {
	role?: UserRole
}

export const UserRoleName: FunctionComponent<UserRoleProps> = (props) => {
	const {role} = props;
	switch (role) {
		case "ROLE_USER":
			return <>Utilizador</>;
		case "ROLE_ADMIN":
			return <>Administrador</>;
		default:
			return <>''</>;
	}
};
