import React, {FunctionComponent} from "react";
import {Button, Dialog, DialogContent, DialogTitle, LinearProgress, makeStyles} from "@material-ui/core";
import {FileManagerApi} from "./api";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {FormErrors} from "../api/formErrors";
import {CustomDialogActions} from "../ui/customDialogActions";

export interface CreateFolderProps {
	parentId?: number
	api: FileManagerApi;
	open: boolean;
}

interface CreateFolderForm {
	name: string;
}

const useStyles = makeStyles({
	form: {
		minWidth: '250px',
		minHeight: '200px',
		maxWidth: '80vw',
		maxHeight: '80vh',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}
});

export const CreateFolder: FunctionComponent<CreateFolderProps> = ({parentId, open, api}) => {
	const styles = useStyles();

	return <Dialog open={open} onClose={api.closeCreateFolder}>
		<Formik
			initialValues={{
				name: ''
			}}
			validate={(values: CreateFolderForm): FormErrors<CreateFolderForm> => {
				const errors: FormErrors<CreateFolderForm> = {};

				if (!values.name) {
					errors.name = 'Obrigatório';
				}

				return errors;
			}}
			onSubmit={async (values, {setSubmitting}) => {
				await api.createFolder(values.name, parentId);
				setSubmitting(false);
				api.closeCreateFolder();
			}}>
			{({isSubmitting}) => (
				<Form className={styles.form}>
					<DialogTitle>Nova Pasta</DialogTitle>

					<DialogContent className={styles.content}>
						<Field id="name" name="name" placeholder="Nome" component={TextField}/>

						{isSubmitting && <LinearProgress/>}
					</DialogContent>

					<CustomDialogActions>
						<Button variant="text"
										color="default"
										type="button"
										disabled={isSubmitting}
										onClick={api.closeCreateFolder}>
							Cancelar
						</Button>
						<Button variant="contained"
										color="primary"
										disabled={isSubmitting}
										type="submit">
							Criar
						</Button>
					</CustomDialogActions>
				</Form>
			)}
		</Formik>
	</Dialog>;
};
