import {authenticatedApiClient} from "./client";
import {PaginatedResource} from "./paginatedResource";
import {GroupSummary} from "./groupSummary";
import {UserRole, UserSummary} from "./userSummary";

export interface UserNotificationSettings {
	newFolderNotificationEnabled: boolean;
	updatedFolderNotificationEnabled: boolean;
	newFileNotificationEnabled: boolean;
	updatedFileNotificationEnabled: boolean;
}

export interface AuditLog {
	identifier: string;
	action: string;
	data: object;
	createdAt: string;
}

export interface User {
	userId: number;
	email: string;
	name: string;
	role: UserRole;
	notificationSettings: UserNotificationSettings,
	permissionEntityId: number;
	groups: GroupSummary[],
	auditLogs?: AuditLog[];
	invisible: boolean;
}

export interface CreateUserRequest {
	name: string;
	email: string;
	role: UserRole;
	notificationSettings: UserNotificationSettings;
	password: string;
	invisible: boolean;
}

export interface SearchCriteria {
	page?: number;
	withoutGroupIdMembership?: number;
}

export function isAdmin(user: UserSummary | User | undefined): boolean {
	return user?.role === 'ROLE_ADMIN';
}

export async function getUsers(params: SearchCriteria = {}): Promise<PaginatedResource<UserSummary>> {
	const {data} = await authenticatedApiClient.get('/users', {params});
	return data;
}

export async function getUser(userId: number): Promise<User> {
	const {data} = await authenticatedApiClient.get(`/users/${String(userId)}`);
	return data;
}

export async function createUser(req: CreateUserRequest): Promise<User> {
	const {data} = await authenticatedApiClient.post('/users', req);
	return data;
}

export async function updateUser(userId: number, req: CreateUserRequest): Promise<User> {
	const {data} = await authenticatedApiClient.put(`/users/${userId}`, req);
	return data;
}

export async function deleteUser(userId: number): Promise<User> {
	const {data} = await authenticatedApiClient.delete(`/users/${userId}`);
	return data;
}

export async function updateGroups(userId: number, groupIds: number[]): Promise<User> {
	const {data} = await authenticatedApiClient.put(`/users/${userId}/groups`, groupIds);
	return data;
}
