import {
	CircularProgress,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Portal,
	Slide,
	Theme,
	Toolbar,
	Typography
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CheckCircle, Close, Description, Error, ExpandLess, ExpandMore} from "@material-ui/icons";
import clsx from "clsx";
import React, {FunctionComponent, useEffect, useState} from "react";
import {pluralize} from "../util";
import {UploadTask, UploadTaskMap, UploadTaskState} from "./state";
import {useBreakpointContext} from "../ui/breakpointProvider";

export interface UploadWidgetProps {
	uploadTasks: UploadTaskMap;
	portalContainer: HTMLDivElement | null | undefined;
	onExpand: (expanded: boolean) => void;
	onClose: () => void;
	onExited: () => void;
	expanded: boolean;
	show: boolean;
}

const useStyles = makeStyles<Theme, UploadWidgetProps>((theme) => ({
	paper: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: theme.zIndex.modal,
		minWidth: '350px',
		maxWidth: '80vw',
		overflow: 'hidden',
	},
	paperMobile: (props) => ({
		maxWidth: 'initial',
		minWidth: 'initial',
		width: '90vw',
		bottom: `${50 + theme.spacing(2)}px`,
		left: '5vw',
		transform: 'translateX(-50%)',
		height: `60vh`,
		transition: '200ms all',
	}),
	header: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(1),
		minHeight: theme.spacing(7),
	},
	headerTitle: {
		flexGrow: 1
	},
	headerButton: {
		color: theme.palette.common.white,
	},
	list: {
		maxHeight: '200px',
		overflowY: 'auto',
	},
	listMobile: {
		maxHeight: 'initial',
		height: 'initial'
	}
}));

const useItemStyles = makeStyles<Theme, UploadWidgetItemProps>((theme) => {
	const opacity = ({task: {state}}: UploadWidgetItemProps) =>
		(state === UploadTaskState.Completed || state === UploadTaskState.Cancelled) ? 1 : .3;

	return ({
		icon: {
			opacity: opacity
		},
		text: {
			opacity: opacity
		},
		secondaryText: {
			marginLeft: theme.spacing(2)
		},
		successIcon: {
			color: theme.palette.success.main
		},
		errorIcon: {
			color: theme.palette.error.main
		},
	});
});

enum UploadState {
	Empty,
	Uploaded,
	Uploading
}

type UploadWidgetTitleProps = Pick<UploadWidgetProps, 'uploadTasks'>;

const UploadWidgetTitle: FunctionComponent<UploadWidgetTitleProps> = ({uploadTasks}) => {
	const [taskCount, setTaskCount] = useState(0);
	const [uploadState, setUploadState] = useState(UploadState.Empty);

	useEffect(() => {
		const taskKeys = Object.keys(uploadTasks);
		setTaskCount(taskKeys.length);

		if (taskKeys.length === 0) {
			setUploadState(UploadState.Empty);
		} else {
			let uploadingTasks = 0;

			for (const taskKey of taskKeys) {
				const {state} = uploadTasks[parseInt(taskKey, 10)];
				if (state === UploadTaskState.Pending || state === UploadTaskState.Uploading) {
					uploadingTasks++;
				}
			}

			if (uploadingTasks > 0) {
				setUploadState(UploadState.Uploading);
				setTaskCount(uploadingTasks);
			} else {
				setUploadState(UploadState.Uploaded);
			}
		}
	}, [setUploadState, uploadTasks, setTaskCount]);

	switch (uploadState) {
		case UploadState.Empty:
			return <>Sem carregamentos</>;
		case UploadState.Uploaded:
			return <>{taskCount} {pluralize(taskCount, 'carregamento', 'carregamentos')}</>;
		case UploadState.Uploading:
			return <>A carregar {taskCount} {pluralize(taskCount, 'item', 'items')}</>;
	}
};

interface UploadWidgetItemProps {
	task: UploadTask;
}

export const UploadWidgetItem: FunctionComponent<UploadWidgetItemProps> = ({task}) => {
	const styles = useItemStyles({task});

	return <ListItem>
		<ListItemIcon className={styles.icon}>
			<Description color="primary"/>
		</ListItemIcon>
		<ListItemText primaryTypographyProps={{variant: 'body2'}} className={styles.text}>
			{task.domFile.name}

			{task.state === UploadTaskState.Cancelled &&
			<Typography color="textSecondary" variant="body2" component="span" className={styles.secondaryText}>
				Cancelado
			</Typography>}
		</ListItemText>
		<ListItemSecondaryAction>
			{task.state === UploadTaskState.Pending &&
			<CircularProgress size="1.5rem"/>}

			{task.state === UploadTaskState.Uploading &&
			<CircularProgress size="1.5rem"
												variant="determinate"
												value={task.progress}/>}

			{task.state === UploadTaskState.Completed &&
			<CheckCircle className={styles.successIcon}/>}

			{task.state === UploadTaskState.Failed &&
			<Error className={styles.errorIcon}/>}
		</ListItemSecondaryAction>
	</ListItem>;
}

export const UploadWidget: FunctionComponent<UploadWidgetProps> = (props) => {
	const {
		portalContainer,
		uploadTasks,
		onClose,
		onExpand,
		onExited,
		show,
		expanded
	} = props;

	const styles = useStyles(props);
	const {isMobile} = useBreakpointContext();

	return <Portal container={portalContainer}>
		<Slide direction="up" in={show} onExited={onExited}>
			<Paper className={clsx(styles.paper, isMobile && styles.paperMobile)}>
				<Toolbar className={styles.header}>
					<Typography variant="body1" className={styles.headerTitle}>
						<UploadWidgetTitle uploadTasks={uploadTasks}/>
					</Typography>

					{!isMobile && <>
						<IconButton className={styles.headerButton} onClick={() => onExpand(!expanded)}>
							{expanded ? <ExpandMore/> : <ExpandLess/>}
						</IconButton>
					</>}

					<IconButton className={styles.headerButton} onClick={onClose}>
						<Close/>
					</IconButton>
				</Toolbar>

				{expanded && <List className={clsx(styles.list, isMobile && styles.listMobile)}>
					{Object.keys(uploadTasks).map(taskKey =>
						<UploadWidgetItem key={taskKey} task={uploadTasks[parseInt(taskKey, 10)]}/>
					)}
				</List>}

			</Paper>
		</Slide>
	</Portal>;
};
