import {Box, Theme} from "@material-ui/core";
import styled from "@material-ui/core/styles/styled";
import React, {HTMLProps} from "react";
import background from "../assets/login-bg.jpg";
import {FullPage} from "./common";

export interface CenteredContainerProps extends HTMLProps<HTMLDivElement> {
	backgroundImage?: string;
}

export const CenteredContainer = styled(FullPage<CenteredContainerProps>(({children, backgroundImage, ...rest}) =>
	<Box {...rest}>
		<Box>{children}</Box>
	</Box>
))<Theme, CenteredContainerProps>(({theme, backgroundImage}) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	...(backgroundImage && {
		backgroundImage: backgroundImage ? `url(${background})` : 'initial',
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	})
}));
