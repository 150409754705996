import React, {FunctionComponent, HTMLAttributes, useMemo} from 'react';

export interface FormattedDateProps extends HTMLAttributes<HTMLSpanElement> {
	date: string;
	dateStyle: 'full' | 'long' | 'medium' | 'short';
}

export const FormattedDate: FunctionComponent<FormattedDateProps> = (props) => {
	const {date, dateStyle, children, ...rest} = props;
	const formatter = new Intl.DateTimeFormat([], {dateStyle} as any);
	const dateObj = useMemo(() => {
		if (date === null || date === undefined) {
			return undefined;
		}

		try {
			return new Date(Date.parse(date));
		} catch (e) {
			return undefined;
		}
	}, [date]);

	if (!dateObj) {
		return null;
	}

	return <span {...rest}>{formatter.format(dateObj)}</span>;
};
