import React, {FunctionComponent, useCallback} from "react";
import {Link, RouteComponentProps} from "@reach/router";
import {Link as MaterialLink, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../theme";
import {GroupForm} from "./form";
import {ScrollContainer} from "../ui/common";
import {Group} from "../api/groups";
import {Navbar, NavbarBreadcrumbs} from "../ui/navbar";

const newPageStyles = makeStyles({
	container: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
	}
});

const useNewPage = (props: RouteComponentProps) => {
	const {navigate} = props;

	const onAfterSubmit = useCallback(async (group: Group) => {
		if (navigate) {
			await navigate(`../${group.groupId}`);
		}
	}, [navigate]);

	const onReset = useCallback(async () => {
		if (navigate) {
			await navigate('..');
		}
	}, [navigate]);

	return {
		onAfterSubmit,
		onReset
	};
};

export const NewPage: FunctionComponent<RouteComponentProps> = (props) => {
	const styles = newPageStyles();
	const {
		onReset,
		onAfterSubmit
	} = useNewPage(props);

	return <>
		<Navbar>
			<NavbarBreadcrumbs>
				<MaterialLink component={Link} to="../" color="inherit">
					Grupos
				</MaterialLink>
				<Typography color="textPrimary">
					Novo Grupo
				</Typography>
			</NavbarBreadcrumbs>
		</Navbar>

		<ScrollContainer>
			<Paper className={styles.container}>
				<GroupForm onReset={onReset} onAfterSubmit={onAfterSubmit}/>
			</Paper>
		</ScrollContainer>
	</>;
}
