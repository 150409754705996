import React, {FunctionComponent} from "react";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

export interface LoadingOverlayProps {
	loading: boolean;
}

const loadingOverlayStyles = makeStyles<Theme, { loading: boolean }>(theme => ({
	root: ({loading}) => ({
		...(loading ? {
			opacity: 0.5,
			pointerEvents: 'none',
		} : {})
	}),
}));

export const LoadingOverlay: FunctionComponent<LoadingOverlayProps> = ({loading, children}) => {
	const styles = loadingOverlayStyles({
		loading
	});

	return (<div className={styles.root}>
		{children}
	</div>);
}
