import {
	getPermissionEntities,
	isPermissionEntityGroup,
	isPermissionEntityUser,
	PermissionEntity,
	SearchCriteria
} from "../api/permissionEntity";
import React, {CSSProperties, FunctionComponent, useCallback, useEffect, useLayoutEffect, useState} from "react";
import {
	Grow,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Theme,
	Typography
} from "@material-ui/core";
import {Group as GroupIcon, Person as PersonIcon,} from '@material-ui/icons';
import {makeStyles} from "@material-ui/styles";
import {PaginatedResource} from "../api/paginatedResource";
import {InfiniteScroll} from "../ui/infiniteScroll";


interface SearchFormDropdownProps {
	inputEl: Element | null;
	open: boolean;
	formValue: SearchCriteria;
	selectedEntities: PermissionEntity[];
	onSelect: (entity: PermissionEntity) => void;
}

enum ViewState {
	Initial,
	Loading,
	Empty,
	LoadError,
}

export type DropdownPosition = Partial<Pick<CSSProperties, 'top' | 'left' | 'maxHeight' | 'width'>>;

const useStyles = makeStyles<Theme, SearchFormDropdownProps & DropdownPosition>((theme: Theme) => ({
	emptyText: {
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	progressBar: {
		flex: '0 0 auto'
	},
	list: {
		flex: '1',
		overflow: 'auto',
	},
	listItemSecondaryText: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	dropdown: ({left, top, maxHeight, width}) => ({
		position: 'fixed',
		top: top !== undefined ? top : 'auto',
		left: left !== undefined ? left : 'auto',
		width: width !== undefined ? width : 'auto',
		display: 'flex',
		maxHeight,
		flexDirection: 'column',
		zIndex: 1,
	})
}));

const calculateDropdownPosition = (inputEl: Element): DropdownPosition => {
	const windowHeight = window.document.body.getBoundingClientRect().height;
	const rect = inputEl.getBoundingClientRect();

	return {
		top: rect.top + rect.height,
		left: rect.left,
		maxHeight: windowHeight - (rect.top + rect.height),
		width: rect.width,
	};
}

type Pagination = Omit<PaginatedResource<any>, 'items'>;

const initialPagination = {
	page: 0,
	count: 0,
	perPage: 0,
};

const getPaginationFromResource = ({page, perPage, count}: PaginatedResource<any>) => {
	return {
		page: page - 1,
		perPage: perPage,
		count: count
	}
}
const paginationHasMore = ({perPage, page, count}: Pagination) => (page + 1) * perPage < count;

export const SearchFormDropdown: FunctionComponent<SearchFormDropdownProps> = (props) => {
	const {
		open,
		inputEl,
		formValue,
		onSelect,
		selectedEntities,
	} = props;

	const [viewState, setViewState] = useState<ViewState>(ViewState.Initial);
	const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [pagination, setPagination] = useState(initialPagination);
	const [items, setItems] = useState<PermissionEntity[]>([]);
	const [isEntered, setEntered] = useState<boolean>(false);
	const styles = useStyles({...props, ...dropdownPosition});

	const loadData = useCallback(async (
		pagination: Pagination,
		formValue: SearchCriteria,
		append: boolean = false
	) => {
		setViewState(ViewState.Initial);
		setLoading(true);
		try {
			const paginatedResource = await getPermissionEntities({
				...formValue,
				page: pagination.page + 1,
				excludeAdmins: true,
			});

			setPagination(getPaginationFromResource(paginatedResource));
			setItems(items => append ? items.concat(paginatedResource.items) : paginatedResource.items);
			setLoading(false);
			setViewState(paginatedResource.items.length ? ViewState.Initial : ViewState.Empty);
		} catch (e) {
			setLoading(false);
			setViewState(ViewState.LoadError);
			setItems([]);
		}
	}, []);

	const handleEntered = useCallback(async () => {
		await loadData(pagination, formValue, false);
		setEntered(true);
	}, [loadData, formValue, pagination]);

	const handleExited = useCallback(() => {
		setItems([]);
		setPagination(initialPagination);
		setViewState(ViewState.Initial);
	}, []);

	const handleLoadMore = useCallback(async () => {
		if (!paginationHasMore(pagination)) {
			return;
		}

		const newPagination = {...pagination, page: pagination.page + 1};
		setPagination({...pagination, page: pagination.page + 1});
		await loadData(newPagination, formValue, true);
	}, [pagination, loadData, formValue]);

	const handleClick = useCallback((entity: PermissionEntity) => {
		onSelect(entity);
		if (inputEl) {
			setDropdownPosition(() => calculateDropdownPosition(inputEl));
		}
	}, [inputEl, onSelect]);

	useLayoutEffect(() => {
		const handleResize = () => {
			if (inputEl) {
				setDropdownPosition(calculateDropdownPosition(inputEl));
			}
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, [inputEl, selectedEntities]);

	useEffect(() => {
		(async () => {
			if (isEntered) {
				setPagination(initialPagination);
				await loadData(initialPagination, formValue, false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formValue]);

	return <Grow
		in={open}
		onEntered={handleEntered}
		onExited={handleExited}>
		<Paper
			className={styles.dropdown}>

			<LinearProgress
				className={styles.progressBar}
				hidden={!isLoading}/>

			{viewState === ViewState.Initial && <>
				<InfiniteScroll
					disabled={isLoading || !paginationHasMore(pagination)}
					onLoadMore={handleLoadMore}>
					<List className={styles.list}>
						{items.map(item => (
							<ListItem button onClick={() => handleClick(item)}>
								{isPermissionEntityUser(item)
									? <>
										<ListItemIcon>
											<PersonIcon/>
										</ListItemIcon>
										<ListItemText disableTypography={true}>
											<Typography
												component="h3"
												variant="body1">
												{item.user.name}
											</Typography>
											<Typography
												component="div"
												variant="body1"
												color="textSecondary"
												className={styles.listItemSecondaryText}>
												{item.user.email}
											</Typography>
										</ListItemText>
									</>
									: isPermissionEntityGroup(item)
										? <>
											<ListItemIcon>
												<GroupIcon/>
											</ListItemIcon>

											<ListItemText disableTypography={true}>
												<Typography
													component="h3"
													variant="body1">
													{item.group.name}
												</Typography>
												<Typography
													component="div"
													variant="body1"
													color="textSecondary"
													className={styles.listItemSecondaryText}>
													&nbsp;
												</Typography>
											</ListItemText>
										</>
										: <></>}
							</ListItem>
						))}
					</List>
				</InfiniteScroll>
			</>}

			{viewState === ViewState.Empty && <>
				<h3 className={styles.emptyText}>
					Não foram encontrados resultados
				</h3>
			</>}

			{viewState === ViewState.LoadError && <>
				<h3 className={styles.emptyText}>
					Ocorreu um erro ao carregar os resultados
				</h3>
			</>}
		</Paper>
	</Grow>
}
