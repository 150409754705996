import {createFile, DOMFile, uploadFile, UploadProgress} from "../api/file";
import {createFolder, getFolder} from "../api/folder";
import {
	deleteNode,
	getNodePathFromRoot,
	getNodes,
	moveNode,
	Node,
	NodePermissionRequest,
	renameNode,
	updateNodePermissions
} from "../api/node";
import {Store} from "../core/store";
import {
	createNodeInfo,
	FileManagerState,
	NodeInfo,
	Sort,
	SortDirection,
	UploadTask,
	UploadTaskMap,
	UploadTaskState
} from "./state";

export interface FileManagerApi {

	loadFolder(folderId?: number): Promise<void>;

	addToSelection(node: Node): void;

	setSelection(node: Node): void;

	showActions(node: Node): void;

	removeFromSelection(node: Node): void;

	clearSelection(): void;

	selectRange(startNode: Node, endNode: Node): void;

	getFirstSelected(): Node | undefined;

	getSelection(): Node[];

	openCreateFolder(): void;

	closeCreateFolder(): void;

	openDeleteNodes(clearSelectionOnClose?: boolean): void;

	closeDeleteNodes(): void;

	toggleRenameNode(isOpen: boolean, clearSelectionOnClose?: boolean): void;

	toggleMoveNode(isOpen: boolean, clearSelectionOnClose?: boolean): void;

	createFolder(name: string, parentId?: number): Promise<Node>;

	deleteNodes(nodes: Node[]): Promise<void>;

	setSort(sort: Sort, direction: SortDirection): void

	uploadFiles(files: DOMFile[], parentId?: number): void;

	toggleUploadWidget(isOpen: boolean): void;

	expandUploadWidget(isExpanded: boolean): void;

	getUploadTasksForStates(...taskState: UploadTaskState[]): UploadTask[];

	toggleSidebar(isOpen: boolean): void;

	renameNode(nodeId: number, name: string): Promise<Node>;

	moveNode(nodeId: number, parentId: number | null): Promise<Node>;

	toggleManagePermissions(isOpen: boolean, clearSelectionOnClose?: boolean): void;

	updateNode(node: Node): void;

	updateNodePermissions(node: Node, permissions: NodePermissionRequest[]): Promise<Node>;

	closeUploadWidgetAndClearTasks(): void;

	closeUploadWidget(): void;

	clearCompletedUploadTasks(): void;

	toggleBottomDrawer(isOpen: boolean): void;

	toggleNodeActions(isOpen: boolean): void;

	showInfo(node: Node): void;

	toggleFileInput(isOpen: boolean): void;
}

const sortNodes = (nodes: NodeInfo[], sort: Sort, sortDirection: SortDirection): NodeInfo[] => {
	const sortFn: (n1: Node, n2: Node) => number = {
		[Sort.Name]: (n1: Node, n2: Node) => n1.name.localeCompare(n2.name),
		[Sort.CreatedAt]: (n1: Node, n2: Node) => Date.parse(n1.createdAt) - Date.parse(n2.createdAt),
	}[sort];

	return nodes.sort((n1, n2) => {
		const sortResult = sortFn(n1.node, n2.node);

		switch (sortDirection) {
			case (SortDirection.Desc):
				return sortResult * -1;
			default:
				return sortResult;
		}
	});
}

const nodeIsChild = (node: Node, parent: Node | undefined): boolean => {
	return (node.parent && parent && node.parent.id === parent.id) || (!node.parent && !parent);
}

const selectSingleNode = (nodes: NodeInfo[], selectedNode: Node): { nodes: NodeInfo[], selectionCount: number } => {
	let selectionCount = 0;
	const newNodes = nodes.map(nodeInfo => {
		const {node, selected} = nodeInfo;
		if (node.id === selectedNode.id && !selected) {
			selectionCount++;
			return {
				...nodeInfo,
				selected: true
			};
		}

		if (node.id !== selectedNode.id && selected) {
			return {
				...nodeInfo,
				selected: false
			};
		}

		return nodeInfo;
	});

	return {
		nodes: newNodes,
		selectionCount,
	};
};

const clearSelection = (nodes: NodeInfo[]): NodeInfo[] => {
	return nodes.map(nodeInfo => {
		if (nodeInfo.selected) {
			return {
				...nodeInfo,
				selected: false
			};
		}

		return nodeInfo;
	})
};

const toggleNodeAction = (
	state: FileManagerState,
	isOpen: boolean,
	clearSelectionOnClose?: boolean
): Partial<FileManagerState> => {
	const {nodes, clearSelectionOnClose: existingClearSelectionOnClose, selectionCount} = state;
	const shouldClearSelection = !isOpen && existingClearSelectionOnClose;

	return {
		bottomDrawerOpen: isOpen ? false : undefined,
		nodeActionsOpen: isOpen ? false : undefined,
		clearSelectionOnClose: isOpen ? clearSelectionOnClose : false,
		nodes: shouldClearSelection ? clearSelection(nodes) : nodes,
		selectionCount: shouldClearSelection ? 0 : selectionCount,
	};
}

class FileManagerApiImpl implements FileManagerApi {
	constructor(private store: Store<FileManagerState>) {
	}

	loadFolder = async (folderId?: number) => {
		const {sort, sortDirection} = this.store.getState();
		this.store.updateState({
			loading: true,
		});

		let breadcrumb: Node[] = [];
		let folder: Node | undefined;

		if (folderId) {
			folder = await getFolder(folderId);
			breadcrumb = await getNodePathFromRoot(folder.id);
		}

		const nodes = await getNodes(folderId);
		this.store.updateState({
			folder,
			nodes: sortNodes(nodes.map(createNodeInfo), sort, sortDirection),
			loading: false,
			selectionCount: 0,
			breadcrumb
		});
	};

	addToSelection = (selectedNode: Node) => {
		const state = this.store.getState();
		const {nodes} = state;
		let {selectionCount} = state;
		const newNodes = [];

		for (const nodeInfo of nodes) {
			const {node, selected} = nodeInfo;
			if (node.id === selectedNode.id && !selected) {
				selectionCount++;
				newNodes.push({...nodeInfo, selected: true});
			} else {
				newNodes.push(nodeInfo);
			}
		}

		this.store.updateState({
			nodes: newNodes,
			selectionCount,
		});
	};

	removeFromSelection = (selectedNode: Node) => {
		const state = this.store.getState();
		const {nodes} = this.store.getState();
		let {selectionCount} = state;
		const newNodes = [];

		for (const nodeInfo of nodes) {
			const {node, selected} = nodeInfo;
			if (node.id === selectedNode.id && selected) {
				selectionCount--;
				newNodes.push({...nodeInfo, selected: false});
			} else {
				newNodes.push(nodeInfo);
			}
		}

		this.store.updateState({
			nodes: newNodes,
			selectionCount,
		});
	};

	setSelection = (selectedNode: Node) => {
		const {nodes} = this.store.getState();
		const {nodes: newNodes, selectionCount} = selectSingleNode(nodes, selectedNode);

		this.store.updateState({
			nodes: newNodes,
			selectionCount,
		});
	};

	showActions = (selectedNode: Node) => {
		const {nodes} = this.store.getState();
		const {nodes: newNodes, selectionCount} = selectSingleNode(nodes, selectedNode);

		this.store.updateState({
			selectionCount,
			nodes: newNodes,
			nodeActionsOpen: true,
			clearSelectionOnClose: true,
		});
	};

	clearSelection = () => {
		const {nodes} = this.store.getState();
		this.store.updateState({
			nodes: clearSelection(nodes),
			selectionCount: 0,
		});
	};

	selectRange = (startNode: Node, endNode: Node) => {
		const state = this.store.getState();
		const {nodes} = this.store.getState();
		let {selectionCount} = state;

		let startNodeIdx = -1;
		let endNodeIdx = -1;

		for (let i = 0; i < nodes.length && startNodeIdx === -1 && endNodeIdx === -1; i++) {
			const {node} = nodes[i];
			if (node.id === startNode.id) {
				startNodeIdx = startNode.id;
			}

			if (node.id === endNode.id) {
				endNodeIdx = endNode.id
			}
		}

		if (startNodeIdx > endNodeIdx) {
			const temp = startNodeIdx;
			startNodeIdx = endNodeIdx;
			endNodeIdx = temp;
		}

		const newNodes = [];

		for (let i = 0; i < nodes.length; i++) {
			const nodeInfo = nodes[i];
			const {selected} = nodeInfo;
			if (i < startNodeIdx || i > endNodeIdx) {
				if (selected) {
					selectionCount--;
					newNodes.push({...nodeInfo, selected: false});
				} else {
					newNodes.push(nodeInfo);
				}
			} else {
				if (selected) {
					newNodes.push(nodeInfo);
				} else {
					selectionCount++;
					newNodes.push({...nodeInfo, selected: true});
				}
			}
		}

		this.store.updateState({
			nodes: newNodes,
			selectionCount,
		});
	};

	getFirstSelected = (): Node | undefined => {
		const {nodes} = this.store.getState();
		const selected = nodes.find(n => n.selected);
		return selected ? selected.node : undefined;
	};

	getSelection = () => {
		const {nodes} = this.store.getState();
		const selectedNodes = [];
		for (const nodeInfo of nodes) {
			if (nodeInfo.selected) {
				selectedNodes.push(nodeInfo.node);
			}
		}

		return selectedNodes;
	};

	openCreateFolder = () => {
		this.store.updateState({
			createFolderOpen: true,
			deleteNodesOpen: false,
			bottomDrawerOpen: false,
		});
	};

	closeCreateFolder = () => {
		this.store.updateState({
			createFolderOpen: false
		});
	};

	createFolder = async (name: string, parentId?: number) => {
		const newFolder = await createFolder({name, parentId});
		const {folder} = this.store.getState();

		if (!folder && !parentId) {
			await this.loadFolder();
		} else if (folder && parentId && folder.id === parentId) {
			await this.loadFolder(folder.id);
		}

		return newFolder;
	};

	closeDeleteNodes = () => {
		this.store.updateState({
			...toggleNodeAction(this.store.getState(), false),
			deleteNodesOpen: false,
		});
	};

	openDeleteNodes = (clearSelectionOnClose?: boolean) => {
		const {selectionCount} = this.store.getState();
		if (!selectionCount) {
			return;
		}

		this.store.updateState({
			...toggleNodeAction(this.store.getState(), true, clearSelectionOnClose),
			createFolderOpen: false,
			deleteNodesOpen: true,
			bottomDrawerOpen: false,
			nodeActionsOpen: false,
		});
	};

	toggleRenameNode = (isOpen: boolean, clearSelectionOnClose?: boolean) => {
		this.store.updateState({
			...toggleNodeAction(this.store.getState(), isOpen, clearSelectionOnClose),
			renameNodeOpen: isOpen,
		});
	}

	toggleMoveNode = (isOpen: boolean, clearSelectionOnClose?: boolean) => {
		this.store.updateState({
			...toggleNodeAction(this.store.getState(), isOpen, clearSelectionOnClose),
			moveNodeOpen: isOpen,
		});
	}

	deleteNodes = async (nodes: Node[]) => {
		const {folder} = this.store.getState();
		const parentIds = nodes.map(({parent}) => parent ? parent.id : undefined);

		const folderId = folder ? folder.id : undefined;

		await Promise.all(nodes.map(({id}) => deleteNode(id)));

		if (parentIds.indexOf(folderId) !== -1) {
			await this.loadFolder(folderId);
		}
	};

	setSort = (newSort: Sort, newDirection: SortDirection): void => {
		const {nodes, sort, sortDirection} = this.store.getState();
		if (newSort === sort && newDirection === sortDirection) {
			return;
		}

		const newNodes = sortNodes(nodes, newSort, newDirection);
		this.store.updateState({
			nodes: newNodes,
			sort: newSort,
			sortDirection: newDirection
		});
	};

	uploadFiles = (files: DOMFile[], parentId?: number): void => {
		const {uploadTasks} = this.store.getState();
		const uploadTaskLength = Object.keys(uploadTasks).length;
		let newUploadTasks: { [key: number]: UploadTask } = {};

		const doUploadFile = (id: number, domFile: DOMFile) => {
			const fileTask: UploadTask = {
				id,
				file: undefined,
				domFile,
				progress: 0,
				state: UploadTaskState.Pending,
				cancel: () => void 0
			};

			const updateTaskState = (cb: (task: UploadTask) => UploadTask, stateCb?: (task: UploadTask) => void) => {
				const {uploadTasks} = this.store.getState();
				const task = cb(uploadTasks[id]);
				this.store.updateState({
					uploadTasks: {
						...uploadTasks,
						[id]: task
					}
				}, () => stateCb ? stateCb(task) : undefined);
			};

			createFile({name: domFile.name, parentId}).then(file => {
				const progressCallback = (progress: UploadProgress) => {
					updateTaskState(task => ({
						...task,
						state: UploadTaskState.Uploading,
						progress: progress.progress
					}));
				};

				const {request, cancel} = uploadFile(file, domFile, progressCallback);

				const cancelCallback = () => {
					updateTaskState(task => ({
						...task,
						state: UploadTaskState.Cancelled
					}));
					cancel();
				};

				updateTaskState(task => {
					return {
						...task,
						state: UploadTaskState.Uploading,
						cancel: cancelCallback
					}
				});

				return request().then(file => {
					updateTaskState(task => {
						return {
							...task,
							file,
							state: UploadTaskState.Completed,
							progress: 100,
						}
					}, task => {
						const {folder} = this.store.getState();
						if (folder?.id !== undefined && task.file?.parent?.id) {
							this.loadFolder(folder?.id);
						} else if (folder?.id === undefined && task.file?.parent?.id === undefined) {
							this.loadFolder();
						}
					});
				}, () => {
					updateTaskState(task => ({
						...task,
						state: UploadTaskState.Failed,
					}))
				});
			}, () => {
				updateTaskState(task => ({
					...task,
					state: UploadTaskState.Failed,
				}))
			});

			return fileTask;
		};

		for (let i = 0; i < files.length; i++) {
			const taskId = uploadTaskLength + i + 1;
			newUploadTasks[taskId] = doUploadFile(taskId, files[i]);
		}

		this.store.updateState({
			showUploadWidget: true,
			expandUploadWidget: true,
			uploadTasks: {
				...uploadTasks,
				...newUploadTasks
			},
			bottomDrawerOpen: false,
			fileInputOpen: false,
		});
	};

	toggleUploadWidget = (isOpen: boolean) => {
		this.store.updateState({
			showUploadWidget: isOpen
		});
	};

	expandUploadWidget = (isExpanded: boolean) => {
		this.store.updateState({
			expandUploadWidget: isExpanded
		});
	};

	getUploadTasksForStates = (...taskStates: UploadTaskState[]) => {
		const {uploadTasks} = this.store.getState();
		const filteredUploadTasks = [];

		for (const key in uploadTasks) {
			if (uploadTasks.hasOwnProperty(key)) {
				if (taskStates.indexOf(uploadTasks[key].state) !== -1) {
					filteredUploadTasks.push(uploadTasks[key]);
				}
			}
		}

		return filteredUploadTasks;
	};

	toggleSidebar = (isOpen: boolean): void => {
		const {clearSelectionOnClose, nodes, selectionCount} = this.store.getState();

		this.store.updateState({
			sidebarOpen: isOpen,
			clearSelectionOnClose: false,
			nodes: clearSelectionOnClose ? clearSelection(nodes) : nodes,
			selectionCount: clearSelectionOnClose ? 0 : selectionCount,
		});
	}

	renameNode = async (nodeId: number, name: string): Promise<Node> => {
		const node = await renameNode(nodeId, name);
		this.updateNode(node);
		return node;
	}

	moveNode = async (nodeId: number, parentId: number | null): Promise<Node> => {
		const node = await moveNode(nodeId, parentId);
		this.updateNode(node);
		return node;
	}

	toggleManagePermissions = (isOpen: boolean, clearSelectionOnClose?: boolean) => {
		this.store.updateState({
			...toggleNodeAction(this.store.getState(), isOpen, clearSelectionOnClose),
			managePermissionsOpen: isOpen,
		});
	}

	updateNode = (node: Node) => {
		const {nodes, folder, selectionCount} = this.store.getState();
		const nodeIdx = nodes.findIndex(({node: existingNode}) => existingNode.id === node.id);

		if (nodeIdx !== -1) {
			const {selected} = nodes[nodeIdx];
			const inCurrentFolder = nodeIsChild(node, folder);
			const newSelectionCount = Math.max((selected && !inCurrentFolder) ? selectionCount - 1 : selectionCount, 0);

			this.store.updateState({
				selectionCount: newSelectionCount,
				nodes: [
					...nodes.slice(0, nodeIdx),
					...inCurrentFolder ? [{
						...nodes[nodeIdx],
						node
					}] : [],
					...nodes.slice(nodeIdx + 1)
				]
			});
		}
	}

	updateNodePermissions = async (node: Node, permissions: NodePermissionRequest[]): Promise<Node> => {
		const newPermissions = await updateNodePermissions(node.id, permissions);
		const newNode = {...node, effectivePermissions: newPermissions};
		this.updateNode(newNode);
		return newNode;
	}

	closeUploadWidgetAndClearTasks = () => {
		const {uploadTasks} = this.store.getState();
		const updatedUploadTasks: UploadTaskMap = {};

		Object.keys(uploadTasks).forEach(key => {
			const uploadTask = uploadTasks[Number(key)];

			if (uploadTask.state !== UploadTaskState.Completed && uploadTask.state !== UploadTaskState.Cancelled) {
				updatedUploadTasks[Number(key)] = uploadTask;
			}
		});

		this.store.updateState({
			showUploadWidget: false,
			uploadTasks: updatedUploadTasks
		});
	}

	clearCompletedUploadTasks = (): void => {
		const {uploadTasks} = this.store.getState();
		const updatedUploadTasks: UploadTaskMap = {};

		Object.keys(uploadTasks).forEach(key => {
			const uploadTask = uploadTasks[Number(key)];

			if (uploadTask.state !== UploadTaskState.Completed && uploadTask.state !== UploadTaskState.Cancelled) {
				updatedUploadTasks[Number(key)] = uploadTask;
			}
		});

		this.store.updateState({
			uploadTasks: updatedUploadTasks,
		});
	}

	closeUploadWidget = (): void => {
		this.store.updateState({
			showUploadWidget: false
		});
	}


	toggleBottomDrawer = (isOpen: boolean) => {
		this.store.updateState({
			bottomDrawerOpen: isOpen
		});
	}

	toggleNodeActions = (isOpen: boolean) => {
		const {nodes, selectionCount} = this.store.getState();

		this.store.updateState({
			nodes: isOpen ? nodes : clearSelection(nodes),
			nodeActionsOpen: isOpen,
			selectionCount: isOpen ? selectionCount : 0,
		});
	}

	showInfo = (node: Node) => {
		const {nodes} = this.store.getState();
		const {nodes: newNodes, selectionCount} = selectSingleNode(nodes, node);

		this.store.updateState({
			nodes: newNodes,
			selectionCount,
			sidebarOpen: true,
			clearSelectionOnClose: true,
			nodeActionsOpen: false,
		});
	}

	toggleFileInput = (isOpen: boolean) => {
		this.store.updateState({
			fileInputOpen: isOpen,
			bottomDrawerOpen: isOpen ? false : undefined,
		});
	}
}

export function createFileManagerApi(store: Store<FileManagerState>): FileManagerApi {
	return new FileManagerApiImpl(store);
}
