import React, {FunctionComponent, ReactNode, useEffect, useRef} from "react";

const SCROLL_THRESHOLD = 0.9 // %;

export interface InfiniteScrollProps {
	disabled: boolean;
	onLoadMore: () => void;
}

export const InfiniteScroll: FunctionComponent<InfiniteScrollProps> = (props) => {
	const {
		children,
		disabled,
		onLoadMore,
	} = props;

	const childRef = useRef<Element>();
	const child = React.Children.only<ReactNode>(children);

	useEffect(() => {
		if (!childRef.current) {
			return;
		}

		const element = childRef.current!;

		const handleScroll = () => {
			if (disabled) {
				return;
			}

			if (element.scrollHeight > element.clientHeight) {
				if (element.scrollTop + element.clientHeight > element.scrollHeight * SCROLL_THRESHOLD) {
					onLoadMore();
				}
			}
		};

		handleScroll();
		element?.addEventListener('scroll', handleScroll);

		return () => element.removeEventListener('scroll', handleScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disabled]);

	if (!React.isValidElement(child)) {
		return <div>
			{child}
		</div>
	}

	return React.cloneElement(child, {
		ref: childRef
	});
};
