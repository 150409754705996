import {authenticatedApiClient} from "./client";
import {Node} from "./node";

export interface CreateFolderForm {
  name: string;
  parentId?: number;
}

export async function getFolder(folderId: number): Promise<Node> {
  const {data} = await authenticatedApiClient.get<Node>(`folders/${folderId}`)
  return data;
}

export async function createFolder({name, parentId}: CreateFolderForm): Promise<Node> {
  const {data} = await authenticatedApiClient.post<Node>('folders', {
    name,
    parent: parentId
  });

  return data;
}
