import React, {createContext, useContext} from "react";
import {AuthApi, createApi} from "./api";
import {Store} from "../core/store";
import {clearInterceptors, setupInterceptors} from "./interceptors";
import {AuthState, getInitialState} from "./state";

export interface AuthContextProps {
  state: AuthState;
  api: AuthApi;
}

export const AuthContext = createContext<AuthContextProps>({
  state: {} as AuthState,
  api: {} as AuthApi,
});

export const useAuthContext = () => useContext(AuthContext);

export const useAuthState = () => {
  const {state} = useContext(AuthContext);
  return state;
}

export const useAuthApi = () => {
  const {api} = useContext(AuthContext);
  return api;
}

export interface AuthProviderProps {

}

export class AuthProvider extends React.Component<AuthProviderProps, AuthState> {
  private readonly api: AuthApi;
  private readonly store: Store<AuthState>;

  constructor(props: Readonly<AuthProviderProps>) {
    super(props);

		this.store = new Store({
      getState: () => this.state,
      setState: (state, cb) => this.setState(state, cb)
    });

    this.api = createApi(this.store);
    this.state = getInitialState();
  }

  render() {
    return <AuthContext.Provider
      value={{state: this.state, api: this.api}}
      children={this.props.children}/>
  }

	componentDidMount() {
		super.componentDidMount?.();
		this.setState({
			...this.state,
			...setupInterceptors(this.store)
		});
	}

	componentWillUnmount() {
		super.componentWillUnmount?.();
		this.setState({
			...this.state,
			...clearInterceptors(this.store)
		});
	}
}
