import React, {FunctionComponent} from "react";
import {Link, RouteComponentProps} from "@reach/router";
import {Button, Paper, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useProfileState} from "./provider";
import {UserRoleName} from "../users/userRoleName";
import {GroupsTable} from "../users/groupsTable";
import {ScrollContainer} from "../ui/common";
import {NavbarHome} from "./navbarHome";
import {Navbar, NavbarActions, NavbarBreadcrumbs} from "../ui/navbar";

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		margin: theme.spacing(2),
		marginTop: 0,
		padding: theme.spacing(2),
	},
	gridContainer: {
		display: 'inline-grid',
		gridTemplateColumns: 'max-content 1fr',
		gridColumnGap: '20px',
		alignItems: 'center',
		gridRowGap: '5px',
		marginBottom: theme.spacing(2),
	},
	header: {
		marginBottom: theme.spacing(2)
	},
}));

const useShowPage = (props: ShowPageProps) => {
	const {profile, isAdmin} = useProfileState();

	return {
		profile,
		showGroupsPanel: !isAdmin,
	}
};

export interface ShowPageProps extends RouteComponentProps {

}

export const ShowPage: FunctionComponent<ShowPageProps> = (props) => {
	const styles = useStyles();

	const {
		profile,
		showGroupsPanel,
	} = useShowPage(props);

	const notificationSettings = profile?.notificationSettings;

	const Header: FunctionComponent = ({children}) => <Typography component="h6" variant="h6" className={styles.header}>
		{children}
	</Typography>;

	const Label: FunctionComponent = ({children}) => <Typography color="textSecondary">
		{children}
	</Typography>;

	const Value: FunctionComponent = ({children}) => <Typography>
		{children}
	</Typography>;

	const BoolValue: FunctionComponent<{ value?: boolean }> = ({value}) =>
		value ? <Value>Sim</Value> : <Value>Não</Value>;

	return <ScrollContainer>
		<Navbar>
			<NavbarBreadcrumbs>
				<NavbarHome/>
			</NavbarBreadcrumbs>
			<NavbarActions>
				<Button color="primary" variant="contained" component={Link} to="edit">
					Editar Perfil
				</Button>
			</NavbarActions>
		</Navbar>

		<Paper className={styles.container}>
			<Header>
				Informações Básicas
			</Header>

			<div className={styles.gridContainer}>
				<Label>Nome</Label>
				<Value>{profile?.name}</Value>

				<Label>Email</Label>
				<Value>{profile?.email}</Value>

				<Label>Tipo de Utilizador</Label>
				<Value><UserRoleName role={profile?.role}/></Value>
			</div>

			<Header>
				Notificações
			</Header>

			<div className={styles.gridContainer}>
				<Label>Criação de pastas</Label>
				<BoolValue value={notificationSettings?.newFolderNotificationEnabled}/>

				<Label>Criação de ficheiros</Label>
				<BoolValue value={notificationSettings?.newFileNotificationEnabled}/>

				<Label>Alteração de pastas</Label>
				<BoolValue value={notificationSettings?.updatedFolderNotificationEnabled}/>

				<Label>Alteração de ficheiros</Label>
				<BoolValue value={notificationSettings?.updatedFileNotificationEnabled}/>
			</div>

			{showGroupsPanel && <>
				<Header>
					Grupos
				</Header>

				<GroupsTable
					groups={profile?.groups || []}
					enableRemove={false}
					emptyLabel="Você não pertence a nenhum grupo"/>
			</>}
		</Paper>
	</ScrollContainer>;
}
