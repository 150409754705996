import {apiClient} from "./client";

export interface LoginParams {
  email: string;
  password: string;
}

export interface AccessToken {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

export async function login({email, password}: LoginParams): Promise<AccessToken> {
  const {data} = await apiClient.post('/login', {username: email, password});
  return data;
}

export async function refreshToken(refreshToken: string): Promise<AccessToken> {
  const {data} = await apiClient.post('/token', {refreshToken});
  return data;
}
