import React, {FunctionComponent, MouseEvent, useCallback} from "react";
import {FileManagerApi} from "./api";
import styled from "@material-ui/core/styles/styled";
import {Box, List} from "@material-ui/core";
import {GridItem, GridItemLoading, ListItem, ListItemLoading} from "./item";
import theme from "../theme";
import {Node, NodeType} from "../api/node";
import {NodeInfo} from "./state";
import {useBreakpointContext} from "../ui/breakpointProvider";

interface FileListProps {
	loading: boolean;
	files: NodeInfo[];
	api: FileManagerApi;
	onNavigateToFolder: (node: Node) => void;
	onDownloadFile: (node: Node) => void;
}

interface FileDisplayProps {
	loading: boolean;
	files: NodeInfo[];

	onToggleSelection(node: NodeInfo, e: MouseEvent): void;

	onNavigate(node: NodeInfo): void;

	onClearSelection(e: MouseEvent): void;

	onShowActions(node: NodeInfo): void;
}

const FileListWrapper = styled(Box)({
	width: '100%',
	height: '100%',
});

const GridItems = styled(Box)({
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fill, 125px)',
	gridAutoRows: 'fit-content',
	gridRowGap: theme.spacing(1),
	gridColumnGap: theme.spacing(1)
});

const FileListLoading: FunctionComponent = () => (
	<FileListWrapper>
		<GridItems>
			<GridItemLoading/>
			<GridItemLoading/>
			<GridItemLoading/>
		</GridItems>
	</FileListWrapper>
);

export const FileGridDisplay = (props: FileDisplayProps) => {
	const {files, onClearSelection, onToggleSelection, onNavigate, loading, onShowActions} = props;

	if (loading) {
		return <FileListLoading/>;
	}

	return <FileListWrapper onClick={onClearSelection}>
		<GridItems>
			{files.map((nodeInfo) =>
				<GridItem item={nodeInfo.node}
									key={nodeInfo.node.id}
									selected={nodeInfo.selected}
									onSelect={(e) => onToggleSelection(nodeInfo, e)}
									onNavigate={() => onNavigate(nodeInfo)}
									onShowActions={() => onShowActions(nodeInfo)}/>)}
		</GridItems>
	</FileListWrapper>
}

export const FileListDisplay: FunctionComponent<FileDisplayProps> = (props) => {
	const {loading, files, onClearSelection, onNavigate, onToggleSelection, onShowActions} = props;

	if (loading) {
		return <List>
			<ListItemLoading/>
			<ListItemLoading/>
			<ListItemLoading/>
		</List>
	}

	return <FileListWrapper onClick={onClearSelection}>
		<List>
			{files.map(nodeInfo => (
				<ListItem
					item={nodeInfo.node}
					key={nodeInfo.node.id}
					selected={nodeInfo.selected}
					onSelect={(e) => onToggleSelection(nodeInfo, e)}
					onNavigate={() => onNavigate(nodeInfo)}
					onShowActions={() => onShowActions(nodeInfo)}/>
			))}
		</List>
	</FileListWrapper>
};

export const FileList: FunctionComponent<FileListProps> = (props) => {
	const {isMobile} = useBreakpointContext();
	const {api, onNavigateToFolder, onDownloadFile, loading, files} = props;

	const handleToggleSelection = useCallback(({node, selected}: NodeInfo, e: MouseEvent) => {
		if (selected) {
			const selection = api.getSelection();
			if (selection.length > 1) {
				api.setSelection(node);
			} else {
				api.removeFromSelection(node);
			}
		} else {
			if (!e.shiftKey && !e.ctrlKey && !e.metaKey) {
				api.setSelection(node);
			} else if (e.ctrlKey || e.metaKey) {
				api.addToSelection(node);
			} else {
				const selection = api.getSelection();
				if (selection.length) {
					api.selectRange(selection[0], node);
				} else {
					api.setSelection(node);
				}
			}
		}

		e.stopPropagation();
	}, [api]);

	const handleNavigate = useCallback(({node}: NodeInfo) => {
		if (node.type === NodeType.Folder) {
			onNavigateToFolder(node);
		} else if (node.type === NodeType.File) {
			onDownloadFile(node);
		}
	}, [onNavigateToFolder, onDownloadFile]);

	const handleClearSelection = useCallback((e: MouseEvent) => {
		api.clearSelection();
		e.stopPropagation();
	}, [api]);

	const handleShowActions = useCallback((nodeInfo: NodeInfo) => {
		api.showActions(nodeInfo.node);
	}, [api]);

	if (isMobile) {
		return <FileListDisplay
			loading={loading}
			files={files}
			onClearSelection={handleClearSelection}
			onNavigate={handleNavigate}
			onToggleSelection={handleToggleSelection}
			onShowActions={handleShowActions}/>;
	}

	return <FileGridDisplay
		loading={loading}
		files={files}
		onClearSelection={handleClearSelection}
		onNavigate={handleNavigate}
		onToggleSelection={handleToggleSelection}
		onShowActions={handleShowActions}/>
}
