import React, {FunctionComponent, ReactNode} from "react";
import {useProfileState} from "../profile/provider";
import {Node, NodePermissionType} from "../api/node";
import {Profile} from "../api/profile";
import {isAdmin} from "../api/users";
import {FolderPermission} from "./isGranted";

export interface IsGrantedOnFolderProps {
	nodes: Node | Node[] | undefined;
	permission: FolderPermission;
	children: (props: { isGranted: boolean }) => ReactNode;
}

const hasPermission = (profile: Profile | undefined, permission: FolderPermission, nodes: Node | Node[] | undefined) => {
	if (!profile) {
		return false;
	}

	if (isAdmin(profile)) {
		return true;
	}

	if (nodes !== undefined && !Array.isArray(nodes)) {
		nodes = [nodes];
	}

	if (!nodes || nodes.length === 0) {
		return false;
	}

	const profileEntities = [profile.permissionEntityId]
		.concat(profile.groups.map(g => g.permissionEntityId));

	const hasWritePermissions = (node: Node) =>
		node.effectivePermissions.find(p =>
			profileEntities.indexOf(p.permissionEntity.permissionEntityId) !== -1 &&
			p.type === NodePermissionType.Write) !== undefined;

	switch (permission) {
		case FolderPermission.ManageNode:
		case FolderPermission.UploadFile:
			return nodes.every(n => hasWritePermissions(n));
		default:
			return false;
	}
}
export const IsGrantedOnFolder: FunctionComponent<IsGrantedOnFolderProps> = (props) => {
	const {children, permission, nodes} = props;
	const {profile} = useProfileState();

	return <>{children({
		isGranted: hasPermission(profile, permission, nodes)
	})}</>;
};
