import axios, {AxiosError} from "axios";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json'
});

export const authenticatedApiClient = axios.create(apiClient.defaults);

export function isAxiosError(err: any): err is AxiosError {
	return !!err && err.hasOwnProperty('isAxiosError') && err.isAxiosError;
}
