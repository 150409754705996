import React, {FunctionComponent, useState} from "react";
import {FileManagerApi} from "./api";
import {Button, Dialog, DialogContent, DialogTitle, LinearProgress, Theme} from "@material-ui/core";
import {FormErrors} from "../api/formErrors";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {Node} from '../api/node';
import {makeStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import {CustomDialogActions} from "../ui/customDialogActions";

export interface RenameNodeProps {
	node: Node;
	open: boolean;
	api: FileManagerApi;
}

export interface RenameNodeForm {
	name: string;
}

const useStyles = makeStyles<Theme>(theme => ({
	nameField: {
		minWidth: '300px',
		maxWidth: '80vw',
	},
	error: {
		margin: theme.spacing(1, 0),
		textAlign: 'center'
	},
}));

export const RenameNode: FunctionComponent<RenameNodeProps> = (props) => {
	const {open, node, api} = props;
	const styles = useStyles();
	const [error, setError] = useState<string | undefined>();

	return <Dialog open={open} onClose={() => api.toggleRenameNode(false)}>
		<Formik
			initialValues={{
				name: node.name
			}}
			validate={(values: RenameNodeForm): FormErrors<RenameNodeForm> => {
				const errors: FormErrors<RenameNodeForm> = {};

				if (!values.name) {
					errors.name = 'Obrigatório';
				}

				return errors;
			}}
			onSubmit={async (values, {setSubmitting}) => {
				try {
					setSubmitting(true);
					await api.renameNode(node.id, values.name);
					setSubmitting(false);
					setError(undefined);
					api.toggleRenameNode(false);
				} catch (e) {
					setSubmitting(false);
					setError("Ocorreu um erro inesperado. Por favor tente novamente");
				}
			}}>
			{({isSubmitting}) => (
				<Form>
					<DialogTitle>Mudar o nome</DialogTitle>

					<DialogContent>
						<Field id="name"
									 name="name"
									 placeholder="Nome"
									 component={TextField}
									 className={styles.nameField}
									 autoFocus/>

						{isSubmitting && <LinearProgress/>}

						{error && <Typography
							variant="body2"
							color="error"
							className={styles.error}>{error}</Typography>}
					</DialogContent>

					<CustomDialogActions>
						<Button variant="text"
										color="default"
										type="button"
										disabled={isSubmitting}
										onClick={() => api.toggleRenameNode(false)}>
							Cancelar
						</Button>
						<Button variant="contained"
										color="primary"
										disabled={isSubmitting}
										type="submit">
							Alterar
						</Button>
					</CustomDialogActions>
				</Form>
			)}
		</Formik>
	</Dialog>;
}
