import {RouteComponentProps} from "@reach/router";
import React, {FunctionComponent} from "react";
import {FullPageRouter} from "../ui/router";
import {IndexPage} from "./indexPage";
import {RecoverPage} from "./recoverPage";

export const PasswordRecoveryModule: FunctionComponent<RouteComponentProps> = () => {
	return (
		<FullPageRouter>
			<IndexPage path="/"/>
			<RecoverPage path="/recover"/>
			<RecoverPage path="/recover/:token"/>
		</FullPageRouter>
	);
}
