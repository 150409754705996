import React, {FunctionComponent, ReactNode} from "react";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import clsx from "clsx";
import {useBreakpointContext} from "./breakpointProvider";

export interface MasterDetailProps {
	node?: Node;
	detailWidth?: string;
	open: boolean;
	detail: ReactNode;
}

const useStyles = makeStyles<Theme, MasterDetailProps>(theme => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		position: 'relative',
	},
	master: {
		flex: '1',
		overflow: 'auto',
		display: 'flex',
		flexDirection: 'row'
	},
	detail: ({open, detailWidth}) => ({
		borderLeft: `${open ? '5px' : '0px'} solid ${theme.palette.grey["200"]}`,
		flex: '0 0 auto',
		width: open ? (detailWidth ? detailWidth : '350px') : '0px',
		overflow: open ? 'initial' : 'hidden',
		transition: 'all 200ms',
	}),
	detailMobile: ({open}) => ({
		position: 'absolute',
		borderLeft: 'none',
		width: '100%',
		height: '100%',
		transform: open ? 'translateY(0)' : 'translateY(100%)',
		backgroundColor: theme.palette.background.default,
	}),
	detailInner: ({detailWidth}) => ({
		width: detailWidth ? detailWidth : '350px',
		maxHeight: '100%',
		overflow: 'auto',
	}),
	detailInnerMobile: ({detailWidth}) => ({
		width: detailWidth ? detailWidth : '100%'
	})
}));

export const MasterDetail: FunctionComponent<MasterDetailProps> = (props) => {
	const {children, detail} = props;
	const styles = useStyles(props);
	const {isMobile} = useBreakpointContext();

	return <div className={styles.root}>
		<div className={styles.master}>{children}</div>
		<div className={clsx(styles.detail, isMobile && styles.detailMobile)}>
			<div className={clsx(styles.detailInner, isMobile && styles.detailInnerMobile)}>
				{detail}
			</div>
		</div>
	</div>
}
