import React, {ComponentClass, FunctionComponent, PropsWithChildren} from "react";
import styled from "@material-ui/core/styles/styled";
import {withStyles} from "@material-ui/core";

export const FullPage = <P, >(c: FunctionComponent<P> | ComponentClass<P>) => styled(c)({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
	justifyContent: 'stretch',
	overflow: 'hidden'
});

export const ScrollContainer = withStyles({
	root: {
		'overflow': 'auto'
	}
})(({children, classes}: PropsWithChildren<{ classes: { root: any } }>) => {
	return <div className={classes.root}>{children}</div>
});

export const FullPageContainer = withStyles({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'stretch',
		overflow: 'hidden'
	},
	child: {
		flex: '1'
	}
})(({children, classes}: PropsWithChildren<{ classes: { root: any, child: any } }>) => {
	const childrenWithClass = React.Children.map(children, child => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				className: `${child.props.className} ${classes.child}`
			});
		}

		return child;
	});

	return <div className={classes.root}>
		{childrenWithClass}
	</div>
});

export const FlexSpacer = withStyles({
	root: {
		flexGrow: 1
	}
})(({classes}: { classes: { root: any } }) => {
	return <div className={classes.root}/>;
})
