import {FileManagerApi} from "./api";
import React, {FunctionComponent, useState} from "react";
import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress} from "@material-ui/core";
import {pluralize} from "../util";
import {Node} from "../api/node";
import {CustomDialogActions} from "../ui/customDialogActions";

export interface DeleteFolderProps {
	selectionCount: number;
	nodes: Node[];
	open: boolean;
	api: FileManagerApi;
}

export const DeleteNodes: FunctionComponent<DeleteFolderProps> = ({open, nodes, selectionCount, api}) => {
	const [isSubmitting, setSubmitting] = useState(false);

	return <Dialog open={open} onClose={api.closeDeleteNodes}>
		<DialogTitle>Apagar Pasta</DialogTitle>

		<DialogContent>
			<DialogContentText>
				Deseja apagar mesmo apagar {selectionCount} {pluralize(selectionCount, "documento", "documentos")}?
				<br/>
				Esta ação não pode ser revertida.
			</DialogContentText>

			{isSubmitting && <LinearProgress/>}
		</DialogContent>
		<CustomDialogActions>
			<Button variant="text"
							color="default"
							type="button"
							disabled={isSubmitting}
							onClick={api.closeDeleteNodes}>
				Cancelar
			</Button>
			<Button variant="contained"
							color="primary"
							type="submit"
							disabled={isSubmitting}
							onClick={async () => {
								setSubmitting(true);
								await api.deleteNodes(nodes);
								setSubmitting(false);
								api.closeDeleteNodes();
							}}>
				Apagar
			</Button>
		</CustomDialogActions>
	</Dialog>;
};
