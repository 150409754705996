import React, {FunctionComponent} from "react";
import {Link as MaterialLink, Typography} from "@material-ui/core";
import {Link} from "@reach/router";

export const NavbarHome: FunctionComponent<{ to?: string }> = props => {
	const {to} = props;

	if (!to) {
		return <Typography color="textPrimary">
			O meu Perfil
		</Typography>;
	}

	return <MaterialLink component={Link} to={to} color="inherit">
		O meu Perfil
	</MaterialLink>;
}
