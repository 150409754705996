import React, {FunctionComponent, SyntheticEvent, useCallback, useRef, useState} from "react";
import {createGroup, CreateGroupRequest, Group, updateGroup} from "../api/groups";
import {makeStyles} from "@material-ui/styles";
import * as yup from 'yup';
import {Field, Form, Formik, FormikHelpers} from "formik";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormLabel,
	Snackbar,
	SnackbarCloseReason,
	TextField
} from "@material-ui/core";
import {CustomDialogActions} from "../ui/customDialogActions";
import {FormActions} from "../ui/formActions";

const useStyles = makeStyles({
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	fieldset: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		marginBottom: '40px',
	},
	legend: {
		marginBottom: '10px',
		fontSize: '1.2em',
		lineHeight: '1.2em',
		fontWeight: 'bold'
	}
});

export interface GroupFormProps {
	group?: Group;
	onAfterSubmit?: (group: Group) => void;
	onReset: () => void;
}

type FormType = Partial<CreateGroupRequest>;

const getValidationSchema = (group?: Group) => yup.object().shape({
	name: yup.string().required("é obrigatório")
});

const getInitialValues = (group?: Group): FormType => ({
	name: group?.name
});

const useGroupForm = (props: GroupFormProps) => {
	const {onReset: onAfterReset, onAfterSubmit, group} = props;
	const {current: validationSchema} = useRef(getValidationSchema(group));
	const {current: initialValues} = useRef(getInitialValues(group))
	const {current: isNewGroup} = useRef(!group || !group.groupId);
	const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>();
	const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

	const doSubmit = useCallback((form: CreateGroupRequest) => {
		if (!isNewGroup) {
			return updateGroup(group!.groupId, form);
		} else {
			return createGroup(form);
		}
	}, [isNewGroup, group]);

	const onSubmit = useCallback(async (values: FormType, helpers: FormikHelpers<FormType>) => {
		const {
			setSubmitting,
			resetForm
		} = helpers;

		setSubmitting(true);

		try {
			const group = await doSubmit({
				name: values.name!,
			});

			setSubmitting(false);
			isNewGroup && resetForm({values: initialValues});
			onAfterSubmit && onAfterSubmit(group);
			setSuccessSnackbarOpen(true);

		} catch (e) {
			setSubmitting(false);
			setHasSubmitError(true);
		}
	}, [doSubmit, initialValues, onAfterSubmit, isNewGroup]);

	const onReset = useCallback(() => {
		onAfterReset && onAfterReset();
	}, [onAfterReset]);

	const handleSnackbarClose = useCallback((event: SyntheticEvent, reason: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSuccessSnackbarOpen(false);
	}, []);

	const handleSubmitErrorDialogClose = useCallback(() => {
		setHasSubmitError(false);
	}, []);

	return {
		group,
		onSubmit,
		onReset,
		initialValues,
		validationSchema,
		successSnackbarOpen,
		handleSnackbarClose,
		isNewGroup,
		hasSubmitError,
		handleSubmitErrorDialogClose,
	}
};

export const GroupForm: FunctionComponent<GroupFormProps> = (props) => {
	const styles = useStyles();
	const {
		onSubmit,
		initialValues,
		validationSchema,
		onReset,
		successSnackbarOpen,
		handleSnackbarClose,
		isNewGroup,
		hasSubmitError,
		handleSubmitErrorDialogClose
	} = useGroupForm(props);

	return <>
		<Formik
			initialValues={initialValues}
			validateOnMount={false}
			validationSchema={validationSchema}
			onReset={onReset}
			onSubmit={onSubmit}>
			{({isSubmitting, errors, touched}) => (
				<Form className={styles.form}>
					<FormControl component="fieldset" className={styles.fieldset}>
						<FormLabel component="legend" className={styles.legend}>Definições Básicas</FormLabel>

						<Field id="name"
									 name="name"
									 label="Nome"
									 as={TextField}
									 margin="normal"
									 error={touched.name && !!errors.name}
									 helperText={touched.name && errors.name}
									 disabled={isSubmitting}/>
					</FormControl>

					<FormActions>
						<Button variant="contained"
										disabled={isSubmitting}
										type="reset">
							Cancelar
						</Button>

						<Button variant="contained"
										color="primary"
										disabled={isSubmitting}
										type="submit">
							{isNewGroup
								? <>Criar Grupo</>
								: <>Atualizar Grupo</>}
						</Button>
					</FormActions>
				</Form>
			)}
		</Formik>

		<Snackbar
			anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
			open={successSnackbarOpen}
			message={isNewGroup
				? 'Grupo criado com sucesso'
				: 'Grupo atualizado com sucesso'
			}
			autoHideDuration={1000}
			onClose={handleSnackbarClose}/>

		<Dialog
			open={hasSubmitError}
			onClose={handleSubmitErrorDialogClose}>
			<DialogTitle>Erro</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{isNewGroup
						? 'Ocorreu um erro ao criar o grupo. Por favor tente novamente.'
						: 'Ocorreu um erro ao atualizar o grupo. Por favor tente novamente.'}
				</DialogContentText>
			</DialogContent>
			<CustomDialogActions>
				<Button onClick={handleSubmitErrorDialogClose} color="primary" autoFocus>
					Ok
				</Button>
			</CustomDialogActions>
		</Dialog>
	</>
}
