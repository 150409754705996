export interface Upstream<S> {
  getState: () => S;
  setState: (state: S, callback?: () => void) => void;
}

export class Store<S> {
  constructor(private upstream: Upstream<S>) {

  }

  getState(): S {
    return this.upstream.getState();
  }

  updateState(state: Partial<S>, callback?: () => void): void {
    this.upstream.setState({...this.getState(), ...state}, callback);
  }

  async updateStateAsync(state: Partial<S>): Promise<void> {
    return new Promise(resolve => this.updateState(state, () => resolve()));
  }
}
