import React, {FunctionComponent} from 'react';
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Theme,
	Tooltip,
	Typography
} from "@material-ui/core";
import {Delete as DeleteIcon} from "@material-ui/icons";
import {GroupSummary} from "../api/groupSummary";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	emptyContainer: {
		margin: theme.spacing(2),
		marginLeft: 0
	}
}));

export interface GroupsTableProps {
	groups: GroupSummary[];
	enableRemove?: boolean;
	onRemove?: (group: GroupSummary) => void;
	emptyLabel?: string;
}

export const GroupsTable: FunctionComponent<GroupsTableProps> = (props) => {
	const styles = useStyles();

	const {
		groups,
		enableRemove,
		onRemove,
		emptyLabel,
	}: GroupsTableProps = {
		enableRemove: false,
		...props
	};

	if (!groups.length) {
		return <Typography color="textSecondary" className={styles.emptyContainer}>
			{emptyLabel !== undefined
				? emptyLabel
				: <>
					O utilizador não pertence a nenhum grupo
				</>}
		</Typography>;
	}

	return <TableContainer>
		<Table>
			<TableBody>
				{groups.map(group => (
					<TableRow>
						<TableCell>{group.name}</TableCell>
						{enableRemove && <>
							<TableCell>
								<Tooltip title="Remover">
									<IconButton color="secondary" onClick={() => onRemove && onRemove(group)}>
										<DeleteIcon/>
									</IconButton>
								</Tooltip>
							</TableCell>
						</>}
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>;
};
