import {Store} from "../core/store";
import {AccessToken, login} from "../api/auth";
import {storeAccessToken} from "./localStorage";
import {AuthState} from "./state";

export interface AuthApi {
	login(username: string, password: string): Promise<AccessToken>;

	logout(): Promise<void>;

	storeAccessToken(accessToken: AccessToken): Promise<void>;
}

export const createApi = (store: Store<AuthState>): AuthApi => {
	return {
		async login(email: string, password: string): Promise<AccessToken> {
			const accessToken = await login({email, password});
			const isLoggedIn = !!accessToken;

			storeAccessToken(accessToken);

			await store.updateStateAsync({
				accessToken,
				isLoggedIn,
			});

			return accessToken;
		},
		async logout() {
			storeAccessToken(undefined);

			return await store.updateStateAsync({
				accessToken: undefined,
				isLoggedIn: false,
			});
		},
		async storeAccessToken(accessToken: AccessToken) {
			storeAccessToken(accessToken);

			await store.updateStateAsync({
				accessToken,
				isLoggedIn: true
			});
		}
	};
}
