import {FunctionComponent} from "react";
import {DialogActions, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import {useBreakpointContext} from "./breakpointProvider";

const useStyles = makeStyles((theme: Theme) => ({
	mobile: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		'& > :not(:last-child)': {
			marginBottom: theme.spacing(1)
		}
	}
}));

export const CustomDialogActions: FunctionComponent = props => {
	const {children} = props;
	const styles = useStyles();
	const {isMobile} = useBreakpointContext();

	return <DialogActions
		className={clsx(isMobile && styles.mobile)}
		disableSpacing={isMobile}>
		{children}
	</DialogActions>
}
