import {FunctionComponent, useEffect, useState} from "react";
import {RouteComponentProps} from "@reach/router";
import {generateDownloadUrl} from "../api/file";
import {isAxiosError} from "../api/client";
import {Typography} from "@material-ui/core";

interface DownloadPageParams {
	fileId: string;
}

enum ErrorType {
	Unknown,
	NotFound,
}

interface DownloadPageState {
	isLoading: boolean;
	errorType: ErrorType | undefined;
	downloadUrl: string | undefined;
}

export const DownloadPage: FunctionComponent<RouteComponentProps<DownloadPageParams>> = props => {
	const {fileId} = props;

	const [state, setState] = useState<DownloadPageState>({
		isLoading: false,
		errorType: undefined,
		downloadUrl: undefined,
	});

	const {isLoading, errorType, downloadUrl} = state;
	const isError = errorType !== undefined;

	useEffect(() => {
		(async () => {
			setState({
				isLoading: true,
				errorType: undefined,
				downloadUrl: undefined,
			});

			try {
				if (fileId === undefined) {
					setState({
						isLoading: false,
						errorType: ErrorType.Unknown,
						downloadUrl: undefined,
					});

					return;
				}

				const downloadUrl = await generateDownloadUrl(parseInt(fileId, 10));

				setState({
					isLoading: false,
					errorType: undefined,
					downloadUrl
				});
			} catch (e) {
				if (isAxiosError(e) && e.response?.status === 404) {
					setState({
						isLoading: false,
						errorType: ErrorType.NotFound,
						downloadUrl: undefined,
					});
				} else {
					setState({
						isLoading: false,
						errorType: ErrorType.Unknown,
						downloadUrl: undefined,
					});
				}
			}
		})();
	}, [fileId]);

	useEffect(() => {
		if (downloadUrl) {
			window.location.replace(downloadUrl);
		}
	}, [downloadUrl]);

	return <div>
		{isLoading && <>
			Por favor aguarde...
		</>}

		{isError && <>
			<Typography variant={"h6"} align={"center"}>
				{errorType === ErrorType.NotFound && <>
					O ficheiro não foi encontrado.
					Por favor feche a aba e tente novamente.
				</>}

				{errorType === ErrorType.Unknown && <>
					Ocorreu um erro inesperado.
					Por favor feche a aba e tente novamente.
				</>}
			</Typography>
		</>}
	</div>;
}
