import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Link, RouteComponentProps} from "@reach/router";
import {
	Button,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../theme";
import {defaultQueryParams, parseQueryParams} from "../util/parseQueryParams";
import * as yup from 'yup';
import {getGroups} from "../api/groups";
import {ScrollContainer} from "../ui/common";
import {LoadingOverlay} from "../ui/loadingOverlay";
import {Delete as DeleteIcon, Edit as EditIcon, Visibility as VisibilityIcon} from "@material-ui/icons";
import {LoadingPanel} from "../ui/loadingPanel";
import {ErrorPanel} from "../ui/errorPanel";
import {DeleteGroupDialog, useDeleteGroupDialog} from "./deleteGroupDialog";
import {GroupSummary} from "../api/groupSummary";
import {Navbar, NavbarActions, NavbarBreadcrumbs} from "../ui/navbar";
import clsx from "clsx";
import {useBreakpointContext} from "../ui/breakpointProvider";

const indexPageStyles = makeStyles({
	tableContainer: {
		margin: theme.spacing(2)
	},
	mobileTableRow: {
		display: 'flex',
		flexDirection: 'column',
		'& td:not(:last-child)': {
			borderBottomColor: 'transparent'
		}
	},
	actionsCell: {
		textAlign: 'right'
	}
});

enum ViewState {
	Initial,
	Loading,
	LoadError,
	Empty,
}

interface Params {
	page: number;
}

interface Pagination {
	page: number;
	perPage: number;
	count: number;
}

const paramsSchema = yup.object().shape({
	page: yup.number().default(1)
});

const useIndexPage = (props: RouteComponentProps) => {
	const {location, navigate} = props;
	const [params, setParams] = useState<Params>();
	const [groups, setGroups] = useState<GroupSummary[]>([]);
	const [state, setState] = useState<ViewState>(ViewState.Initial);
	const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
	const [pagination, setPagination] = useState<Pagination>({
		count: 0,
		page: 0,
		perPage: 0,
	});

	const loadGroups = useCallback(async () => {
		if (!params) {
			return;
		}

		setState(ViewState.Loading);

		try {
			const paginatedGroups = await getGroups({
				page: params?.page
			});

			setGroups(paginatedGroups.items);
			setPagination({
				count: paginatedGroups.count,
				page: paginatedGroups.page - 1,
				perPage: paginatedGroups.perPage
			});
			setState(paginatedGroups.items.length || paginatedGroups.page > 1
				? ViewState.Initial
				: ViewState.Empty
			);
			setIsInitialLoad(false);
		} catch (e) {
			setGroups([]);
			setPagination({
				count: 0,
				perPage: 0,
				page: 0
			});
			setState(ViewState.LoadError);
		}
	}, [params]);

	const onChangePage = useCallback((_, page) => {
		const newParams = {
			...params,
			page: page + 1
		};

		const url = new URLSearchParams(newParams);
		if (navigate) {
			navigate(`.?${url.toString()}`);
		}
	}, [params, navigate]);

	const deleteGroupDialog = useDeleteGroupDialog({
		onGroupDeleted: loadGroups
	})

	useEffect(() => {
		if (location) {
			setParams(parseQueryParams(location, paramsSchema));
		} else {
			setParams(defaultQueryParams(paramsSchema));
		}
	}, [location]);

	useEffect(() => {
		// noinspection JSIgnoredPromiseFromCall
		loadGroups();
	}, [params, loadGroups]);

	return {
		isInitialLoad,
		isInitial: state === ViewState.Initial,
		isLoading: state === ViewState.Loading,
		isLoadError: state === ViewState.LoadError,
		isEmpty: state === ViewState.Empty,
		onChangePage,
		onReload: loadGroups,
		groups,
		...pagination,
		...deleteGroupDialog
	}
}

export const IndexPage: FunctionComponent<RouteComponentProps> = (props) => {
	const styles = indexPageStyles();
	const {
		perPage,
		count,
		page,
		onReload,
		isInitial,
		onChangePage,
		groups,
		isLoading,
		isEmpty,
		isInitialLoad,
		isLoadError,
		onDeleteGroupDialogGroup,
		onDeleteGroup,
		onDeleteGroupExited,
		onDeleteGroupDialogOpen,
		onDeleteGroupSubmit,
		onDeleteGroupCancel,
	} = useIndexPage(props);

	const {isMobile} = useBreakpointContext();

	return <>
		<Navbar>
			<NavbarBreadcrumbs>
				<Typography color="textPrimary">Grupos</Typography>
			</NavbarBreadcrumbs>

			<NavbarActions>
				<Button color="primary" variant="contained" component={Link} to="new">
					Novo Grupo
				</Button>
			</NavbarActions>
		</Navbar>

		{!isInitialLoad && (isLoading || isInitial) && <>
			<ScrollContainer>
				<Paper className={styles.tableContainer}>
					<LoadingOverlay loading={isLoading}>
						<TableContainer>
							<Table>
								{!isMobile && <>
									<TableHead>
										<TableRow>
											<TableCell>#</TableCell>
											<TableCell>Nome</TableCell>
											<TableCell/>
										</TableRow>
									</TableHead>
								</>}

								<TableBody>
									{groups.map(group => (
										<TableRow key={group.groupId} className={clsx(isMobile && styles.mobileTableRow)}>
											{!isMobile && <>
												<TableCell>{group.groupId}</TableCell>
											</>}

											<TableCell>{group.name}</TableCell>
											<TableCell className={styles.actionsCell}>
												<Tooltip title="Ver">
													<IconButton component={Link} to={`${group.groupId}`}>
														<VisibilityIcon/>
													</IconButton>
												</Tooltip>

												<Tooltip title="Editar">
													<IconButton component={Link} to={`${group.groupId}/edit`}>
														<EditIcon/>
													</IconButton>
												</Tooltip>

												<Tooltip title="Apagar">
													<IconButton onClick={() => onDeleteGroup(group)} color="secondary">
														<DeleteIcon/>
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									))}
								</TableBody>

								<TableFooter>
									<TableRow>
										<TablePagination count={count}
																		 onChangePage={onChangePage}
																		 page={page}
																		 rowsPerPageOptions={[]}
																		 rowsPerPage={perPage}>
										</TablePagination>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</LoadingOverlay>
				</Paper>
			</ScrollContainer>
		</>}

		{isInitialLoad && isLoading && <>
			<LoadingPanel>
				A carregar grupos...
			</LoadingPanel>
		</>}

		{isEmpty && <>
			<ErrorPanel showRetryButton={false}>
				Não existem grupos para apresentar.
			</ErrorPanel>
		</>}

		{isLoadError && <>
			<ErrorPanel showRetryButton={true} onRetry={onReload}>
				Ocorreu um erro a carregar os grupos.
				<br/>
				Por favor tente novamente.
			</ErrorPanel>
		</>}


		<DeleteGroupDialog
			group={onDeleteGroupDialogGroup}
			open={onDeleteGroupDialogOpen}
			onCancel={onDeleteGroupCancel}
			onSubmit={onDeleteGroupSubmit}
			onExited={onDeleteGroupExited}/>
	</>
};
