import {Skeleton} from "@material-ui/lab";
import React, {FunctionComponent, MouseEvent, useCallback} from "react";
import styled from "@material-ui/core/styles/styled";
import {
	Box,
	IconButton,
	ListItem as MaterialListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Theme
} from "@material-ui/core";
import theme from "../theme";
import {Node} from "../api/node";
import {ItemIcon} from "./itemIcon";
import {makeStyles} from "@material-ui/styles";
import {Folder as FolderIcon, MoreHoriz} from "@material-ui/icons";

interface ItemProps {
	item: Node;
	selected: boolean;
	onNavigate: () => void;
	onShowActions: () => void;
	onSelect: (event: MouseEvent) => void;
}

const ItemWrapper = styled(Box)({
	textAlign: 'center',
	padding: theme.spacing(1),
});

const IconLoading = styled(Skeleton)({
	marginLeft: 'auto',
	marginRight: 'auto',
});

export const GridItemLoading = () => (
	<ItemWrapper>
		<IconLoading variant="rect" width={90} height={90}/>
		<Skeleton variant="text"/>
	</ItemWrapper>
);

export const ListItemLoading = () => (
	<MaterialListItem>
		<ListItemIcon>
			<Skeleton>
				<FolderIcon/>
			</Skeleton>
		</ListItemIcon>
		<ListItemText>
			<Skeleton/>
		</ListItemText>
		<ListItemSecondaryAction/>
	</MaterialListItem>
)

const useStyle = makeStyles<Theme, ItemProps>(theme => {
	const baseLabel = {
		...theme.typography.body1,
		fontSize: '12px',
		margin: '0',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		display: 'block',
		paddingLeft: '5px',
		paddingRight: '5px',
		width: '100%',
	};

	return ({
		label: ({selected}) => ({
			...baseLabel,
			whiteSpace: 'nowrap',
			...selected ? {
				visibility: 'hidden'
			} : {}
		}),
		floatingLabel: ({selected}) => ({
			...baseLabel,
			position: 'absolute',
			top: '0',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			'-webkit-line-clamp': 3,
			'-webkit-box-orient': 'vertical',
			display: '-webkit-box',
			wordBreak: 'break-word',
			...!selected ? {
				visibility: 'hidden'
			} : {},
		}),
		labelContainer: {
			position: 'relative'
		},
		icon: {
			fontSize: '90px'
		},
		iconWrapper: ({selected}) => ({
			display: 'inline-block',
			borderRadius: theme.shape.borderRadius, // 5px
			padding: '2px',
			lineHeight: '0',
			...selected ? {
				backgroundColor: theme.palette.grey["200"],
			} : {},
		})
	});
});

export const GridItem = (props: ItemProps) => {
	const {item, selected, onSelect, onShowActions, onNavigate, ...rest} = props;
	const style = useStyle(props);

	const handleClick = useCallback((e: MouseEvent) => {
		e.stopPropagation();
		onSelect(e);
	}, [onSelect]);

	const handleDoubleClick = useCallback((e: MouseEvent) => {
		e.stopPropagation();
		onNavigate();
	}, [onNavigate]);

	return <ItemWrapper {...rest} title={item.name} onClick={handleClick} onDoubleClick={handleDoubleClick}>
		<div className={style.iconWrapper}>
			<ItemIcon color="primary" node={item} className={style.icon}/>
		</div>

		<div className={style.labelContainer}>
			<h3 className={style.label}>
				{item.name}
			</h3>

			<h3 className={style.floatingLabel}>
				{item.name}
			</h3>
		</div>
	</ItemWrapper>;
};

export const ListItem: FunctionComponent<ItemProps> = (props: ItemProps) => {
	const {item, onNavigate, onShowActions, selected} = props;

	const handleClick = useCallback((e: MouseEvent) => {
		e.stopPropagation();
		onNavigate();
	}, [onNavigate]);

	const handleShowActions = useCallback((e: MouseEvent) => {
		e.stopPropagation();
		onShowActions();
	}, [onShowActions]);

	return <MaterialListItem onClick={handleClick} selected={selected}>
		<ListItemIcon>
			<ItemIcon color="primary" node={item}/>
		</ListItemIcon>
		<ListItemText>
			{item.name}
		</ListItemText>

		<ListItemSecondaryAction>
			<IconButton onClick={handleShowActions}>
				<MoreHoriz/>
			</IconButton>
		</ListItemSecondaryAction>

	</MaterialListItem>
};
