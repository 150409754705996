import React, {FunctionComponent} from "react";
import {FileDownloadStat} from "../api/file";
import {Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {FormattedDate} from "../ui/formattedDate";
import {Person as PersonIcon} from "@material-ui/icons";


const useStyles = makeStyles((theme: Theme) => ({
	list: {
		listStyle: 'none',
		marginTop: 0,
		padding: 0,
		marginBottom: theme.spacing(-1),
	},
	listItem: {
		marginBottom: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		marginLeft: theme.spacing(1),
	},
	userName: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	lastDownloadAt: {
		fontSize: theme.typography.fontSize,
	}
}));

export interface SidebarDownloadListProps {
	downloadStats: FileDownloadStat[];
}

export const SidebarDownloadList: FunctionComponent<SidebarDownloadListProps> = (props) => {
	const {downloadStats} = props;
	const styles = useStyles();

	if (!downloadStats || !downloadStats.length) {
		return <>Sem visualizações</>;
	}

	return <ul className={styles.list}>
		{downloadStats.map((downloadStat) => (
			<li key={downloadStat.userId} className={styles.listItem}>
				<div>
					<PersonIcon/>
				</div>
				<div className={styles.details}>
					<div className={styles.userName}>
						{downloadStat.userName}
					</div>
					<Typography
						variant="body1"
						component="span"
						className={styles.lastDownloadAt}
						color="textSecondary">
						<FormattedDate date={downloadStat.lastDownloadAt} dateStyle='short'/>
					</Typography>
				</div>
			</li>
		))}
	</ul>;
}
