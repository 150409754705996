import React from 'react';
import './App.css';
import {FileManagerModule} from './fileManager';
import '@fontsource/roboto';
import {CssBaseline} from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {PasswordRecoveryModule} from "./passwordRecovery";
import theme from "./theme";
import {AuthProvider} from "./auth/provider";
import {ProtectedRoute} from "./auth/protectedRoute";
import {LoginPage} from "./auth/loginPage";
import {FullPageRouter} from "./ui/router";
import {AppBar} from "./ui/appBar";
import {ProfileProvider} from "./profile/provider";
import {UsersModule} from "./users";
import {GroupsModule} from "./groups";
import {GenericPermission} from "./permissions/isGranted";
import {ProfileModule} from "./profile";
import {DownloadModule} from "./download";
import {BreakpointProvider} from "./ui/breakpointProvider";

const ProtectedApp = () => (
	<>
		<AppBar/>
		<FullPageRouter>
			<ProtectedRoute
				as={UsersModule}
				path={"/users/*"}
				permission={GenericPermission.ManageUsers}/>

			<ProtectedRoute
				as={GroupsModule}
				path={"/groups/*"}
				permission={GenericPermission.ManageGroup}/>

			<FileManagerModule path="/*"/>

			<ProfileModule path="/profile/*"/>
		</FullPageRouter>
	</>
);

function App() {
	return (
		<CssBaseline>
			<BreakpointProvider>
				<AuthProvider>
					<ProfileProvider>
						<ThemeProvider theme={theme}>
							<FullPageRouter>
								<LoginPage path="/login"/>
								<PasswordRecoveryModule path="/recover-password/*"/>
								<ProtectedRoute path="/download/*" as={DownloadModule}/>
								<ProtectedRoute path="/*" as={ProtectedApp}/>
							</FullPageRouter>
						</ThemeProvider>
					</ProfileProvider>
				</AuthProvider>
			</BreakpointProvider>
		</CssBaseline>
	);
}

export default App;
