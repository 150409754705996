import React, {FunctionComponent} from "react";
import {pluralize} from "../util";
import {Box, IconButton, Theme, useTheme} from "@material-ui/core";
import {NodeInfo} from "./state";
import {Add as AddIcon, CloudUpload as CloudUploadIcon} from "@material-ui/icons";
import {FileManagerApi} from "./api";
import {Node} from "../api/node";
import {IsGrantedOnFolder} from "../permissions/isGrantedOnFolder";
import {FolderPermission} from "../permissions/isGranted";
import {useBreakpointContext} from "../ui/breakpointProvider";

export interface FooterProps {
	folder: Node | undefined;
	sidebarOpen: boolean;
	loading: boolean;
	nodes: NodeInfo[];
	selectionCount: number;
	api: FileManagerApi;
	bottomDrawerOpen: boolean;
	uploadWidgetOpen: boolean;
}

export interface FooterTextProps {
	nodes: NodeInfo[];
	selectionCount: number;
}

const FooterText: FunctionComponent<FooterTextProps> = ({nodes, selectionCount}) => (
	selectionCount > 0
		? <>{selectionCount} de {nodes.length} {pluralize(selectionCount, 'selecionado', 'selecionados')}</>
		: <>{nodes.length} {pluralize(nodes.length, 'item', 'items')}</>
)

export const Footer: FunctionComponent<FooterProps> = (props) => {
	const {loading, nodes, selectionCount, api, bottomDrawerOpen, uploadWidgetOpen, folder} = props;
	const theme = useTheme<Theme>();
	const {isMobile} = useBreakpointContext();

	return <Box
		paddingLeft={1}
		paddingRight={1}
		paddingBottom={1}
		borderTop={1}
		borderColor={theme.palette.grey["300"]}
		bgcolor={theme.palette.grey["200"]}
		display='flex'
		alignItems='center'>

		{isMobile && <>
			<IsGrantedOnFolder nodes={folder ? [folder] : []} permission={FolderPermission.UploadFile}>
				{({isGranted}) => isGranted ? <>
					<IconButton
						onClick={() => api.toggleUploadWidget(!uploadWidgetOpen)}
						color={uploadWidgetOpen ? 'primary' : 'default'}>
						<CloudUploadIcon/>
					</IconButton>
				</> : <>
					<IconButton disabled={true}/>
				</>}
			</IsGrantedOnFolder>
		</>}

		<Box
			textAlign="center"
			padding={1}
			flex={1}>
			{loading
				? <>A carregar...</>
				: <FooterText nodes={nodes} selectionCount={selectionCount}/>}
		</Box>

		{isMobile && <>
			<IsGrantedOnFolder nodes={folder ? [folder] : []} permission={FolderPermission.UploadFile}>
				{({isGranted}) => isGranted ? <>
					<IconButton
						onClick={() => api.toggleBottomDrawer(!bottomDrawerOpen)}
						color={bottomDrawerOpen ? 'primary' : 'default'}>
						<AddIcon/>
					</IconButton>
				</> : <>
					<IconButton disabled={true}/>
				</>}
			</IsGrantedOnFolder>
		</>}
	</Box>;
}
