import React, {FunctionComponent, useCallback, useRef, useState} from "react";
import {ClickAwayListener, FormControl, IconButton, Input, InputAdornment, Theme} from "@material-ui/core";
import {Close as CloseButton, Search as SearchIcon} from "@material-ui/icons";
import {
	isPermissionEntityGroup,
	isPermissionEntityUser,
	PermissionEntity,
	SearchCriteria
} from "../api/permissionEntity";
import {Field, FieldProps, Form, Formik} from "formik";
import {ClassNameMap, makeStyles} from "@material-ui/styles";
import {SearchFormDropdown} from "./searchFormDropdown";


const useStyles = makeStyles((theme: Theme) => ({
	searchForm: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: theme.spacing(2),
	},
	searchInput: {
		flex: '1',
	},
	selectedEntities: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: 0,
		margin: '-2px',
		marginBottom: theme.spacing(1),
	},
	selectedEntity: {
		margin: '2px',
		border: `1px solid ${theme.palette.divider}`,
		padding: '2px 2px 2px 12px',
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: theme.palette.divider,
		},
		'& > span': {
			maxWidth: '100px',
			display: 'inline-block',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		}
	},
}));

export interface SearchFormProps {
	selectedEntities: PermissionEntity[];
	onSelect: (entity: PermissionEntity) => void;
	onRemove: (entity: PermissionEntity) => void;
}

const SearchButton = (<InputAdornment position="end">
	<IconButton type="submit"><SearchIcon/></IconButton>
</InputAdornment>);

const PermissionEntityName = ({entity}: { entity: PermissionEntity }) => (
	isPermissionEntityUser(entity)
		? <span>{entity.user.name}</span>
		: isPermissionEntityGroup(entity)
		? <span>{entity.group.name}</span>
		: <></>
);


interface SelectedEntitiesFormProps {
	entities: PermissionEntity[];
	styles: ClassNameMap<any>;
	onRemove: (entity: PermissionEntity) => void;
	onClick: () => void;
}

const SelectedEntitiesForm: FunctionComponent<SelectedEntitiesFormProps> = (props) => {
	const {styles, entities, onRemove, onClick} = props;

	if (!entities.length) {
		return <></>;
	}

	return <ul className={styles.selectedEntities} onClick={onClick}>
		{entities.map(entity => (
			<li
				className={styles.selectedEntity}
				onClick={(e) => e.stopPropagation()}>
				<PermissionEntityName entity={entity}/>
				<IconButton
					size="small"
					onClick={(e) => {
						e.stopPropagation();
						onRemove(entity);
					}}>
					<CloseButton/>
				</IconButton>
			</li>
		))}
	</ul>;
};

export const SearchForm: FunctionComponent<SearchFormProps> = (props) => {
	const {
		onSelect,
		onRemove,
		selectedEntities,
	} = props;
	const styles = useStyles();
	const inputRef = useRef<HTMLDivElement | null>(null);
	const [formValue, setFormValue] = useState<SearchCriteria>({q: undefined});
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

	const handleFocus = useCallback(() => {
		// setSelectedEntities([]);
		setDropdownOpen(true);
	}, []);

	const handleClickAway = useCallback(() => {
		// setSelectedEntities([]);
		setDropdownOpen(false);
	}, []);

	const handleSubmit = useCallback((values: SearchCriteria) => {
		setFormValue(values);
	}, []);

	const handleSelect = useCallback((entity: PermissionEntity) => {
		onSelect(entity);
		setDropdownOpen(false);
	}, [onSelect]);

	const handleRemoveSelectedEntity = useCallback((entity: PermissionEntity) => {
		onRemove(entity);
	}, [onRemove]);

	return <ClickAwayListener onClickAway={handleClickAway}>
		<div>
			<Formik
				onSubmit={(values) => handleSubmit(values)}
				initialValues={{
					q: undefined,
				}}>
				<Form className={styles.searchForm}>
					<Field name="q">
						{({field}: FieldProps) => (
							<FormControl className={styles.searchInput}>
								<SelectedEntitiesForm
									entities={selectedEntities}
									styles={styles}
									onRemove={handleRemoveSelectedEntity}
									onClick={handleClickAway}/>

								<Input
									{...field}
									ref={inputRef}
									autoComplete="off"
									autoCorrect="off"
									onFocus={handleFocus}
									endAdornment={SearchButton}/>
							</FormControl>
						)}
					</Field>

					<SearchFormDropdown
						selectedEntities={selectedEntities}
						inputEl={inputRef.current}
						open={dropdownOpen}
						formValue={formValue}
						onSelect={handleSelect}/>
				</Form>
			</Formik>
		</div>
	</ClickAwayListener>
};
