import React, {FunctionComponent, PropsWithChildren, useCallback, useEffect} from "react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	LinearProgress,
	Snackbar
} from "@material-ui/core";
import {useLoadingViewStateWithSnackbar} from "../core/useLoadingViewState";
import {CustomDialogActions} from "./customDialogActions";

export interface ConfirmRemoteOperationDialogProps<R> {
	open: boolean;
	onExited: (isSubmitted: boolean) => void;
	onSubmit: () => R | Promise<R> | undefined;
	onAfterSubmit: (r: R) => void;
	onCancel: () => void;
	initialText: React.ReactNode;
	loadingText: React.ReactNode;
	submitErrorText: React.ReactNode;
	cancelButtonText?: string;
	submitButtonText?: string;
	submittedText?: React.ReactNode;
	successText?: string;
}

export const ConfirmRemoteOperationDialog: FunctionComponent<ConfirmRemoteOperationDialogProps<any>> =
	<R, >(props: PropsWithChildren<ConfirmRemoteOperationDialogProps<R>>) => {

		const {
			open,
			onExited,
			onSubmit: onParentSubmit,
			onAfterSubmit,
			loadingText,
			submitErrorText,
			onCancel,
			cancelButtonText,
			submitButtonText,
			retryButtonText,
			successText,
			initialText,
			submittedText,
		} = {
			cancelButtonText: 'Cancelar',
			submitButtonText: 'Confirmar',
			retryButtonText: 'Tentar novamente',
			successText: 'Operação bem sucedida!',
			submittedText: 'Operação bem sucedida!',
			...props
		};

		const {
			snackbarAnchorOrigin,
			snackbarTimer,
			snackbarOpen,
			snackbarHandleClose,
			isInitial,
			isSubmitError,
			isSubmitted,
			isLoading,
			onSubmit,
			resetState,
		} = useLoadingViewStateWithSnackbar({
			onSubmit: onParentSubmit,
			onAfterSubmit,
		});

		useEffect(() => {
			open && resetState();
		}, [open, resetState]);

		const handleExited = useCallback(async () => {
			onExited(isSubmitted);
		}, [isSubmitted, onExited]);

		return <>
			<Dialog open={open} disableBackdropClick={true} onExited={handleExited}>
				<DialogTitle>Remover Grupo</DialogTitle>

				<DialogContent>
					<DialogContentText>
						{isInitial && initialText}
						{isLoading && loadingText}
						{isSubmitError && submitErrorText}
						{isSubmitted && submittedText}
					</DialogContentText>

					{isLoading && <LinearProgress/>}
				</DialogContent>
				<CustomDialogActions>
					{(isInitial || isLoading) && <>
						<Button variant="text"
										color="default"
										type="button"
										disabled={isLoading}
										onClick={onCancel}>
							{cancelButtonText}
						</Button>
						<Button variant="contained"
										color="primary"
										type="submit"
										disabled={isLoading}
										onClick={onSubmit}>
							{submitButtonText}
						</Button>
					</>}

					{isSubmitError && <>
						<Button variant="text"
										color="default"
										type="button"
										disabled={isLoading}
										onClick={onCancel}>
							{cancelButtonText}
						</Button>
						<Button variant="contained"
										color="primary"
										type="submit"
										disabled={isLoading}
										onClick={onSubmit}>
							{retryButtonText}
						</Button>
					</>}
				</CustomDialogActions>
			</Dialog>

			<Snackbar
				anchorOrigin={snackbarAnchorOrigin}
				open={snackbarOpen}
				message={successText}
				autoHideDuration={snackbarTimer}
				onClose={snackbarHandleClose}/>
		</>
	}
