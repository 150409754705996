import React, {FunctionComponent} from "react";
import {LinearProgress, Paper, Typography} from "@material-ui/core";
import {FullPageContainer} from "./common";
import {makeStyles} from "@material-ui/styles";
import theme from "../theme";

const useStyles = makeStyles({
	loadingText: {
		marginBottom: theme.spacing(1),
		textAlign: 'center'
	},
	loadingContainer: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	loadingProgress: {
		minWidth: '300px',
		maxWidth: '80vw'
	}
});

export const LoadingPanel: FunctionComponent = (props) => {
	const {children} = props;
	const styles = useStyles();

	return <FullPageContainer>
		<Paper className={styles.loadingContainer}>
	<Typography variant="h6" component="h6" className={styles.loadingText}>
		{children}
	</Typography>

	<LinearProgress className={styles.loadingProgress}/>
	</Paper>
	</FullPageContainer>;
}
