import React, {FunctionComponent, SyntheticEvent, useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	LinearProgress,
	Snackbar,
	SnackbarCloseReason,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow
} from "@material-ui/core";
import {getGroups} from "../api/groups";
import {updateGroups, User} from "../api/users";
import {GroupSummary} from "../api/groupSummary";
import {LoadingOverlay} from "../ui/loadingOverlay";
import {CustomDialogActions} from "../ui/customDialogActions";

export interface AddUserGroupDialogProps {
	open: boolean;
	user: User;
	onSubmit?: (user: User) => void;
	onCancel?: () => void;
}

const useStyles = makeStyles({
	loadingContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	loadingBar: {
		width: '100%'
	}
});

const useAddUserGroupDialog = (props: AddUserGroupDialogProps) => {
	const {user, onSubmit: onParentSubmit, onCancel: onParentCancel, open} = props;
	const [groups, setGroups] = useState<GroupSummary[]>([]);
	const [selectedGroup, setSelectedGroup] = useState<number>();
	const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(0);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
	const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>();

	const reloadGroups = useCallback(async () => {
		if (!open) {
			return;
		}

		setLoading(true);

		const paginatedGroups = await getGroups({
			page: page + 1,
			withoutUserIdMembership: user?.userId
		});

		if (selectedGroup && !paginatedGroups.items.find(g => g.groupId === selectedGroup)) {
			setSelectedGroup(undefined);
		}

		setCount(paginatedGroups.count);
		setPage(paginatedGroups.page - 1);
		setPerPage(paginatedGroups.perPage);
		setLoading(false);
		setGroups(paginatedGroups.items);
		setIsInitialLoad(false);
	}, [open, user, page, selectedGroup]);

	const isGroupSelected = (groupId: number) =>
		selectedGroup === groupId;

	const onToggleGroupSelection = useCallback((groupId) => {
		if (selectedGroup === groupId) {
			setSelectedGroup(undefined);
		} else {
			setSelectedGroup(groupId);
		}
	}, [selectedGroup]);

	const onChangePage = useCallback((_, page) => {
		setPage(page);
		setIsInitialLoad(false);
	}, []);

	const onSubmit = useCallback(async () => {
		if (!selectedGroup || !user || !user?.userId) {
			return;
		}

		const groupIds = [
			...user.groups.map(g => g.groupId),
			selectedGroup
		];

		setSubmitting(true);

		try {
			const updatedUser = await updateGroups(user.userId, groupIds)
			setSubmitting(false);
			setHasSubmitError(false);
			onParentSubmit && onParentSubmit(updatedUser);
			setSuccessSnackbarOpen(true);
			setSelectedGroup(undefined);
		} catch (e) {
			setSubmitting(false);
			setHasSubmitError(true);
			setSuccessSnackbarOpen(false);
		}
	}, [user, selectedGroup, onParentSubmit]);

	const onCancel = useCallback(() => {
		onParentCancel && onParentCancel();
	}, [onParentCancel]);

	const handleSnackbarClose = useCallback((event: SyntheticEvent, reason: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSuccessSnackbarOpen(false);
	}, []);

	const handleExited = useCallback(() => {
		setGroups([]);
		setPage(0);
		setCount(0);
		setPerPage(0);
	}, []);

	useEffect(() => {
		if (open) {
			setIsInitialLoad(true);
		}
	}, [open]);

	useEffect(() => {
		// noinspection JSIgnoredPromiseFromCall
		reloadGroups();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, page]);

	return {
		groups,
		reloadGroups,
		isGroupSelected,
		onToggleGroupSelection,
		isLoading,
		count,
		page,
		perPage,
		onChangePage,
		isSubmitting,
		onSubmit,
		hasSubmitError,
		hasSelectedGroup: !!selectedGroup,
		onCancel,
		successSnackbarOpen,
		handleSnackbarClose,
		open,
		handleExited,
		isInitialLoad,
	};
}

export const AddUserGroupDialog: FunctionComponent<AddUserGroupDialogProps> = (props) => {
	const {
		groups,
		onToggleGroupSelection,
		isGroupSelected,
		count,
		perPage,
		page,
		isLoading,
		onChangePage,
		hasSubmitError,
		onSubmit,
		isSubmitting,
		hasSelectedGroup,
		onCancel,
		handleSnackbarClose,
		successSnackbarOpen,
		open,
		handleExited,
		isInitialLoad,
	} = useAddUserGroupDialog(props);
	const styles = useStyles();

	return <>
		<Dialog open={open} disableBackdropClick={true} onExited={handleExited}>
			<DialogTitle>Selecione o grupo para adicionar</DialogTitle>
			<DialogContent>
				{isLoading && isInitialLoad && <LinearProgress/>}

				{!isLoading && !isSubmitting && isInitialLoad && (groups.length === 0) && <>
					<DialogContentText>
						Não existem grupos para apresentar
					</DialogContentText>
				</>}

				{!isInitialLoad && !isSubmitting && <>
					<LoadingOverlay loading={isLoading}>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>#</TableCell>
										<TableCell>Nome</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{groups.map(group => {
										const isSelected = isGroupSelected(group.groupId);
										const handleClick = () => onToggleGroupSelection(group.groupId);

										return (<TableRow
											key={group.groupId}
											selected={isSelected}
											onClick={handleClick}>
											<TableCell>{group.groupId}</TableCell>
											<TableCell>{group.name}</TableCell>
										</TableRow>);
									})}
								</TableBody>

								<TableFooter>
									<TableRow>
										<TablePagination count={count}
																		 onChangePage={onChangePage}
																		 page={page}
																		 rowsPerPageOptions={[]}
																		 rowsPerPage={perPage}>
										</TablePagination>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</LoadingOverlay>
				</>}

				{isSubmitting && <>
					<DialogContentText className={styles.loadingContainer}>
						A adicionar grupo ao utilizador...
						<LinearProgress className={styles.loadingBar}/>
					</DialogContentText>
				</>}

				{hasSubmitError && <>
					<DialogContentText>
						Ocorreu um erro ao adicionar o grupo ao utilizador.
						<br/>
						Por favor tente novamente.
					</DialogContentText>
				</>}

			</DialogContent>
			<CustomDialogActions>
				{!hasSubmitError && <>
					<Button variant="contained"
									disabled={isSubmitting}
									onClick={onCancel}>
						Cancelar
					</Button>

					<Button variant="contained"
									color="primary"
									disabled={isSubmitting || !hasSelectedGroup}
									onClick={onSubmit}>
						Associar grupo
					</Button>
				</>}

				{hasSubmitError && <>
					<Button variant="contained" color="primary" onClick={onCancel}>
						Ok
					</Button>
				</>}
			</CustomDialogActions>
		</Dialog>

		<Snackbar
			anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
			open={successSnackbarOpen}
			message="Grupo associado com sucesso!"
			autoHideDuration={1000}
			onClose={handleSnackbarClose}/>
	</>;
}
