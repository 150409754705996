import React, {FunctionComponent} from "react";
import {Button, Paper, Typography} from "@material-ui/core";
import {FullPageContainer} from "./common";
import {makeStyles} from "@material-ui/styles";
import theme from "../theme";

export interface ErrorPanelProps {
	showRetryButton?: boolean
	buttonText?: string;
	onRetry?: () => void;
}

const useStyles = makeStyles({
	errorText: {
		marginBottom: theme.spacing(1),
		textAlign: 'center'
	},
	errorContainer: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	}
});

export const ErrorPanel: FunctionComponent<ErrorPanelProps> = (props) => {
	const styles = useStyles();

	const {
		children,
		buttonText,
		showRetryButton,
		onRetry
	} = {
		showRetryButton: true,
		onRetry: () => void 0,
		buttonText: 'Tentar novamente',
		...props
	};

	return <FullPageContainer>
		<Paper className={styles.errorContainer}>
			<Typography variant="h6" component="h6" className={styles.errorText}>
				{children}
			</Typography>

			{showRetryButton && <>
				<Button onClick={onRetry} variant="contained" color="primary">
					{buttonText}
				</Button>
			</>}
		</Paper>
	</FullPageContainer>
}
