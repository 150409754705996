import React, {FunctionComponent} from 'react';
import {FileManagerApi} from './api';
import {Divider, Drawer, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {FolderPermission} from "../permissions/isGranted";
import {CloudUpload as CloudUploadIcon, CreateNewFolder as CreateNewFolderIcon} from "@material-ui/icons";
import {IsGrantedOnFolder} from "../permissions/isGrantedOnFolder";

export interface BottomDrawerProps {
	open: boolean;
	api: FileManagerApi;
	selectionCount: number;
}

export const BottomDrawer: FunctionComponent<BottomDrawerProps> = (props) => {
	const {api, open} = props;
	const selection = api.getSelection();

	return <>
		<Drawer
			open={open}
			anchor="bottom"
			onClose={() => api.toggleBottomDrawer(false)}>
			<IsGrantedOnFolder nodes={selection} permission={FolderPermission.UploadFile}>
				{({isGranted}) => (isGranted && (<>
					<Divider/>
					<ListItem button onClick={() => api.toggleFileInput(true)}>
						<ListItemIcon>
							<CloudUploadIcon/>
						</ListItemIcon>
						<ListItemText>
							Criar Ficheiro
						</ListItemText>
					</ListItem>
					<ListItem button onClick={() => api.openCreateFolder()}>
						<ListItemIcon>
							<CreateNewFolderIcon/>
						</ListItemIcon>
						<ListItemText>
							Criar Pasta
						</ListItemText>
					</ListItem>
				</>))}
			</IsGrantedOnFolder>
		</Drawer>
	</>
};
