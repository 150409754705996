import {Button, LinearProgress, Theme} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {Link, navigate, RouteComponentProps} from "@reach/router";
import clsx from "clsx";
import {Field, Form, Formik} from "formik";
import React, {FunctionComponent, useState} from "react";
import {FormErrors} from "../api/formErrors";
import backgroundImage from '../assets/login-bg.jpg';
import {useBreakpointContext} from "../ui/breakpointProvider";
import {CenteredContainer} from "../ui/centeredContainer";
import {AppTextField} from "../ui/forms";
import {useAuthApi} from "./provider";

interface LoginFormValue {
	username: string;
	password: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	containerMobile: {
		justifyContent: 'flex-end',
		alignItems: 'stretch',
	},
	paper: {
		padding: theme.spacing(3),
		minWidth: '30vw',
	},
	paperMobile: {
		borderRadius: '0',
		paddingBottom: '40px',
		minHeight: '50vh',
		display: 'flex',
		flexDirection: 'column',
	},
	form: {
		display: 'flex',
		flexDirection: 'column'
	},
	formMobile: {
		flex: '1',
		justifyContent: 'flex-end',
	},
	textField: {
		minWidth: '250px'
	},
	linearProgress: {
		margin: theme.spacing(1, 0)
	},
	error: {
		margin: theme.spacing(1, 0),
		textAlign: 'center'
	},
	title: {
		...theme.typography.h3,
		color: theme.palette.primary.main,
		margin: theme.spacing(0, 1, 0, 1),
		textAlign: 'center'
	},
	titleMobile: {
		fontSize: '2.5em',
	},
	subtitle: {
		...theme.typography.h4,
		color: theme.palette.primary.light,
		margin: theme.spacing(0, 1, 6, 1),
		textAlign: 'center',
		marginTop: 0
	},
	subtitleMobile: {
		fontSize: '1.5em',
	},
	bottomActions: {
		marginTop: theme.spacing(1),
		'& a': {
			color: blue.A200,
			textDecoration: 'none',
		}
	}
}));

export const LoginForm: FunctionComponent<RouteComponentProps> = () => {
	const styles = useStyles();
	const api = useAuthApi();
	const [error, setError] = useState<string | undefined>(undefined);
	const {isMobile} = useBreakpointContext();

	return <CenteredContainer backgroundImage={backgroundImage} className={clsx(isMobile && styles.containerMobile)}>
		<Paper className={clsx(styles.paper, isMobile && styles.paperMobile)}>
			<h1 className={clsx(styles.title, isMobile && styles.titleMobile)}>
				Assembleia Municipal
			</h1>
			<h2 className={clsx(styles.subtitle, isMobile && styles.subtitleMobile)}>
				Plataforma de Gestão Documental
			</h2>

			<Formik
				initialValues={{
					username: '',
					password: '',
				}}
				validate={(values: LoginFormValue): FormErrors<LoginFormValue> => {
					const errors: FormErrors<LoginFormValue> = {};

					if (!values.username) {
						errors.username = 'Obrigatório';
					}

					if (!values.password) {
						errors.password = 'Obrigatório';
					}

					return errors;
				}}
				onSubmit={async (values, {setSubmitting}) => {
					setError(undefined);

					try {
						await api.login(values.username, values.password);
						await navigate('/');
					} catch (e) {
						setSubmitting(false);

						if (e.response && e.response.status === 401) {
							setError('Credenciais inválidas');
						} else {
							setError('Ocorreu um erro inesperado. Por favor tente novamente.');
						}
					}
				}}>
				{({isSubmitting}) => (
					<Form className={clsx(styles.form, isMobile && styles.formMobile)}>
						<Field id="username"
									 name="username"
									 placeholder="Email"
									 component={AppTextField}
									 InputProps={{className: styles.textField}}/>

						<Field id="password"
									 name="password"
									 placeholder="Password"
									 component={AppTextField}
									 InputProps={{type: 'password', className: styles.textField}}/>

						{isSubmitting && <LinearProgress
							className={styles.linearProgress}/>}

						{error && <Typography
							variant="body2"
							color="error"
							className={styles.error}>{error}</Typography>}

						<Button variant="contained"
										color="primary"
										disabled={isSubmitting}
										type="submit">
							Entrar
						</Button>

						<div className={styles.bottomActions}>
							<Link to="/recover-password">
								Esqueceu a password?
							</Link>
						</div>
					</Form>
				)}
			</Formik>
		</Paper>
	</CenteredContainer>;
};
