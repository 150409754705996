import React, {FunctionComponent} from "react";
import {isNodePermissionGroup, isNodePermissionUser, NodePermission, NodePermissionType} from "../api/node";
import {Group as GroupIcon, Person as PersonIcon} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import {Theme, Typography} from "@material-ui/core";
import {useProfileState} from "../profile/provider";

const useStyles = makeStyles((theme: Theme) => ({
	list: {
		listStyle: 'none',
		padding: 0,
		marginBottom: theme.spacing(-1),
	},
	listItem: {
		marginBottom: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		marginLeft: theme.spacing(0.5),
	},
	entityName: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	permissionType: {
		fontSize: theme.typography.fontSize,
	}
}));

export interface SidebarSharedListProps {
	permissions: NodePermission[];
}

const PermissionType = ({type}: { type: NodePermissionType }) => {
	switch (type) {
		case NodePermissionType.Read:
			return <>Ler</>;
		case NodePermissionType.Write:
			return <>Ler e Escrever</>;
		default:
			return <></>;
	}
}

export const SidebarSharedList: FunctionComponent<SidebarSharedListProps> = (props) => {
	const {
		permissions
	} = props;

	const {profile} = useProfileState();
	const styles = useStyles();

	return <ul className={styles.list}>
		{permissions.map(permission => (
			isNodePermissionUser(permission)
				? <li className={styles.listItem} key={permission.permissionEntity.permissionEntityId}>
					<div><PersonIcon/></div>
					<div className={styles.details}>
						<div className={styles.entityName}>
							{permission.permissionEntity.user.name}
						</div>
						<Typography
							variant="body1"
							component="span"
							className={styles.permissionType}
							color="textSecondary">
							<PermissionType type={permission.type}/>

							{permission.permissionEntity.user.userId === profile?.userId && <>
								&nbsp;
								(Você)
							</>}
						</Typography>
					</div>
				</li>
				: isNodePermissionGroup(permission)
				? <li className={styles.listItem} key={permission.permissionEntity.permissionEntityId}>
					<div><GroupIcon/></div>
					<div className={styles.details}>
						<div className={styles.entityName}>
							{permission.permissionEntity.group.name}
						</div>
						<Typography
							variant="body1"
							component="span"
							className={styles.permissionType}
							color="textSecondary">
							<PermissionType type={permission.type}/>
						</Typography>
					</div>
				</li>
				: <>
				</>
		))}
	</ul>;
};
