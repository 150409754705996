import React, {FunctionComponent} from "react";
import {RouteComponentProps} from "@reach/router";
import {IndexPage} from "./indexPage";
import {NewPage} from "./newPage";
import {EditPage} from "./editPage";
import {FullPageRouter} from '../ui/router';
import {ShowPage} from "./showPage";

export const UsersModule: FunctionComponent<RouteComponentProps> = () => {
	return (
		<FullPageRouter>
			<IndexPage path="/"/>
			<NewPage path="/new"/>
			<ShowPage path="/:userId"/>
			<EditPage path="/:userId/edit"/>
		</FullPageRouter>
	);
};
