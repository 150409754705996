import React, {FunctionComponent, SyntheticEvent, useCallback, useEffect, useState} from "react";
import {Group, updateUsers} from "../api/groups";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	LinearProgress,
	Snackbar,
	SnackbarCloseReason,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow
} from "@material-ui/core";
import {getUsers} from "../api/users";
import {makeStyles} from "@material-ui/styles";
import {UserSummary} from "../api/userSummary";
import {LoadingOverlay} from "../ui/loadingOverlay";
import {CustomDialogActions} from "../ui/customDialogActions";


export interface AddGroupUserDialogProps {
	open: boolean;
	group: Group;
	onSubmit?: (group: Group) => void;
	onCancel?: () => void;
}

const useStyles = makeStyles({
	loadingContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	loadingBar: {
		width: '100%'
	}
});

const useAddGroupUserDialog = (props: AddGroupUserDialogProps) => {
	const {group, onSubmit: onParentSubmit, onCancel: onParentCancel, open} = props;
	const [users, setUsers] = useState<UserSummary[]>([]);
	const [selectedUser, setSelectedUser] = useState<number>();
	const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(0);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
	const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>();

	const reloadUsers = useCallback(async () => {
		if (!open) {
			return;
		}

		setLoading(true);

		const paginatedUsers = await getUsers({
			page: page + 1,
			withoutGroupIdMembership: group?.groupId
		});

		if (selectedUser && !paginatedUsers.items.find(u => u.userId === selectedUser)) {
			setSelectedUser(undefined);
		}

		setCount(paginatedUsers.count);
		setPage(paginatedUsers.page - 1);
		setPerPage(paginatedUsers.perPage);
		setLoading(false);
		setUsers(paginatedUsers.items);
		setIsInitialLoad(false);
	}, [page, selectedUser, group, open]);

	const isUserSelected = (userId: number) =>
		selectedUser === userId;

	const onToggleUserSelection = useCallback((userId) => {
		if (selectedUser === userId) {
			setSelectedUser(undefined);
		} else {
			setSelectedUser(userId);
		}
	}, [selectedUser]);

	const onChangePage = useCallback(async (_, page) => {
		setPage(page);
		setIsInitialLoad(false);
	}, []);

	const onSubmit = useCallback(async () => {
		if (!selectedUser || !group || !group?.groupId) {
			return;
		}

		const groupIds = [
			...group.users.map(u => u.userId),
			selectedUser
		];

		setSubmitting(true);

		try {
			const updatedGroup = await updateUsers(group.groupId, groupIds)
			setSubmitting(false);
			setHasSubmitError(false);
			onParentSubmit && onParentSubmit(updatedGroup);
			setSuccessSnackbarOpen(true);
			setSelectedUser(undefined);
		} catch (e) {
			setSubmitting(false);
			setHasSubmitError(true);
			setSuccessSnackbarOpen(false);
		}
	}, [group, selectedUser, onParentSubmit]);

	const onCancel = useCallback(() => {
		onParentCancel && onParentCancel();
	}, [onParentCancel]);

	const handleSnackbarClose = useCallback((event: SyntheticEvent, reason: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSuccessSnackbarOpen(false);
	}, []);

	const handleExited = useCallback(() => {
		setUsers([]);
		setPage(0);
		setCount(0);
		setPerPage(0);
	}, []);

	useEffect(() => {
		if (open) {
			setIsInitialLoad(true);
		}
	}, [open]);

	useEffect(() => {
		// noinspection JSIgnoredPromiseFromCall
		reloadUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, page]);

	return {
		users,
		reloadUsers,
		isUserSelected,
		onToggleUserSelection,
		isLoading,
		count,
		page,
		perPage,
		onChangePage,
		isSubmitting,
		onSubmit,
		hasSubmitError,
		hasSelectedUser: !!selectedUser,
		onCancel,
		successSnackbarOpen,
		handleSnackbarClose,
		open,
		handleExited,
		isInitialLoad,
	};
}

export const AddGroupUserDialog: FunctionComponent<AddGroupUserDialogProps> = (props) => {
	const {
		users,
		onToggleUserSelection,
		isUserSelected,
		count,
		perPage,
		page,
		isLoading,
		onChangePage,
		hasSubmitError,
		onSubmit,
		isSubmitting,
		hasSelectedUser,
		onCancel,
		handleSnackbarClose,
		successSnackbarOpen,
		open,
		handleExited,
		isInitialLoad,
	} = useAddGroupUserDialog(props);
	const styles = useStyles();

	return <>
		<Dialog open={open} disableBackdropClick={true} onExited={handleExited}>
			<DialogTitle>Selecione o utilizador para adicionar</DialogTitle>
			<DialogContent>
				{isLoading && isInitialLoad && <LinearProgress/>}

				{!isLoading && !isSubmitting && isInitialLoad && (users.length === 0) && <>
					<DialogContentText>
						Não existem utilizadores para apresentar
					</DialogContentText>
				</>}

				{!isInitialLoad && !isSubmitting && <>
					<LoadingOverlay loading={isLoading}>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>#</TableCell>
										<TableCell>Nome</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{users.map(user => {
										const isSelected = isUserSelected(user.userId);
										const handleClick = () => onToggleUserSelection(user.userId);

										return (<TableRow
											key={user.userId}
											selected={isSelected}
											onClick={handleClick}>
											<TableCell>{user.userId}</TableCell>
											<TableCell>{user.name}</TableCell>
										</TableRow>);
									})}
								</TableBody>

								<TableFooter>
									<TableRow>
										<TablePagination count={count}
																		 onChangePage={onChangePage}
																		 page={page}
																		 rowsPerPageOptions={[]}
																		 rowsPerPage={perPage}>
										</TablePagination>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</LoadingOverlay>
				</>}

				{isSubmitting && <>
					<DialogContentText className={styles.loadingContainer}>
						A adicionar utilizador ao grupo...
						<LinearProgress className={styles.loadingBar}/>
					</DialogContentText>
				</>}

				{hasSubmitError && <>
					<DialogContentText>
						Ocorreu um erro ao adicionar o utilizador ao grupo.
						<br/>
						Por favor tente novamente.
					</DialogContentText>
				</>}

			</DialogContent>
			<CustomDialogActions>
				{!hasSubmitError && <>
					<Button variant="contained"
									disabled={isSubmitting}
									onClick={onCancel}>
						Cancelar
					</Button>

					<Button variant="contained"
									color="primary"
									disabled={isSubmitting || !hasSelectedUser}
									onClick={onSubmit}>
						Associar utilizador
					</Button>
				</>}

				{hasSubmitError && <>
					<Button variant="contained" color="primary" onClick={onCancel}>
						Ok
					</Button>
				</>}
			</CustomDialogActions>
		</Dialog>

		<Snackbar
			anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
			open={successSnackbarOpen}
			message="Utilizador associado com sucesso!"
			autoHideDuration={1000}
			onClose={handleSnackbarClose}/>
	</>;
}
