import React, {FunctionComponent} from 'react';
import {FileManagerApi} from './api';
import {Drawer, ListItem, ListItemIcon, ListItemText, Typography} from '@material-ui/core';
import {FolderPermission} from "../permissions/isGranted";
import {Delete, Edit, Info as InfoIcon, MoveToInbox, Share as ShareIcon} from "@material-ui/icons";
import {IsGrantedOnFolder} from "../permissions/isGrantedOnFolder";

export interface NodeActionsProps {
	open: boolean;
	api: FileManagerApi;
	selectionCount: number;
}

export const NodeActions: FunctionComponent<NodeActionsProps> = (props) => {
	const {api, open, selectionCount} = props;
	const selection = api.getSelection();

	return <>
		<Drawer
			open={open}
			anchor="bottom"
			onClose={() => api.toggleNodeActions(false)}>
			<IsGrantedOnFolder nodes={selection} permission={FolderPermission.ManageNode}>
				{({isGranted}) => <>
					<ListItem
						button
						onClick={() => api.showInfo(selection[0])}
						disabled={!isGranted || selectionCount !== 1}>
						<ListItemIcon>
							<InfoIcon/>
						</ListItemIcon>
						<ListItemText>
							Informações
						</ListItemText>
					</ListItem>
					<ListItem
						button
						onClick={() => api.toggleMoveNode(true, true)}
						disabled={!isGranted || selectionCount !== 1}>
						<ListItemIcon>
							<MoveToInbox/>
						</ListItemIcon>
						<ListItemText>
							Mover
						</ListItemText>
					</ListItem>
					<ListItem
						button
						onClick={() => api.toggleRenameNode(true, true)}
						disabled={!isGranted || selectionCount !== 1}>
						<ListItemIcon>
							<Edit/>
						</ListItemIcon>
						<ListItemText>
							Mudar o nome
						</ListItemText>
					</ListItem>
					<ListItem
						button
						onClick={() => api.toggleManagePermissions(true, true)}
						disabled={!isGranted || selectionCount !== 1}
						color='secondary'>
						<ListItemIcon>
							<ShareIcon/>
						</ListItemIcon>
						<ListItemText>
							Permissões
						</ListItemText>
					</ListItem>
					<ListItem
						button
						onClick={() => api.openDeleteNodes(true)}
						disabled={!isGranted || !selectionCount}>
						<ListItemIcon>
							<Delete color='error'/>
						</ListItemIcon>
						<ListItemText>
							<Typography color='error'>
								Apagar
							</Typography>
						</ListItemText>
					</ListItem>
				</>}
			</IsGrantedOnFolder>
		</Drawer>
	</>
};
