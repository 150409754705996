import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress} from "@material-ui/core";
import React, {FunctionComponent, useCallback, useState} from "react";
import {deleteUser} from "../api/users";
import {CustomDialogActions} from "../ui/customDialogActions";

interface DeleteUserDialogProps {
	open: boolean;
	userId: number;
	onSubmit?: () => void;
	onCancel?: () => void;
}

const useDeleteUserDialog = (props: DeleteUserDialogProps) => {
	const {
		userId,
		onCancel: onParentCancel,
		onSubmit: onParentSubmit,
		open
	} = props;

	const [isSubmitting, setSubmitting] = useState(false);
	const [hasSubmitError, setHasSubmitError] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const onSubmit = useCallback(async () => {
		setSubmitting(true);
		setIsSubmitted(false);
		try {
			await deleteUser(userId);
			setSubmitting(false);
			setIsSubmitted(true);
			setHasSubmitError(false);
		} catch (e) {
			setSubmitting(false);
			setIsSubmitted(false);
			setHasSubmitError(true);
		}
	}, [userId]);

	const onCancel = useCallback(() => {
		onParentCancel && onParentCancel();
	}, [onParentCancel]);

	const onClose = useCallback(() => {
		if (isSubmitted) {
			onParentSubmit && onParentSubmit();
		} else {
			onParentCancel && onParentCancel();
		}
	}, [isSubmitted, onParentCancel, onParentSubmit]);

	return {
		isSubmitting,
		hasSubmitError,
		isSubmitted,
		onSubmit,
		onCancel,
		onClose,
		open
	};
}

export const DeleteUserDialog: FunctionComponent<DeleteUserDialogProps> = (props) => {
	const {userId} = props;
	const {
		hasSubmitError,
		onSubmit,
		isSubmitted,
		isSubmitting,
		onCancel,
		onClose,
		open
	} = useDeleteUserDialog(props);

	return <Dialog open={open} onClose={onClose} disableBackdropClick={true}>
		<DialogTitle>Apagar Utilizador #{userId}</DialogTitle>

		<DialogContent>
			<DialogContentText>
				{!isSubmitted && !hasSubmitError && <>
					Deseja apagar mesmo apagar o utilizador #{userId}?
					<br/>
					Esta ação não pode ser revertida.
				</>}

				{isSubmitted && <>
					Utilizador apagado com sucesso!
				</>}

				{hasSubmitError && <>
					Ocorreu um erro ao apagar o utilizador.
					<br/>
					Por favor tente novamente.
				</>}
			</DialogContentText>

			{isSubmitting && <LinearProgress/>}
		</DialogContent>
		<CustomDialogActions>
			{!isSubmitted && !hasSubmitError && <>
				<Button variant="text"
								color="default"
								type="button"
								disabled={isSubmitting}
								onClick={onCancel}>
					Cancelar
				</Button>
				<Button variant="contained"
								color="primary"
								type="submit"
								disabled={isSubmitting}
								onClick={onSubmit}>
					Apagar
				</Button>
			</>}

			{hasSubmitError && <>
				<Button variant="text"
								color="default"
								type="button"
								disabled={isSubmitting}
								onClick={onCancel}>
					Cancelar
				</Button>
				<Button variant="contained"
								color="primary"
								type="submit"
								disabled={isSubmitting}
								onClick={onSubmit}>
					Tentar novamente
				</Button>
			</>}

			{isSubmitted && <>
				<Button variant="contained"
								color="primary"
								type="submit"
								disabled={isSubmitting}
								onClick={onClose}>
					Fechar
				</Button>
			</>}
		</CustomDialogActions>
	</Dialog>;
}
