import React, {FunctionComponent, HTMLAttributes} from "react";
import {bytesToHuman} from "../util";

export interface HumanByteSizeProps extends HTMLAttributes<HTMLSpanElement> {
	bytes: number;
	showBytes?: boolean;
}

export const HumanByteSize: FunctionComponent<HumanByteSizeProps> = (props) => {
	const {bytes, showBytes = false, children, ...rest} = props;
	const {value, unit} = bytesToHuman(bytes);
	const formattedValue = value.toLocaleString(undefined, {maximumFractionDigits: 2});

	return <span {...rest}>
		{formattedValue} {unit}

		{showBytes && unit !== 'B' && <>
			&nbsp;({bytes.toLocaleString(undefined, {maximumFractionDigits: 0})} bytes)
		</>}
	</span>;
}
