import {Button, LinearProgress, makeStyles, Paper, Theme} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import {Link, RouteComponentProps} from "@reach/router";
import clsx from "clsx";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import React, {FunctionComponent, useCallback, useState} from "react";
import * as yup from 'yup';
import {isAxiosError} from "../api/client";
import {checkPasswordRecovery, recoverPassword} from "../api/passwordRecovery";
import backgroundImage from "../assets/login-bg.jpg";
import {useAuthApi} from "../auth/provider";
import {useBreakpointContext} from "../ui/breakpointProvider";
import {CenteredContainer} from "../ui/centeredContainer";

const useStyles = makeStyles((theme: Theme) => ({
	containerMobile: {
		justifyContent: 'flex-end',
		alignItems: 'stretch',
	},
	paper: {
		padding: theme.spacing(3),
		minWidth: '30vw',
	},
	paperMobile: {
		borderRadius: '0',
		paddingBottom: '40px',
		minHeight: '50vh',
		display: 'flex',
		flexDirection: 'column',
	},
	form: {
		display: 'flex',
		flexDirection: 'column'
	},
	formMobile: {
		flex: '1',
		justifyContent: 'flex-end',
	},
	textField: {
		marginBottom: theme.spacing(2),
		minWidth: '250px'
	},
	linearProgress: {
		margin: theme.spacing(1, 0)
	},
	error: {
		margin: theme.spacing(1, 0),
		textAlign: 'center'
	},
	title: {
		color: theme.palette.primary.main,
		margin: theme.spacing(0, 1, 0, 1),
		textAlign: 'center'
	},
	description: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		color: theme.palette.text.secondary,
		textAlign: 'center',
	},
	bottomActions: {
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		'& a:not([role=button])': {
			color: blue.A200,
			textDecoration: 'none',
		}
	},
	spacer: {
		flex: '1',
	},
	successContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	bottomActionsSuccess: {
		justifyContent: 'center',
		'& a': {
			flex: '1'
		}
	},
}));

enum ViewState {
	Initial,
	NewPassword,
	Success,
}

interface RecoverPageProps {
	token?: string;
}

interface TokenFormValue {
	token: string | undefined;
}

interface PasswordFormValue {
	password: string | undefined;
	passwordConfirmation: string | undefined;
}

interface RecoverPageState {
	viewState: ViewState;
	token: string | undefined;
	tokenError: string | undefined;
	submitError: string | undefined;
}

const tokenInitialValue: TokenFormValue = {
	token: undefined
};

const tokenValidationSchema = yup.object().shape({
	token: yup.string().required('O código é obrigatório')
});

const passwordInitialValue: PasswordFormValue = {
	password: undefined,
	passwordConfirmation: undefined,
};

const passwordValidationSchema = yup.object().shape({
	password: yup.string()
		.required('é obrigatório')
		.min(6, 'tem de ter mais que 6 caracteres'),
	passwordConfirmation: yup.string()
		.required('é obrigatório')
		.oneOf([yup.ref('password')], 'tem que ser igual à password'),
});

const tokenExpiredErrorMessage = 'O código já expirou. Efetue a recuperação da password novamente.';
const submitErrorMessage = 'Não foi possível recuperar a password. Por favor tente novamente';

const useRecoverPage = (props: RecoverPageProps & RouteComponentProps) => {
	const {storeAccessToken} = useAuthApi();
	const {navigate} = props;

	const [state, setState] = useState<RecoverPageState>({
		viewState: props.token ? ViewState.NewPassword : ViewState.Initial,
		token: props.token,
		tokenError: undefined,
		submitError: undefined,
	});

	const handleSubmitToken = useCallback(async (value: TokenFormValue, helpers: FormikHelpers<TokenFormValue>) => {
		const {setSubmitting} = helpers;

		setSubmitting(true);
		setState(prevState => ({...prevState, tokenError: undefined}));

		try {
			await checkPasswordRecovery(value.token!);
			setState(prevState => ({...prevState, tokenError: undefined}));
			navigate && navigate(`./${value.token!}`, {replace: true});
		} catch (e) {
			setSubmitting(false);
			setState(prevState => ({...prevState, tokenError: tokenExpiredErrorMessage}));
		}
	}, [navigate]);

	const handleSubmitPassword = useCallback(async (value: PasswordFormValue, helpers: FormikHelpers<PasswordFormValue>) => {
		const {setSubmitting} = helpers;

		setSubmitting(true);
		setState(prevState => ({...prevState, submitError: undefined}));

		try {
			const accessToken = await recoverPassword(state.token!, {password: value.password!});
			await storeAccessToken(accessToken);
			setState(prevState => ({
				...prevState,
				submitError: undefined,
				viewState: ViewState.Success,
			}));
		} catch (e) {
			setSubmitting(false);

			if (isAxiosError(e) && e.response?.status === 410) {
				setState(prevState => ({...prevState, submitError: tokenExpiredErrorMessage}));
			} else {
				setState(prevState => ({...prevState, submitError: submitErrorMessage}));
			}
		}
	}, [state.token, storeAccessToken]);

	return {
		...state,
		handleSubmitToken,
		handleSubmitPassword,
	};
};

export const RecoverPage: FunctionComponent<RecoverPageProps & RouteComponentProps> = (props) => {
	const styles = useStyles();
	const {viewState, handleSubmitToken, handleSubmitPassword, submitError, tokenError} = useRecoverPage(props);
	const {isMobile} = useBreakpointContext();

	return <CenteredContainer
		backgroundImage={backgroundImage}
		className={clsx(isMobile && styles.containerMobile)}>
		<Paper className={clsx(styles.paper, isMobile && styles.paperMobile)}>
			<Typography variant='h6' component='h3' className={styles.title}>
				Recuperar Password
			</Typography>

			{viewState === ViewState.Initial && <>
				<Typography variant={'body1'} className={styles.description}>
					Introduza abaixo o código que lhe foi enviado via e-mail
				</Typography>

				<Formik
					initialValues={tokenInitialValue}
					validationSchema={tokenValidationSchema}
					onSubmit={handleSubmitToken}>
					{({isSubmitting, touched, errors, values}) => (<>
						<Form className={clsx(styles.form, isMobile && styles.formMobile)}>
							<Field
								id="token"
								name={"token"}
								placeholder={"Código"}
								component={TextField}
								error={touched.token && !!errors.token}
								helperText={touched.token && errors.token}
								disabled={isSubmitting}
								className={styles.textField}
								InputProps={{autoComplete: 'off'}}/>

							{isSubmitting && <LinearProgress
								className={styles.linearProgress}/>}

							{tokenError && <Typography
								variant="body2"
								color="error"
								className={styles.error}>{tokenError}</Typography>}

							<Button
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								type="submit">
								Recuperar Password
							</Button>

							<div className={styles.bottomActions}>
								<Link to="/login">
									Iniciar Sessão
								</Link>

								<div className={styles.spacer}/>

								<Link to="../">
									Esqueceu a password?
								</Link>
							</div>
						</Form>
					</>)}
				</Formik>
			</>}

			{viewState === ViewState.NewPassword && <>
				<Typography variant={'body1'} className={styles.description}>
					Defina uma nova password
				</Typography>

				<Formik
					initialValues={passwordInitialValue}
					validationSchema={passwordValidationSchema}
					onSubmit={handleSubmitPassword}>
					{({isSubmitting, touched, errors}) => (<>
						<Form className={styles.form}>
							<Field
								id="password"
								name="password"
								type="password"
								placeholder="Password"
								component={TextField}
								error={touched.password && !!errors.password}
								helperText={touched.password && errors.password}
								disabled={isSubmitting}
								className={styles.textField}/>

							<Field
								id="passwordConfirmation"
								name="passwordConfirmation"
								type="password"
								placeholder="Confirmação da Password"
								component={TextField}
								error={touched.passwordConfirmation && !!errors.passwordConfirmation}
								helperText={touched.passwordConfirmation && errors.passwordConfirmation}
								disabled={isSubmitting}
								className={styles.textField}/>

							{isSubmitting && <LinearProgress
								className={styles.linearProgress}/>}

							{submitError && <Typography
								variant="body2"
								color="error"
								className={styles.error}>{submitError}</Typography>}

							<Button
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								type="submit">
								Recuperar Password
							</Button>

							<div className={styles.bottomActions}>
								<Link to="/login">
									Iniciar Sessão
								</Link>

								<div className={styles.spacer}/>

								<Link to="/login">
									Esqueceu a password?
								</Link>
							</div>
						</Form>
					</>)}
				</Formik>
			</>}

			{viewState === ViewState.Success && <div className={styles.successContainer}>
				<Typography variant={'body1'} className={styles.description}>
					A sua password foi alterada com sucesso!
					<br/>
				</Typography>

				<div className={clsx(styles.bottomActions, styles.bottomActionsSuccess)}>
					<Button variant="contained" color="primary" component={Link} to="/">
						Continuar
					</Button>
				</div>
			</div>}
		</Paper>
	</CenteredContainer>
};
