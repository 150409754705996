import {Button, LinearProgress, makeStyles, Paper, Theme} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import {Link, RouteComponentProps} from "@reach/router";
import clsx from "clsx";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import React, {FunctionComponent, useCallback, useRef, useState} from "react";
import * as yup from "yup";
import {AnyObjectSchema} from "yup";
import {requestPasswordRecovery} from "../api/passwordRecovery";
import backgroundImage from '../assets/login-bg.jpg';
import {useBreakpointContext} from "../ui/breakpointProvider";
import {CenteredContainer} from "../ui/centeredContainer";

const useStyles = makeStyles((theme: Theme) => ({
	containerMobile: {
		justifyContent: 'flex-end',
		alignItems: 'stretch',
	},
	paper: {
		padding: theme.spacing(3),
		minWidth: '30vw',
	},
	paperMobile: {
		borderRadius: '0',
		paddingBottom: '40px',
		minHeight: '50vh',
		display: 'flex',
		flexDirection: 'column',
	},
	form: {
		display: 'flex',
		flexDirection: 'column'
	},
	formMobile: {
		flex: '1',
		justifyContent: 'flex-end',
	},
	textField: {
		marginBottom: theme.spacing(2),
		minWidth: '250px'
	},
	linearProgress: {
		margin: theme.spacing(1, 0)
	},
	error: {
		margin: theme.spacing(1, 0),
		textAlign: 'center'
	},
	title: {
		color: theme.palette.primary.main,
		margin: theme.spacing(0, 1, 0, 1),
		textAlign: 'center'
	},
	description: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		color: theme.palette.text.secondary,
		textAlign: 'center',
	},
	bottomActions: {
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		'& a': {
			color: blue.A200,
			textDecoration: 'none',
		}
	},
	spacer: {
		flex: '1',
	},
	successContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	bottomActionsSuccess: {
		justifyContent: 'center',
	},
}));

enum ViewState {
	Initial,
	Submitted,
}

interface IndexPageState {
	viewState: ViewState;
	error: string | undefined;
}

interface FormValue {
	email: string | undefined;
}

const useIndexPage = () => {
	const initialValue = useRef<FormValue>({
		email: ''
	});

	const validationSchema = useRef<AnyObjectSchema>(yup.object().shape({
		email: yup.string()
			.required('é obrigatório')
			.email('não é um e-mail válido'),
	}));

	const [state, setState] = useState<IndexPageState>({
		viewState: ViewState.Initial,
		error: undefined,
	});

	const handleSubmit = useCallback(async (value: FormValue, helpers: FormikHelpers<FormValue>) => {
		const {setSubmitting} = helpers;

		setSubmitting(true);

		try {
			await requestPasswordRecovery(value.email!);
			setSubmitting(false);
			setState(prevState => ({
				...prevState,
				viewState: ViewState.Submitted,
				error: undefined,
			}));
		} catch (e) {
			setSubmitting(false);
			setState(prevState => ({
				...prevState,
				viewState: ViewState.Initial,
				error: 'Não foi possível recuperar a password. Por favor tente novamente',
			}));
		}
	}, []);

	return {
		...state,
		handleSubmit,
		initialValue: initialValue.current,
		validationSchema: validationSchema.current,
	}
};

export const IndexPage: FunctionComponent<RouteComponentProps> = () => {
	const styles = useStyles();
	const {viewState, error, handleSubmit, initialValue, validationSchema} = useIndexPage();
	const {isMobile} = useBreakpointContext();

	return <CenteredContainer
		backgroundImage={backgroundImage}
		className={clsx(isMobile && styles.containerMobile)}>

		<Paper className={clsx(styles.paper, isMobile && styles.paperMobile)}>
			<Typography variant='h6' component='h3' className={styles.title}>
				Recuperar Password
			</Typography>

			{viewState === ViewState.Initial && <>
				<Typography variant={'body1'} className={styles.description}>
					Para recuperar a sua password, introduza o seu e-mail abaixo.
					<br/>
					Irá ser enviado um e-mail com instruções de recuperação.
				</Typography>

				<Formik
					initialValues={initialValue}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}>
					{({isSubmitting, touched, errors, values}) => (
						<Form className={clsx(styles.form, isMobile && styles.formMobile)}>
							<Field
								id="email"
								name="email"
								placeholder="Email"
								component={TextField}
								error={touched.email && !!errors.email}
								helperText={touched.email && errors.email}
								disabled={isSubmitting}
								className={styles.textField}/>

							{isSubmitting && <LinearProgress
								className={styles.linearProgress}/>}

							{error && <Typography
								variant="body2"
								color="error"
								className={styles.error}>{error}</Typography>}

							<Button
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								type="submit">
								Recuperar Password
							</Button>

							<div className={styles.bottomActions}>
								<Link to="/login">
									Iniciar Sessão
								</Link>

								<div className={styles.spacer}/>

								<Link to="./recover">
									Já tenho código de recuperação
								</Link>
							</div>
						</Form>
					)}
				</Formik>
			</>}

			{viewState === ViewState.Submitted && <div className={styles.successContainer}>
				<Typography variant={'body1'} className={styles.description}>
					Foi enviado um e-mail para o seu endereço com instruções de recuperação.
					<br/>
					Carregue no link que se encontra no e-mail para iniciar a recuperação.
					<br/>
				</Typography>

				<div className={clsx(styles.bottomActions, styles.bottomActionsSuccess)}>
					<Link to="/login">
						Voltar ao Login
					</Link>
				</div>
			</div>}
		</Paper>
	</CenteredContainer>
}
