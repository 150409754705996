import {authenticatedApiClient} from "./client";
import {AccessToken} from "./auth";

export interface RecoverPasswordRequest {
	password: string;
}

export async function requestPasswordRecovery(email: string): Promise<void> {
	await authenticatedApiClient.post('/passwords', {
		email
	});
}

export async function checkPasswordRecovery(token: string): Promise<void> {
	await authenticatedApiClient.get(`/passwords/${token}/check`);
}

export async function recoverPassword(token: string, req: RecoverPasswordRequest): Promise<AccessToken> {
	const {data} = await authenticatedApiClient.post<AccessToken>(`/passwords/${token}`, req);
	return data;
}
