import {Box, Button, IconButton, Menu, MenuItem, Theme, Toolbar, Tooltip, TooltipProps} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Link from "@material-ui/core/Link";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
	ArrowBackIos,
	ArrowDownward,
	ArrowDropDown,
	ArrowUpward,
	Delete,
	Edit,
	Home,
	Info,
	MoveToInbox,
	Share as ShareIcon,
	Sort as SortIcon
} from "@material-ui/icons";
import {Link as RouterLink, useNavigate} from "@reach/router";
import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from "react";
import {Node} from "../api/node";
import {FolderPermission} from "../permissions/isGranted";
import {IsGrantedOnFolder} from "../permissions/isGrantedOnFolder";
import {FileManagerApi} from "./api";
import {Sort, SortDirection} from "./state";
import {useBreakpointContext} from "../ui/breakpointProvider";

export interface HeaderProps {
	sort: Sort;
	sortDirection: SortDirection;
	selectionCount: number;
	breadcrumb: Node[];
	folder?: Node;
	sidebarOpen: boolean;
	api: FileManagerApi;
}

const useStyles = makeStyles<Theme>((theme) => ({
	actions: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		flex: '1',
	},
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: theme.palette.grey["200"]
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	breadcrumb: {
		marginTop: '2px',

		'& > ol': {
			flexWrap: 'nowrap',
			overflow: 'auto',
		}
	},
	breadcrumbIconLink: {
		height: '24px',
		display: 'flex'
	},
	breadcrumbIcon: {
		width: '20px',
		height: '20px',
		display: 'inline-block',
	}
}));

export interface SortMenuProps {
	sort: Sort;
	sortDirection: SortDirection;
	onSort: (sort: Sort, sortDirection: SortDirection) => void;
}

const SortDirectionIcon: FunctionComponent<{ sortDirection: SortDirection }> = ({sortDirection}) => {
	switch (sortDirection) {
		case SortDirection.Desc:
			return <ArrowDownward/>;
		case SortDirection.Asc:
		default:
			return <ArrowUpward/>;
	}
};

const SortMenu: FunctionComponent<SortMenuProps> = ({sort, sortDirection, onSort}) => {
	const [isOpen, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const setSortBy = (newSort: Sort) => {
		if (newSort === sort) {
			sortDirection = (sortDirection + 1) % (Object.keys(SortDirection).length / 2);
		} else {
			sortDirection = SortDirection.Asc;
		}

		onSort(newSort, sortDirection);
		setOpen(false);
	};

	return <>
		<IconButton onClick={(e) => {
			setAnchorEl(e.currentTarget);
			setOpen(true);
		}}>
			<SortIcon/>
		</IconButton>

		<Menu open={isOpen} keepMounted onClose={() => setOpen(false)} anchorEl={anchorEl}>
			<MenuItem onClick={() => setSortBy(Sort.Name)} selected={sort === Sort.Name}>
				Nome
				{sort === Sort.Name && <SortDirectionIcon sortDirection={sortDirection}/>}
			</MenuItem>
			<MenuItem onClick={() => setSortBy(Sort.CreatedAt)} selected={sort === Sort.CreatedAt}>
				Data
				{sort === Sort.CreatedAt && <SortDirectionIcon sortDirection={sortDirection}/>}
			</MenuItem>
		</Menu>
	</>;
};

export interface CreateButtonProps {
	disabled: boolean;
	onOpenFileInput: () => void;
	onCreateFolder: () => void;
}

export const CreateButton: FunctionComponent<CreateButtonProps> = (props) => {
	const {onCreateFolder, onOpenFileInput, disabled} = props;
	const [open, setOpen] = useState(false);
	const [minWidth, setMinWidth] = useState('auto');
	const anchorRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (anchorRef.current !== null) {
			setMinWidth(`${anchorRef.current.clientWidth}px`);
		} else {
			setMinWidth('auto');
		}
	}, []);

	const handleCreateFile = useCallback(() => {
		setOpen(false);
		onOpenFileInput();
	}, [onOpenFileInput]);

	return <>
		<ButtonGroup variant="contained" color="primary" ref={anchorRef} disabled={disabled}>
			<Button onClick={handleCreateFile}>
				Criar Ficheiro
			</Button>
			<Button color="primary" size="small" onClick={() => setOpen(true)}>
				<ArrowDropDown/>
			</Button>
		</ButtonGroup>
		<Popper open={open} anchorEl={anchorRef.current} transition>
			{({TransitionProps, placement}) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
					}}
				>
					<Paper style={{minWidth}}>
						<ClickAwayListener onClickAway={() => setOpen(false)}>
							<MenuList id="split-button-menu">
								<MenuItem onClick={() => {
									setOpen(false);
									onCreateFolder();
								}}>
									Nova Pasta
								</MenuItem>
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	</>;
}

export const Header: FunctionComponent<HeaderProps> = (props) => {
	const {
		selectionCount,
		folder,
		api,
		sortDirection,
		sort,
		sidebarOpen,
	} = props;

	const styles = useStyles();
	const selection = api.getSelection();
	const {isMobile} = useBreakpointContext();

	if (isMobile) {
		return <Toolbar className={styles.toolbar}>
			<ToolbarBreadcrumb {...props} />
		</Toolbar>;
	}

	return (
		<Toolbar className={styles.toolbar}>
			<ToolbarBreadcrumb {...props} />

			<Box className={styles.actions}>
				<IsGrantedOnFolder nodes={folder} permission={FolderPermission.UploadFile}>
					{({isGranted}) => (
						<CreateButton
							disabled={!isGranted}
							onOpenFileInput={() => api.toggleFileInput(true)}
							onCreateFolder={() => api.openCreateFolder()}/>
					)}
				</IsGrantedOnFolder>

				<TooltipButton title="Mostrar informação">
					<IconButton onClick={() => api.toggleSidebar(!sidebarOpen)}
											color={sidebarOpen ? 'primary' : 'default'}>
						<Info/>
					</IconButton>
				</TooltipButton>

				<IsGrantedOnFolder nodes={selection} permission={FolderPermission.ManageNode}>
					{({isGranted}) => <>
						<TooltipButton title="Mover">
							<IconButton
								onClick={() => api.toggleMoveNode(true)}
								disabled={!isGranted || selectionCount !== 1}>
								<MoveToInbox/>
							</IconButton>
						</TooltipButton>

						<TooltipButton title="Mudar o nome">
							<IconButton
								onClick={() => api.toggleRenameNode(true)}
								disabled={!isGranted || selectionCount !== 1}>
								<Edit/>
							</IconButton>
						</TooltipButton>

						<TooltipButton title="Apagar">
							<IconButton
								color="secondary"
								disabled={!isGranted || !selectionCount}
								onClick={() => api.openDeleteNodes()}>
								<Delete/>
							</IconButton>
						</TooltipButton>

						<TooltipButton title="Permissões">
							<IconButton
								disabled={!isGranted || selectionCount !== 1}
								onClick={() => api.toggleManagePermissions(true)}>
								<ShareIcon/>
							</IconButton>
						</TooltipButton>
					</>}
				</IsGrantedOnFolder>

				<TooltipButton title="Ordenar">
					<SortMenu
						sort={sort}
						sortDirection={sortDirection}
						onSort={api.setSort}/>
				</TooltipButton>
			</Box>
		</Toolbar>
	);
};

const TooltipButton: FunctionComponent<TooltipProps> = props => {
	const {children, ...tooltipProps} = props;
	return <Tooltip {...tooltipProps} arrow>
		<span>{children}</span>
	</Tooltip>
};

const ToolbarBreadcrumb: FunctionComponent<HeaderProps> = (props) => {
	const {breadcrumb, folder} = props;
	const styles = useStyles();
	const navigate = useNavigate();
	const {isMobile, isTablet} = useBreakpointContext();

	const [breadcrumbConfig, setBreadcrumbConfig] = useState({
		maxItems: 5,
		itemsBeforeCollapse: 1,
		itemsAfterCollapse: 3,
	});

	useEffect(() => {
		if (isMobile || isTablet) {
			setBreadcrumbConfig({
				maxItems: 3,
				itemsBeforeCollapse: 1,
				itemsAfterCollapse: 1
			})
		} else {
			setBreadcrumbConfig({
				maxItems: 5,
				itemsBeforeCollapse: 1,
				itemsAfterCollapse: 3,
			});
		}
	}, [isMobile, isTablet]);

	const handleBack = useCallback(async () => {
		if (folder) {
			if (folder?.parent?.id) {
				await navigate(`/folder/${folder?.parent?.id}`);
			} else {
				await navigate('/');
			}
		}
	}, [folder, navigate]);

	return <>
		{folder && <>
			<IconButton size='small' className={styles.backButton} onClick={handleBack}>
				<ArrowBackIos/>
			</IconButton>
		</>}

		<Breadcrumbs {...breadcrumbConfig} className={styles.breadcrumb}>
			<Tooltip title="Início">
				<Link color="inherit" component={RouterLink} to="/" className={styles.breadcrumbIconLink}>
					<Home className={styles.breadcrumbIcon}/>
				</Link>
			</Tooltip>

			{breadcrumb.map(node => (
				<Link color="inherit" key={node.id} component={RouterLink} to={`/folder/${node.id}`}>
					{node.name}
				</Link>
			))}

			{folder && <Link color="primary" underline="none">
				{folder.name}
			</Link>}
		</Breadcrumbs></>
}
