export const pluralize = (count: number, singular: string, plural: string) => count === 1 ? singular : plural;


export type ByteUnit = 'B' | 'KB' | 'MB' | 'GB';

export const bytesToHuman = (bytes: number): { value: number, unit: ByteUnit } => {
	const units: ByteUnit[] = ['B', 'KB', 'MB', 'GB']

	while (bytes / 1024 > 1 && units.length) {
		bytes /= 1024;
		units.shift();
	}

	return {value: bytes, unit: units[0]};
}
