import {Node, NodeType} from "../api/node";
import {DOMFile, File} from "../api/file";

export interface NodeInfo {
  node: Node;
  selected: boolean;
}

export function createNodeInfo(node: Node): NodeInfo {
  return {
    node,
    selected: false
  };
}

export enum Sort {
  Name,
  CreatedAt,
}

export enum SortDirection {
  Asc,
  Desc,
}

export enum UploadTaskState {
  Pending,
  Uploading,
  Completed,
  Failed,
  Cancelled,
}

export interface UploadTask {
  id: number;
  file: File | undefined;
  domFile: DOMFile;
  state: UploadTaskState;
  progress: number;
  cancel: () => void;
}

export const ROOT_NODE: Node = {
	id: 0,
	name: 'Cloud',
	parent: undefined,
	type: NodeType.Folder,
	createdBy: undefined,
	updatedAt: '',
	createdAt: '',
	effectivePermissions: [],
	effectiveUserPermissions: undefined,
}

export type UploadTaskMap = { [key: number]: UploadTask };

export interface FileManagerState {
  loading: boolean;
  folder: Node | undefined;
  nodes: NodeInfo[];
  selectionCount: number;
  breadcrumb: Node[];
  createFolderOpen: boolean;
  deleteNodesOpen: boolean;
  renameNodeOpen: boolean;
  sort: Sort;
  sortDirection: SortDirection;
  uploadTasks: UploadTaskMap;
  showUploadWidget: boolean;
  expandUploadWidget: boolean;
  sidebarOpen: boolean;
  moveNodeOpen: boolean;
  managePermissionsOpen: boolean;
  bottomDrawerOpen: boolean;
  nodeActionsOpen: boolean;
  clearSelectionOnClose: boolean;
  fileInputOpen: boolean;
}

export function getInitialState({isMobile}: {isMobile: boolean}): FileManagerState {
  return {
    selectionCount: 0,
    folder: undefined,
    loading: false,
    nodes: [],
    breadcrumb: [],
    createFolderOpen: false,
    deleteNodesOpen: false,
		renameNodeOpen: false,
    sort: Sort.Name,
    sortDirection: SortDirection.Asc,
    uploadTasks: {},
    showUploadWidget: false,
    expandUploadWidget: true,
    sidebarOpen: !isMobile,
		moveNodeOpen: false,
		managePermissionsOpen: false,
		bottomDrawerOpen: false,
		nodeActionsOpen: false,
		clearSelectionOnClose: false,
		fileInputOpen: false,
  };
}
