import {RouteComponentProps} from "@reach/router";
import React, {FunctionComponent, SyntheticEvent, useCallback, useRef, useState} from "react";
import {ScrollContainer} from "../ui/common";
import {NavbarHome} from "./navbarHome";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormGroup,
	FormLabel,
	Paper,
	Snackbar,
	SnackbarCloseReason,
	TextField,
	Theme,
	Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {useProfileApi, useProfileState} from "./provider";
import {CreateUserRequest} from "../api/users";
import * as yup from "yup";
import {AnyObjectSchema} from "yup";
import {CheckboxWithLabel} from "formik-material-ui";
import {parseFormErrors} from "../api/formErrors";
import {updatePassword, UpdatePasswordRequest, updateProfile, UpdateProfileRequest} from "../api/profile";
import {CustomDialogActions} from "../ui/customDialogActions";
import {Navbar, NavbarBreadcrumbs} from "../ui/navbar";
import clsx from "clsx";
import {FormActions} from "../ui/formActions";
import {useBreakpointContext} from "../ui/breakpointProvider";

export type FormType = Partial<UpdateProfileRequest>;

export interface PasswordFormType extends Partial<UpdatePasswordRequest> {
	passwordConfirmation?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		margin: theme.spacing(2),
		marginTop: 0,
		padding: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	fieldset: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		marginBottom: '40px',
	},
	legend: {
		marginBottom: '10px',
		fontSize: '1.2em',
		lineHeight: '1.2em',
		fontWeight: 'bold'
	},
	notificationSettingsFormGroup: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
	},
	notificationSettingsFormGroupMobile: {
		gridTemplateColumns: '1fr',
	}
}));

const useEditPage = () => {
	const {profile} = useProfileState();
	const profileApi = useProfileApi();

	const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>();
	const [passwordSuccessSnackbarOpen, setPasswordSuccessSnackbarOpen] = useState<boolean>();
	const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
	const [hasPasswordSubmitError, setHasPasswordSubmitError] = useState<boolean>(false);

	const {current: initialValues} = useRef<FormType>({
		name: profile!.name,
		email: profile!.email,
		notificationSettings: {
			newFileNotificationEnabled: profile!.notificationSettings.newFileNotificationEnabled,
			newFolderNotificationEnabled: profile!.notificationSettings.newFolderNotificationEnabled,
			updatedFolderNotificationEnabled: profile!.notificationSettings.updatedFolderNotificationEnabled,
			updatedFileNotificationEnabled: profile!.notificationSettings.updatedFileNotificationEnabled,
		}
	});

	const {current: passwordInitialValues} = useRef<PasswordFormType>({
		password: undefined,
		passwordConfirmation: undefined,
	});

	const {current: validationSchema} = useRef<AnyObjectSchema>(yup.object().shape({
		name: yup.string()
			.required('é obrigatório'),
		email: yup.string()
			.required('é obrigatório')
			.email('não é um e-mail válido'),
		notificationSettings: yup.object().shape({
			newFolderNotificationEnabled: yup.boolean(),
			updatedFolderNotificationEnabled: yup.boolean(),
			newFileNotificationEnabled: yup.boolean(),
			updatedFileNotificationEnabled: yup.boolean(),
		})
	}));

	const {current: passwordValidationSchema} = useRef<AnyObjectSchema>(yup.object().shape({
		password: yup.string()
			.required('é obrigatória')
			.min(6, 'tem de ter mais que 6 caracteres'),
		passwordConfirmation: yup.string()
			.oneOf([yup.ref('password'), null], 'tem que ser igual à password'),
	}));

	const handleSubmit = useCallback(async (values: FormType, helpers: FormikHelpers<FormType>) => {
		const {
			setSubmitting,
			setFieldError,
		} = helpers;

		setSubmitting(true);

		const {notificationSettings} = values;

		try {
			const profile = await updateProfile({
				name: values.name!,
				email: values.email!,
				notificationSettings: {
					newFileNotificationEnabled: notificationSettings!.newFileNotificationEnabled,
					newFolderNotificationEnabled: notificationSettings!.newFolderNotificationEnabled,
					updatedFileNotificationEnabled: notificationSettings!.updatedFileNotificationEnabled,
					updatedFolderNotificationEnabled: notificationSettings!.updatedFolderNotificationEnabled
				}
			});

			profileApi.updateProfile(profile);
			setSubmitting(false);
			setSuccessSnackbarOpen(true);

		} catch (e) {
			setSubmitting(false);

			const errors = parseFormErrors<CreateUserRequest>(e);
			if (errors) {
				if (errors.email) {
					setFieldError('email', 'já está a ser usado');
					return;
				}
			}

			setHasSubmitError(true);
		}
	}, [profileApi]);

	const passwordHandleSubmit = useCallback(async (values: PasswordFormType, helpers: FormikHelpers<PasswordFormType>) => {
		const {
			setSubmitting,
		} = helpers;

		setSubmitting(true);

		try {
			const profile = await updatePassword({
				password: values.password!
			});

			profileApi.updateProfile(profile);
			setSubmitting(false);
			setPasswordSuccessSnackbarOpen(true);

		} catch (e) {
			setSubmitting(false);
			setHasPasswordSubmitError(true);
		}
	}, [profileApi]);

	const handleSnackbarClose = useCallback((event: SyntheticEvent, reason: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSuccessSnackbarOpen(false);
	}, []);

	const handleSubmitErrorDialogClose = useCallback(() => {
		setHasSubmitError(false);
	}, []);

	const handlePasswordSnackbarClose = useCallback((event: SyntheticEvent, reason: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setPasswordSuccessSnackbarOpen(false);
	}, []);

	const handlePasswordSubmitErrorDialogClose = useCallback(() => {
		setHasPasswordSubmitError(false);
	}, []);


	return {
		profile,
		handleSubmit,
		initialValues,
		validationSchema,
		successSnackbarOpen,
		handleSnackbarClose,
		handleSubmitErrorDialogClose,
		hasSubmitError,
		passwordHandleSubmit,
		passwordInitialValues,
		passwordValidationSchema,
		passwordSuccessSnackbarOpen,
		hasPasswordSubmitError,
		handlePasswordSubmitErrorDialogClose,
		handlePasswordSnackbarClose,
	}
};

export type EditPageProps = RouteComponentProps;

export const EditPage: FunctionComponent<EditPageProps> = props => {
	const styles = useStyles();
	const {
		handleSubmit,
		initialValues,
		validationSchema,
		hasSubmitError,
		handleSubmitErrorDialogClose,
		handleSnackbarClose,
		successSnackbarOpen,
		handlePasswordSnackbarClose,
		handlePasswordSubmitErrorDialogClose,
		hasPasswordSubmitError,
		passwordSuccessSnackbarOpen,
		passwordHandleSubmit,
		passwordInitialValues,
		passwordValidationSchema,
	} = useEditPage();

	const {isMobile} = useBreakpointContext();

	return <ScrollContainer>
		<Navbar>
			<NavbarBreadcrumbs>
				<NavbarHome to="../"/>
				<Typography color="textPrimary">
					Editar
				</Typography>
			</NavbarBreadcrumbs>
		</Navbar>

		<Paper className={styles.container}>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}>
				{({isSubmitting, errors, touched}) => (
					<Form className={styles.form}>
						<FormControl component="fieldset" className={styles.fieldset}>
							<FormLabel component="legend" className={styles.legend}>
								Definições Básicas
							</FormLabel>

							<Field id="name"
										 name="name"
										 label="Nome"
										 as={TextField}
										 margin="normal"
										 error={touched.name && !!errors.name}
										 helperText={touched.name && errors.name}
										 disabled={isSubmitting}/>

							<Field id="email"
										 name="email"
										 label="Email"
										 as={TextField}
										 margin="normal"
										 type="email"
										 error={touched.email && !!errors.email}
										 helperText={touched.email && errors.email}
										 disabled={isSubmitting}/>
						</FormControl>

						<FormControl component="fieldset" className={styles.fieldset}>
							<FormLabel component="legend" className={styles.legend}>Notificações</FormLabel>
							<FormGroup
								className={clsx(
									styles.notificationSettingsFormGroup,
									isMobile && styles.notificationSettingsFormGroupMobile
								)}>
								<Field name="notificationSettings.newFolderNotificationEnabled"
											 Label={{label: "Criação de pastas"}}
											 type="checkbox"
											 component={CheckboxWithLabel}
											 disabled={isSubmitting}/>

								<Field name="notificationSettings.newFileNotificationEnabled"
											 Label={{label: "Criação de ficheiros"}}
											 type="checkbox"
											 component={CheckboxWithLabel}
											 disabled={isSubmitting}/>

								<Field name="notificationSettings.updatedFolderNotificationEnabled"
											 Label={{label: "Alteração de pastas"}}
											 type="checkbox"
											 component={CheckboxWithLabel}
											 disabled={isSubmitting}/>

								<Field name="notificationSettings.updatedFileNotificationEnabled"
											 Label={{label: "Alteração de ficheiros"}}
											 type="checkbox"
											 component={CheckboxWithLabel}
											 disabled={isSubmitting}/>
							</FormGroup>
						</FormControl>

						<FormActions>
							<Button variant="contained"
											color="primary"
											disabled={isSubmitting}
											type="submit">
								Atualizar Perfil
							</Button>
						</FormActions>
					</Form>
				)}
			</Formik>

			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				open={successSnackbarOpen}
				message='Perfil atualizado com sucesso'
				autoHideDuration={1000}
				onClose={handleSnackbarClose}/>

			<Dialog
				open={hasSubmitError}
				onClose={handleSubmitErrorDialogClose}>
				<DialogTitle>Erro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Ocorreu um erro ao atualizar o seu perfil.
						Por favor tente novamente.
					</DialogContentText>
				</DialogContent>
				<CustomDialogActions>
					<Button onClick={handleSubmitErrorDialogClose} color="primary" autoFocus>
						Ok
					</Button>
				</CustomDialogActions>
			</Dialog>
		</Paper>

		<Paper className={styles.container}>
			<Formik
				initialValues={passwordInitialValues}
				validationSchema={passwordValidationSchema}
				onSubmit={passwordHandleSubmit}>
				{({isSubmitting, errors, touched}) => (
					<Form className={styles.form}>
						<FormControl component="fieldset" className={styles.fieldset}>
							<FormLabel component="legend" className={styles.legend}>Password</FormLabel>

							<FormGroup>
								<Field id="password"
											 name="password"
											 label="Password"
											 as={TextField}
											 margin="normal"
											 type="password"
											 error={touched.password && !!errors.password}
											 helperText={touched.password && errors.password}
											 disabled={isSubmitting}/>

								<Field id="passwordConfirmation"
											 name="passwordConfirmation"
											 label="Confirmação da Password"
											 as={TextField}
											 margin="normal"
											 type="password"
											 error={touched.passwordConfirmation && !!errors.passwordConfirmation}
											 helperText={touched.passwordConfirmation && errors.passwordConfirmation}
											 disabled={isSubmitting}/>
							</FormGroup>
						</FormControl>

						<FormActions>
							<Button variant="contained"
											color="primary"
											disabled={isSubmitting}
											type="submit">
								Atualizar Password
							</Button>
						</FormActions>
					</Form>
				)}
			</Formik>

			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				open={passwordSuccessSnackbarOpen}
				message='Password atualizada com sucesso'
				autoHideDuration={1000}
				onClose={handlePasswordSnackbarClose}/>

			<Dialog
				open={hasPasswordSubmitError}
				onClose={handlePasswordSubmitErrorDialogClose}>
				<DialogTitle>Erro</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Ocorreu um erro ao atualizar a sua password.
						Por favor tente novamente.
					</DialogContentText>
				</DialogContent>
				<CustomDialogActions>
					<Button onClick={handlePasswordSubmitErrorDialogClose} color="primary" autoFocus>
						Ok
					</Button>
				</CustomDialogActions>
			</Dialog>
		</Paper>
	</ScrollContainer>;
};
