import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle, LinearProgress, Theme} from "@material-ui/core";
import {FileManagerApi} from "./api";
import {getNodes, isFolder, Node} from '../api/node';
import {getFolder} from "../api/folder";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {ROOT_NODE} from "./state";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {ArrowBack, ArrowRight} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import {CustomDialogActions} from "../ui/customDialogActions";

export interface MoveNodeProps {
	open: boolean;
	api: FileManagerApi;
	node: Node;
}

const useStyles = makeStyles<Theme, { isLoading: boolean, canBack: boolean, isMoving: boolean }>(theme => ({
	dialog: {
		width: '450px',
		maxWidth: '80vw',
		height: '450px',
		maxHeight: '80vh',
		padding: 0,
	},
	header: {
		backgroundColor: theme.palette.grey["200"],
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	backButton: ({isLoading, canBack, isMoving}) => ({
		marginRight: theme.spacing(1),
		...(isLoading || isMoving || !canBack) ? {
			visibility: 'hidden',
		} : {}
	}),
	loadingText: {
		padding: theme.spacing(1),
		textAlign: 'center'
	},
}));

const getSelectedFolder = (node: Node, rootFolder: Node | undefined): Node | undefined => {
	if (!rootFolder) {
		return undefined;
	}

	if (node.parent) {
		if (rootFolder.id !== node.parent.id) {
			return rootFolder;
		}
	} else {
		if (rootFolder.id !== ROOT_NODE.id) {
			return rootFolder;
		}
	}

	return undefined;
}

function isChildFolderDisabled(childFolder: Node, node: Node) {
	return childFolder.id === node.id ||
		(node.parent && node.parent.id === childFolder.id) ||
		(!node.parent && childFolder.id === ROOT_NODE.id);
}

function isChildFolderSelected(selectedFolder: Node | undefined, childFolder: Node) {
	return selectedFolder &&
		selectedFolder.id === childFolder.id;
}

export const MoveNode: FunctionComponent<MoveNodeProps> = (props) => {
	const {open, api, node} = props;
	const [folderId, setFolderId] = useState(node.parent ? node.parent.id : undefined);
	const [isLoading, setLoading] = useState(false);
	const [isMoving, setMoving] = useState(false);
	const [folder, setFolder] = useState<Node>();
	const [folders, setFolders] = useState<Node[]>([]);
	const [selectedFolder, setSelectedFolder] = useState<Node>();

	useEffect(() => {
		(async () => {
			if (open) {
				setMoving(false);
				setLoading(true);
				const folder = folderId ? await getFolder(folderId) : ROOT_NODE;
				const nodes = await getNodes(folderId);
				const folders = nodes.filter(isFolder);
				setFolder(folder);
				setFolders(folders);
				setLoading(false);
				setSelectedFolder(getSelectedFolder(node, folder));
			}
		})();
	}, [open, folderId, node]);

	const styles = useStyles({
		isLoading,
		isMoving,
		canBack: folder?.id !== ROOT_NODE.id
	});

	return <Dialog open={open} onClose={() => api.toggleMoveNode(false)}>
		<DialogTitle>Mover</DialogTitle>

		<DialogContent className={styles.dialog} dividers>
			<div className={styles.header}>
				<IconButton className={styles.backButton}
										onClick={() => setFolderId(folder?.parent?.id)}
										size="small">
					<ArrowBack/>
				</IconButton>

				{isLoading || isMoving
					? <>&nbsp;</>
					: <>
						<Typography component="h4" variant="h6">
							{folder?.name}
						</Typography>
					</>}
			</div>

			{isLoading && <>
				<LinearProgress/>
			</>}

			{isMoving && <>
				<Typography component="h6" variant="body1" className={styles.loadingText}>
					A mover...
				</Typography>
				<LinearProgress/>
			</>}

			{!isLoading && !isMoving && <>
				<List disablePadding>
					{folders.map(childFolder => (
						<ListItem key={childFolder.id}
											button
											disabled={isChildFolderDisabled(childFolder, node)}
											selected={isChildFolderSelected(selectedFolder, childFolder)}
											onClick={() => {
												if (selectedFolder && selectedFolder.id === childFolder.id) {
													setSelectedFolder(getSelectedFolder(node, folder));
												} else {
													setSelectedFolder(childFolder);
												}
											}}>
							<ListItemText>
								{childFolder.name}
							</ListItemText>
							<ListItemSecondaryAction>
								<IconButton edge="end"
														disabled={isChildFolderDisabled(childFolder, node)}
														onClick={() => {
															setFolderId(childFolder.id);
														}}>
									<ArrowRight/>
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
			</>}
		</DialogContent>

		<CustomDialogActions>
			<Button variant="text"
							color="default"
							type="button"
							onClick={() => api.toggleMoveNode(false)}>
				Cancelar
			</Button>
			<Button variant="contained"
							color="primary"
							type="submit"
							disabled={!selectedFolder || isMoving || isLoading}
							onClick={async () => {
								if (!selectedFolder) {
									return;
								}

								setMoving(true);
								const parentId = selectedFolder.id === ROOT_NODE.id ? null : selectedFolder.id;
								await api.moveNode(node.id, parentId);
								api.toggleMoveNode(false);
							}}>
				Mover
			</Button>
		</CustomDialogActions>
	</Dialog>;
};
