import {authenticatedApiClient} from "./client";
import {UserSummary} from "./userSummary";
import {GroupSummary} from "./groupSummary";
import {UserNotificationSettings} from "./users";

export interface Profile extends UserSummary {
	groups: GroupSummary[];
	notificationSettings: UserNotificationSettings;
}

export interface UpdateProfileRequest {
	name: string;
	email: string;
	notificationSettings: UserNotificationSettings;
}

export interface UpdatePasswordRequest {
	password: string;
}

export async function getProfile(): Promise<Profile> {
	const {data} = await authenticatedApiClient.get('profile');
	return data;
}

export async function updateProfile(req: UpdateProfileRequest): Promise<Profile> {
	const {data} = await authenticatedApiClient.put('profile', req);
	return data;
}

export async function updatePassword(req: UpdatePasswordRequest): Promise<Profile> {
	const {data} = await authenticatedApiClient.put('profile/update-password', req);
	return data;
}
